import { FC } from 'react';
import { styled } from '@mui/system';
import Box from '@mui/material/Box';
import { CredibilityIcon } from 'assets/images/icons';
import { Props } from './PaymentCredibility.types';
import { MONITORING_STATE, SCORING_STATE } from 'api/models';
import { Tooltip } from '@mui/material';
import HourglassEmptyIcon from '@mui/icons-material/HourglassEmpty';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import { Tooltip as CustomTooltip } from 'components';

const Root = styled('div')`
  display: flex;
  align-items: center;
  padding: 4px 10px;
  height: 30px;
  background: #078a51;
  border-radius: 4px;
  color: #ffffff;
  font-weight: 500;
  font-size: 13px;
  cursor: pointer;
  white-space: nowrap;
`;

const tooltipText = 'Pobranie informacji o kategorii wiarygodności płatniczej wymaga włączenia monitorowania.';
const tooltipLink = { linkName: 'Samouczek, punkt 4.3.', linkUrl: '/tutorial#punkt-4-3' };

const PaymentCredibility: FC<Props> = ({ scoring }) => {
  const { riskGroup, riskGroupCode, scoringId, scoringStatus, scoringStatusCode, monitoringState, scoringState } =
    scoring;
  let result = null;
  if (monitoringState === MONITORING_STATE.DISABLING || monitoringState === MONITORING_STATE.DISABLED) {
    result = (
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '2.5rem',
          whiteSpace: 'nowrap'
        }}>
        <Box
          sx={{
            display: 'flex',
            flexShrink: '0',
            alignItems: 'center'
          }}>
          <Tooltip
            title={
              'Trwa wyłączanie monitorowania. Monitorowanie zostanie zatrzymane z końcem doby, w której zlecono jego wyłączenie.'
            }>
            <HourglassEmptyIcon fontSize="medium" sx={{ color: '#ec9514' }} />
          </Tooltip>
        </Box>
      </Box>
    );
  } else if (monitoringState === MONITORING_STATE.ENABLED && scoringState === SCORING_STATE.CREATED) {
    result = (
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '2.5rem',
          whiteSpace: 'nowrap'
        }}>
        <Box
          sx={{
            display: 'flex',
            flexShrink: '0',
            alignItems: 'center'
          }}>
          <Tooltip
            title={
              'Trwa włączanie monitorowania. Monitorowanie uruchomi się po 2 dniach (w godzinach rannych) od momentu zlecenia jego włączenia.'
            }>
            <HourglassEmptyIcon fontSize="medium" sx={{ color: '#ec9514' }} />
          </Tooltip>
        </Box>
      </Box>
    );
  } else if (monitoringState === MONITORING_STATE.CREATED) {
    result = (
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '2.5rem',
          whiteSpace: 'nowrap'
        }}>
        <Box
          sx={{
            display: 'flex',
            flexShrink: '0',
            alignItems: 'center'
          }}>
          <Tooltip
            title={
              'Trwa włączanie monitorowania. Monitorowanie uruchomi się po 2 dniach (w godzinach rannych) od momentu zlecenia jego włączenia.'
            }>
            <HourglassEmptyIcon fontSize="medium" sx={{ color: '#ec9514' }} />
          </Tooltip>
        </Box>
      </Box>
    );
  } else if (monitoringState === MONITORING_STATE.ADDED_IN_MPRO) {
    result = (
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '2.5rem',
          whiteSpace: 'nowrap'
        }}>
        <Tooltip
          title={
            'Trwa włączanie monitorowania. Monitorowanie uruchomi się po 2 dniach (w godzinach rannych) od momentu zlecenia jego włączenia.'
          }>
          <Box sx={{ dislay: 'flex', alignItems: 'center' }}>
            <HourglassEmptyIcon fontSize="medium" sx={{ color: '#ec9514' }} />
          </Box>
        </Tooltip>
      </Box>
    );
  } else if (scoringState === SCORING_STATE.ERROR_GETTING_DEBT) {
    result = (
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '2.5rem',
          whiteSpace: 'nowrap'
        }}>
        <Box
          sx={{
            display: 'flex',
            flexShrink: '0',
            alignItems: 'center'
          }}>
          <Tooltip
            title={
              'Brak uprawnień do pobrania informacji o kategorii wiarygodności płatniczej. Skontaktuj się z Administratorem KG.'
            }>
            <WarningAmberIcon sx={{ marginLeft: '0.5rem', color: '#EA9617' }} />
          </Tooltip>
        </Box>
      </Box>
    );
  } else if (scoringState === SCORING_STATE.ERROR_DISABLING) {
    result = (
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '2.5rem',
          whiteSpace: 'nowrap'
        }}>
        <Box
          sx={{
            display: 'flex',
            flexShrink: '0',
            alignItems: 'center'
          }}>
          <Tooltip title={'Trwa pobieranie informacji'}>
            <HourglassEmptyIcon fontSize="medium" sx={{ color: '#ec9514' }} />
          </Tooltip>
        </Box>
      </Box>
    );
  } else if (monitoringState === MONITORING_STATE.ERROR_ENABLING || scoringState === SCORING_STATE.ERROR_ENABLING) {
    result = (
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '2.5rem',
          whiteSpace: 'nowrap'
        }}>
        <Box
          sx={{
            display: 'flex',
            flexShrink: '0',
            alignItems: 'center'
          }}>
          <Tooltip title={'Pobranie informacji nie powiodło się. Skontaktuj się z Administratorem KG.'}>
            <WarningAmberIcon sx={{ marginLeft: '0.5rem', color: '#EA9617' }} />
          </Tooltip>
        </Box>
      </Box>
    );
  } else if (monitoringState === MONITORING_STATE.ERROR_DISABLING) {
    result = (
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '2.5rem',
          whiteSpace: 'nowrap'
        }}>
        <Box
          sx={{
            display: 'flex',
            flexShrink: '0',
            alignItems: 'center'
          }}>
          <Tooltip title={'Nie udało się wyłączyć monitorowania. Skontaktuj się z Administratorem KG.'}>
            <WarningAmberIcon sx={{ marginLeft: '0.5rem', color: '#EA9617' }} />
          </Tooltip>
        </Box>
      </Box>
    );
  } else if (scoringStatusCode === '0' && riskGroup && riskGroupCode) {
    result = (
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '2.5rem',
          whiteSpace: 'nowrap'
        }}>
        <Box
          sx={{
            display: 'flex',
            flexShrink: '0',
            alignItems: 'center'
          }}>
          <Tooltip title={riskGroup}>
            <Box sx={{ maxWidth: '8.5rem', width: 'fit-content', textOverflow: 'ellipsis', overflow: 'hidden' }}>
              {riskGroupCode && <CredibilityIcon letter={riskGroupCode} />}
            </Box>
          </Tooltip>
        </Box>
      </Box>
    );
  } else if (scoringStatusCode !== '0' && scoringStatus) {
    result = (
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '2.5rem',
          whiteSpace: 'nowrap'
        }}>
        <Box
          sx={{
            display: 'flex',
            flexShrink: '0',
            alignItems: 'center'
          }}>
          <Tooltip title={`Brak kategorii - ${scoringStatus}`}>
            <Box sx={{ maxWidth: '8.5rem', width: 'fit-content', textOverflow: 'ellipsis', overflow: 'hidden' }}>
              {riskGroupCode && <CredibilityIcon letter="none" />}
            </Box>
          </Tooltip>
        </Box>
      </Box>
    );
  } else if (monitoringState === null) {
    result = (
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '2.5rem',
          whiteSpace: 'nowrap'
        }}>
        <CustomTooltip text={tooltipText} link={tooltipLink}></CustomTooltip>
      </Box>
    );
  }

  return result;
};

export default PaymentCredibility;
