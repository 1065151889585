import moment, { Moment } from 'moment';
import { HistoryDataResultDto } from 'api/models';

export enum FIELDS {
  NAME = 'name',
  TAX_ID = 'taxId',
  INFORMATION_TYPE = 'informationType',
  DAY_1 = 'day1',
  DAY_2 = 'day2',
  DAY_3 = 'day3',
  DAY_4 = 'day4',
  DAY_5 = 'day5',
  DAY_6 = 'day6',
  DAY_7 = 'day7',
  DAY_CURRENT = 'dayCurrent',
  CURRENT_WEEK = 'current_week',
  WEEK_1 = 'week1',
  WEEK_2 = 'week2',
  WEEK_3 = 'week3',
  WEEK_4 = 'week4',
  WEEK_5 = 'week5',
  WEEK_6 = 'week6',
  WEEK_7 = 'week7',
  WEEK_8 = 'week8'
}

export enum HISTORY_FIELDS {
  NAME = 'name',
  TAX_ID = 'taxIdentificationNumber',
  TYPE = 'type',
  DATES = 'dates',
  SELECTED_DATE = 'selectedDate',
  CURRENT_VALUE = 'currentValue',
  COUNTERPARTY_ID = 'counterpartyId',
  SUMMARY_VIEW = 'summaryView',
  ENTRIES = 'entries'
}

export enum TYPES {
  DEBT = 1,
  SCORING = 2
}
export interface HistoryDate {
  day: string;
  newValue: string | null;
}

export interface EntryDate {
  weekStartDate: string;
  newValue: string | null;
  weeksAgo: number;
}
export interface Row {
  [HISTORY_FIELDS.NAME]: string;
  [HISTORY_FIELDS.TAX_ID]: string;
  [HISTORY_FIELDS.TYPE]: number;
  [HISTORY_FIELDS.DATES]: HistoryDate[];
  [HISTORY_FIELDS.CURRENT_VALUE]: string | null;
  [HISTORY_FIELDS.COUNTERPARTY_ID]: number;
  [HISTORY_FIELDS.ENTRIES]: EntryDate[];
}
export interface HistoryFormikValues {
  [HISTORY_FIELDS.NAME]: string;
  [HISTORY_FIELDS.TAX_ID]: string;
}

export type HeadCell<T> = {
  disablePadding: boolean;
  id: T;
  labelTop: string;
  labelBottom?: Moment;
  numeric: boolean;
  sortable: boolean;
};

export type informationType = {
  setFieldValue: HISTORY_FIELDS;
  value: TYPES;
  label: string;
};

export type dateType = {
  setFieldValue: HISTORY_FIELDS;
  value: string;
  label: string;
};

export const informationTypeCheckboxes: informationType[] = [
  {
    setFieldValue: HISTORY_FIELDS.TYPE,
    value: TYPES.DEBT,
    label: 'Zadłużenie w KRD'
  },
  {
    setFieldValue: HISTORY_FIELDS.TYPE,
    value: TYPES.SCORING,
    label: 'Wiarygodność'
  }
];

export let datesRadioButtons: dateType[] = [
  { setFieldValue: HISTORY_FIELDS.DATES, value: HISTORY_FIELDS.SUMMARY_VIEW, label: 'Widok sumaryczny' }
];

[...Array(9)].forEach((_, id) => {
  const weekNumber = id + 1;

  if (weekNumber === 1) {
    const firstDayOfWeek = moment().weekday(1);
    const lastDayOfWeek = moment().weekday(7);

    datesRadioButtons = [
      ...datesRadioButtons,
      {
        setFieldValue: HISTORY_FIELDS.DATES,
        value: lastDayOfWeek.format('YYYY-MM-DD'),
        label: `${firstDayOfWeek.format('DD-MM-YYYY')} - ${lastDayOfWeek.format('DD-MM-YYYY')}`
      }
    ];
  } else {
    const previousWeek = datesRadioButtons[weekNumber - 1];
    const lastDayOfWeek = moment(previousWeek?.value).subtract(7, 'd');
    const firstDayOfWeek = moment(previousWeek?.value).subtract(13, 'd');

    datesRadioButtons = [
      ...datesRadioButtons,
      {
        setFieldValue: HISTORY_FIELDS.DATES,
        value: lastDayOfWeek.format('YYYY-MM-DD'),
        label: `${firstDayOfWeek.format('DD-MM-YYYY')} - ${lastDayOfWeek.format('DD-MM-YYYY')}`
      }
    ];
  }
});

export type HistoryDataResults = {
  results: HistoryDataResultDto[];
  totalSize: number;
};
