import { FC } from 'react';
import { Props } from './TradeCreditLimit.types';
import { MONITORING_STATE, SCORING_STATE, TradeCreditLimitAuthorizationErrorState } from 'api/models';
import classes from './tradeCreditLimit.module.scss';
import { Tooltip } from '@mui/material';
import { Tooltip as CustomTooltip } from 'components';
import HourglassEmptyIcon from '@mui/icons-material/HourglassEmpty';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import { numberWithSpace } from 'lib/utils';
import { CredibilityIcon } from 'assets/images/icons/components';

const TradeCreditLimit: FC<Props> = ({
  tradeCreditLimitAuthorizationErrorState,
  monitoringState,
  scoringState,
  tradeCreditLimitValue,
  tradeCreditLimitModel,
  tradeCreditLimitStatus
}) => {
  if (monitoringState === null) {
    return (
      <div className={classes.box}>
        <div className={classes.boxCenter}>
          <CustomTooltip
            text="Pobranie informacji o dostępnym limicie kupieckim wymaga włączenia monitorowania."
            link={{ linkName: 'Samouczek, punkt 4.3', linkUrl: '/tutorial#punkt-4-3' }}></CustomTooltip>
        </div>
      </div>
    );
  } else if (
    tradeCreditLimitAuthorizationErrorState === TradeCreditLimitAuthorizationErrorState.Forbidden ||
    tradeCreditLimitAuthorizationErrorState === TradeCreditLimitAuthorizationErrorState.MissingService
  ) {
    return (
      <Tooltip title="Usługa nieaktywna. Jeśli chcesz aktywować usługę, skontaktuj się ze swoim opiekunem.">
        <div className={classes.box}>
          <span className={classes.inactiveText}>Nieaktywny</span>
        </div>
      </Tooltip>
    );
  } else if (monitoringState === MONITORING_STATE.DISABLING || monitoringState === MONITORING_STATE.DISABLED) {
    return (
      <div className={classes.box}>
        <Tooltip
          title={
            'Trwa wyłączanie monitorowania. Monitorowanie zostanie zatrzymane z końcem doby, w której zlecono jego wyłączenie.'
          }>
          <HourglassEmptyIcon fontSize="medium" className={classes.iconYellowColor} />
        </Tooltip>
      </div>
    );
  } else if (
    (monitoringState === MONITORING_STATE.ENABLED && scoringState === SCORING_STATE.CREATED) ||
    monitoringState === MONITORING_STATE.CREATED ||
    monitoringState === MONITORING_STATE.ADDED_IN_MPRO
  ) {
    return (
      <div className={classes.box}>
        <Tooltip
          title={
            'Trwa włączanie monitorowania. Monitorowanie uruchomi się po 2 dniach (w godzinach rannych) od momentu zlecenia jego włączenia.'
          }>
          <HourglassEmptyIcon fontSize="medium" className={classes.iconYellowColor} />
        </Tooltip>
      </div>
    );
  } else if (scoringState === SCORING_STATE.ERROR_DISABLING) {
    return (
      <div className={classes.box}>
        <Tooltip title={'Trwa pobieranie informacji.'}>
          <HourglassEmptyIcon fontSize="medium" className={classes.iconYellowColor} />
        </Tooltip>
      </div>
    );
  } else if (monitoringState === MONITORING_STATE.ERROR_ENABLING || scoringState === SCORING_STATE.ERROR_ENABLING) {
    return (
      <div className={classes.box}>
        <Tooltip title={'Pobranie informacji nie powiodło się. Skontaktuj się z Administratorem KG.'}>
          <WarningAmberIcon className={classes.iconWarning} />
        </Tooltip>
      </div>
    );
  } else if (monitoringState === MONITORING_STATE.ERROR_DISABLING) {
    return (
      <div className={classes.box}>
        <Tooltip title={'Nie udało się wyłączyć monitorowania. Skontaktuj się z Administratorem KG.'}>
          <WarningAmberIcon className={classes.iconWarning} />
        </Tooltip>
      </div>
    );
  } else if (tradeCreditLimitValue === null && tradeCreditLimitStatus !== null) {
    return (
      <div className={classes.box}>
        <Tooltip title={tradeCreditLimitStatus ?? ''}>
          <div className={classes.iconTooltip}>
            <CredibilityIcon letter="none" />
          </div>
        </Tooltip>
      </div>
    );
  } else if (tradeCreditLimitValue === null) {
    return null;
  }

  return (
    <div className={`${classes.box} ${classes.justifyRight}`}>
      <CustomTooltip
        noIcon
        text={`Limit kupiecki został wyznaczony za pomocą modelu ${tradeCreditLimitModel}.`}
        link={{ linkName: 'Samouczek, punkt 4.2', linkUrl: '/tutorial#punkt-4-2' }}>
        <div>
          {numberWithSpace(tradeCreditLimitValue?.toFixed(2))} ({tradeCreditLimitModel})
        </div>
      </CustomTooltip>
    </div>
  );
};

export default TradeCreditLimit;
