import { HistoryDataResultDto } from 'api/models';
import { HISTORY_FIELDS, TYPES, HistoryFormikValues, FIELDS } from '../historyChangesList.types';

export const createRows = (historyData?: HistoryDataResultDto[]) => {
  if (!historyData) return [];

  return historyData.map((result: HistoryDataResultDto) => ({
    [HISTORY_FIELDS.NAME]: result.name,
    [HISTORY_FIELDS.TAX_ID]: result.taxIdentificationNumber,
    [HISTORY_FIELDS.TYPE]: result.type,
    [HISTORY_FIELDS.DATES]: result.dates,
    [HISTORY_FIELDS.CURRENT_VALUE]: result.currentValue,
    [HISTORY_FIELDS.COUNTERPARTY_ID]: result.counterpartyId,
    [HISTORY_FIELDS.ENTRIES]: result.entries
  }));
};

export const parseType = (type: number) => {
  if (type === TYPES.DEBT) {
    return 'Zadłużenie w KRD';
  }
  if (type === TYPES.SCORING) {
    return 'Wiarygodność';
  }
  return '';
};

export const mapOrderBy = (orderBy: HISTORY_FIELDS | FIELDS) => {
  switch (orderBy) {
    case HISTORY_FIELDS.NAME:
      return 'name';
  }
};

export const createSearchQuery = (
  values: HistoryFormikValues,
  searchParams: URLSearchParams,
  searchFieldName: HISTORY_FIELDS
) => {
  const searchArray: string[] = [];

  searchParams.set(searchFieldName, (values as any)?.[searchFieldName]);

  searchParams.forEach((el, key) => {
    if (key === HISTORY_FIELDS.NAME) {
      if (el) {
        searchArray.push(`${key};contains;${el}`);
      }
    }
    if (key === HISTORY_FIELDS.TAX_ID) {
      if (el) {
        searchArray.push(`${key};contains;${el}`);
      }
    }

    if (!el || el === 'undefined') {
      searchParams.delete(key);
    }
  });

  return encodeURIComponent(searchArray.join('|'));
};

export const createFiltersQuery = (type?: number[]) => {
  let filtersQuery = '';

  if (type) {
    const prepareQuery = type.map((el) => `&types=${el}`).join('');

    filtersQuery += prepareQuery;
  }

  return filtersQuery;
};

export const createInitialSearchQuery = (searchParams: URLSearchParams) => {
  const searchArray: string[] = [];

  searchParams.forEach((el, key) => {
    if (key === HISTORY_FIELDS.NAME) {
      if (el) {
        searchArray.push(`${key};contains;${el}`);
      }
    }
    if (key === HISTORY_FIELDS.TAX_ID) {
      if (el) {
        searchArray.push(`${key};contains;${el}`);
      }
    }

    if (!el || el === 'undefined') {
      searchParams.delete(key);
    }
  });

  return encodeURIComponent(searchArray.join('|'));
};

export const createExportToCsvSearchFilters = (searchParams: URLSearchParams) => {
  const name = searchParams.get(HISTORY_FIELDS.NAME);
  const taxNumber = searchParams.get(HISTORY_FIELDS.TAX_ID);

  const searchFiltersArray = [
    {
      name: HISTORY_FIELDS.NAME,
      value: name
    },
    {
      name: HISTORY_FIELDS.TAX_ID,
      value: taxNumber
    }
  ];

  let searchFiltersString = '';

  searchFiltersArray.forEach((el) => {
    if (el.value) {
      searchFiltersString += `${searchFiltersString ? '|' : ''}${el.name};contains;${el.value}`;
    }
  });

  return searchFiltersString;
};
