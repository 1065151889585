import { Props } from './TradeCreditLimit.types';
import { FC } from 'react';
import { NoDataLabel } from 'components';
import { dateGenerator } from 'lib/utils/date';
import { MONITORING_STATE, SCORING_STATE, TradeCreditLimitAuthorizationErrorState } from 'api/models';
import classes from './tradeCreditLimit.module.scss';
import { numberWithSpace } from 'lib/utils';

const TradeCreditLimit: FC<Props> = ({
  tradeCreditLimit,
  tradeCreditLimitAuthorizationErrorState,
  calculatedAt,
  scoringState,
  monitoringState,
  lastDebtSynchronizationDate
}) => {
  const formattedDate = calculatedAt
    ? dateGenerator({
        date: calculatedAt,
        separator: '-'
      })
    : null;

  const createNoDataLabel = (text: string): JSX.Element => {
    return <NoDataLabel text={text} />;
  };

  const createStatusElement = (): JSX.Element | null => {
    if (monitoringState === undefined || monitoringState === MONITORING_STATE.DISABLED) {
      return (
        <div className={classes.borderedBoxForIcon}>
          <div className={classes.blackIcon}>-</div>
          Monitorowanie wyłączone
        </div>
      );
    }
    if (
      tradeCreditLimitAuthorizationErrorState === TradeCreditLimitAuthorizationErrorState.Forbidden ||
      tradeCreditLimitAuthorizationErrorState === TradeCreditLimitAuthorizationErrorState.MissingService
    ) {
      return createNoDataLabel(
        'Usługa nieaktywna. \n Jeśli chcesz aktywować usługę, skontaktuj się ze swoim opiekunem.'
      );
    }
    if (tradeCreditLimit?.value === null && scoringState === SCORING_STATE.ENABLED) {
      return createNoDataLabel(`Limit nie został wyznaczony. \n ${tradeCreditLimit.status}.`);
    }
    if (monitoringState === MONITORING_STATE.DISABLING) {
      return createNoDataLabel('Trwa wyłączanie monitorowania.');
    } else if (
      (monitoringState === MONITORING_STATE.ADDED_IN_MPRO && lastDebtSynchronizationDate) ||
      (monitoringState === MONITORING_STATE.ENABLED && scoringState === SCORING_STATE.ERROR_DISABLING)
    ) {
      return createNoDataLabel('Trwa pobieranie informacji.');
    } else if (
      monitoringState === MONITORING_STATE.CREATED ||
      monitoringState === MONITORING_STATE.ADDED_IN_MPRO ||
      (monitoringState === MONITORING_STATE.ENABLED && scoringState === SCORING_STATE.CREATED)
    ) {
      return createNoDataLabel('Trwa włączanie monitorowania.');
    } else if (
      monitoringState === MONITORING_STATE.ERROR_GETTING_DEBT ||
      monitoringState === MONITORING_STATE.ERROR_GETTING_DEBT_FINAL ||
      monitoringState === MONITORING_STATE.ERROR_ENABLING ||
      scoringState === SCORING_STATE.ERROR_GETTING_DEBT ||
      scoringState === SCORING_STATE.ERROR_ENABLING
    ) {
      return createNoDataLabel('Pobranie informacji nie powiodło się. Skontaktuj się z Administratorem KG.');
    } else if (monitoringState === MONITORING_STATE.ERROR_DISABLING || scoringState === SCORING_STATE.ERROR_DISABLING) {
      return createNoDataLabel('Nie udało się wyłączyć monitorowania. Skontaktuj się z Administratorem KG.');
    } else if (tradeCreditLimit?.value === undefined && tradeCreditLimit?.status === undefined) {
      return <div></div>;
    }

    return null;
  };

  const status = createStatusElement();

  return (
    <>
      <div className={classes.containerBox}>
        {status !== null ? (
          status
        ) : (
          <div className={classes.tradeCreditLimitBox}>
            <div className={classes.boldText}>Kwota</div>
            <div>Wyznaczony</div>
            <div>Model</div>
            <div className={classes.boldText}>{numberWithSpace(tradeCreditLimit?.value?.toFixed(2))} PLN</div>
            <div>{formattedDate}</div>
            <div>{tradeCreditLimit?.model}</div>
          </div>
        )}
      </div>
    </>
  );
};

export default TradeCreditLimit;
