import { FC } from 'react';
import Box from '@mui/material/Box';
import { Props } from './StatusKrd.types';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { dateGenerator, numberWithSpace } from 'lib/utils';
import { KrdEvent } from '.';
import { NoDataLabel, PageSubtitle, Tooltip } from 'components';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import { MONITORING_STATE } from 'api/models/CustomerDto';
import ErrorIcon from '@mui/icons-material/Error';

const StatusKrd: FC<Props> = ({ data, monitoringEvents }) => {
  const formattedDate = data?.lastDebtSynchronizationDate
    ? dateGenerator({
        date: data.lastDebtSynchronizationDate,
        separator: '-',
        textBefore: '(',
        textAfter: ')'
      })
    : null;
  const events = !!monitoringEvents?.length
    ? monitoringEvents?.map((x, index) => <KrdEvent key={x.id} item={x} index={index + 1}></KrdEvent>)
    : null;

  let result: JSX.Element | null;

  const monitoringTooltipText =
    'Data w nawiasie oznacza datę ostatniego sprawdzenia zadłużenia w KRD. Więcej informacji na temat usługi monitorowania.';
  const eventsTooltipText =
    'Lista uwzględnia zdarzenia począwszy od dnia uruchomienia monitorowania, ale nie starsze niż 90 dni.';
  const monitoringTooltipLink = {
    linkName: 'Samouczek, punkt 3.1.',
    linkUrl: '/tutorial#punkt-3-1'
  };
  const tooltip = <Tooltip text={monitoringTooltipText} link={monitoringTooltipLink} hideDetailsText />;

  const monitoringState = data?.monitoringState;

  const defaultStyles = {
    maxWidth: '42.75rem',
    width: '100%',
    maxHeight: '19.3rem',
    overflow: 'auto'
  };

  if (
    monitoringState === MONITORING_STATE.ERROR_GETTING_DEBT ||
    monitoringState === MONITORING_STATE.ERROR_GETTING_DEBT_FINAL
  ) {
    result = (
      <Box sx={defaultStyles}>
        <NoDataLabel text="Pobranie informacji nie powiodło się. Skontaktuj się z Administratorem KG." />
      </Box>
    );
  } else if (monitoringState === MONITORING_STATE.DISABLING) {
    result = (
      <Box sx={defaultStyles}>
        <NoDataLabel text={'Trwa wyłączanie monitorowania.'} />
      </Box>
    );
  } else if (monitoringState === MONITORING_STATE.ERROR_ENABLING) {
    result = (
      <Box sx={defaultStyles}>
        <NoDataLabel text={'Nie udało się uruchomić monitorowania. Skontaktuj się z Administratorem KG.'} />
      </Box>
    );
  } else if (monitoringState === MONITORING_STATE.ERROR_DISABLING) {
    result = (
      <Box sx={defaultStyles}>
        <NoDataLabel text={'Nie udało się wyłączyć monitorowania. Skontaktuj się z Administratorem KG.'} />
      </Box>
    );
  } else if (
    monitoringState === MONITORING_STATE.CREATED ||
    (monitoringState === MONITORING_STATE.ADDED_IN_MPRO && !data?.lastDebtSynchronizationDate)
  ) {
    result = (
      <Box sx={defaultStyles}>
        <NoDataLabel text={'Trwa włączanie monitorowania.'} />
      </Box>
    );
  } else if (monitoringState === MONITORING_STATE.ADDED_IN_MPRO && data?.lastDebtSynchronizationDate) {
    result = (
      <Box sx={defaultStyles}>
        <NoDataLabel text={'Trwa pobieranie informacji.'} />
      </Box>
    );
  } else if (data?.isDebtor) {
    result = (
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          border: '1px solid #F51B42;',
          marginTop: '1rem',
          padding: '0 0.8rem',
          borderRadius: '4px',
          fontWeight: '500',
          width: '42.7rem',
          height: '4.1rem',
          color: '#F51B42'
        }}>
        <ErrorIcon sx={{ color: '#F51B42', width: '3rem', height: '3rem', margin: '0.5rem 0.5rem 0.5rem 0' }} />W bazie
        KRD: {numberWithSpace(data?.debt?.toFixed(2))} PLN {formattedDate} {tooltip}
      </Box>
    );
  } else if (!monitoringState || monitoringState === MONITORING_STATE.DISABLED) {
    result = null;
  } else {
    result = (
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          border: '1px solid #29A745;',
          marginTop: '1rem',
          padding: '0 0.8rem',
          borderRadius: '4px',
          fontWeight: '500',
          maxWidth: '42.5rem'
        }}>
        <CheckCircleIcon sx={{ color: '#29A745', width: '3rem', height: '3rem', margin: '0.5rem 0.5rem 0.5rem 0' }} />
        Brak wpisu w bazie KRD {formattedDate} {tooltip}
      </Box>
    );
  }

  return (
    <>
      {monitoringState !== MONITORING_STATE.DISABLED && result}
      <Box
        sx={{
          maxWidth: '42.7rem',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          margin: '2rem 0 0.5rem 0'
        }}>
        <PageSubtitle subtitle="Zdarzenia w KRD" />
        <Box sx={{ marginRight: 'auto', display: 'flex' }}>
          <Tooltip text={eventsTooltipText} />
        </Box>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          Ostatnie 90 dni <CalendarTodayIcon sx={{ marginLeft: '0.5rem' }} />
        </Box>
      </Box>
      <Box sx={{ maxWidth: '42.75rem', width: '100%', maxHeight: '19.3rem', overflow: 'auto' }}>
        {events ? (
          events
        ) : (
          <NoDataLabel
            text={
              monitoringState === MONITORING_STATE.ERROR_GETTING_DEBT_FINAL
                ? 'Nie udało się pobrać informacji o saldzie zadłużenia. Skontaktuj się z Administratorem KG.'
                : 'Brak zdarzeń w KRD.'
            }
          />
        )}
      </Box>
    </>
  );
};

export default StatusKrd;
