import { TradeCreditLimitAuthorizationErrorState } from 'api/models';
import { CUSTOMER_FIELDS } from '../customersList.types';

export const initialValues = {
  [CUSTOMER_FIELDS.NAME]: '',
  [CUSTOMER_FIELDS.TAX_NUMBER]: '',
  [CUSTOMER_FIELDS.MONITORING_STATE]: '',
  [CUSTOMER_FIELDS.MONITORING_ID]: '',
  [CUSTOMER_FIELDS.LAST_MONITORING_EVENT_DATE]: '',
  [CUSTOMER_FIELDS.DEBT_AMOUNT_PLN]: '',
  [CUSTOMER_FIELDS.REPORT]: false,
  [CUSTOMER_FIELDS.STATE]: '',
  [CUSTOMER_FIELDS.REPORT_GENERETED_AT]: '',
  [CUSTOMER_FIELDS.ACTION]: '',
  [CUSTOMER_FIELDS.DEBT_VALUE_FROM]: 0,
  [CUSTOMER_FIELDS.DEBT_VALUE_TO]: 0,
  [CUSTOMER_FIELDS.MONITOR_CLIENT]: 2,
  [CUSTOMER_FIELDS.MONITOR_CLIENT_ENABLED]: false,
  [CUSTOMER_FIELDS.MONITOR_CLIENT_DISABLED]: false,
  [CUSTOMER_FIELDS.PAYMENT_CREDIBILITY_DEBTOR]: false,
  [CUSTOMER_FIELDS.PAYMENT_CREDIBILITY_NON_DEBTOR]: false,
  [CUSTOMER_FIELDS.PAYMENT_CREDIBILITY]: 2,
  [CUSTOMER_FIELDS.COMPANY_ID]: '',
  [CUSTOMER_FIELDS.SCORING]: '',
  [CUSTOMER_FIELDS.LAST_DEBT]: 0,
  [CUSTOMER_FIELDS.LAST_EVENT]: 0,
  [CUSTOMER_FIELDS.RISK_GROUP]: '',
  [CUSTOMER_FIELDS.RISK_GROUP_CODE]: '',
  [CUSTOMER_FIELDS.RISK_GROUP_IDS]: '',
  [CUSTOMER_FIELDS.SCORING_ID]: undefined,
  [CUSTOMER_FIELDS.GROSS_DEBT_FROM]: 0,
  [CUSTOMER_FIELDS.GROSS_DEBT_TO]: 0,
  [CUSTOMER_FIELDS.GROSS_SALE_LAST_12_MONTHS_FROM]: 0,
  [CUSTOMER_FIELDS.GROSS_SALE_LAST_12_MONTHS_TO]: 0,
  [CUSTOMER_FIELDS.RISK_GROUP_CODES]: '',
  [CUSTOMER_FIELDS.GROSS_SALE_LAST_12_MONTHS]: 0,
  [CUSTOMER_FIELDS.GROSS_DEBT]: 0,
  [CUSTOMER_FIELDS.IS_IMPORT_OUTDATED]: false,
  [CUSTOMER_FIELDS.IMPORT_DATE]: '',
  [CUSTOMER_FIELDS.SCORING_STATUS]: '',
  [CUSTOMER_FIELDS.SCORING_STATE]: '',
  [CUSTOMER_FIELDS.SCORING_STATUS_CODE]: '',
  [CUSTOMER_FIELDS.MONITORING_END_DATE]: '',
  [CUSTOMER_FIELDS.DESCRIPTION1]: '',
  [CUSTOMER_FIELDS.DESCRIPTION2]: '',
  [CUSTOMER_FIELDS.TRADE_CREDIT_LIMIT]: '',
  [CUSTOMER_FIELDS.TRADE_CREDIT_LIMIT_AUTHORIZATION_ERROR_STATE]: TradeCreditLimitAuthorizationErrorState.None,
  [CUSTOMER_FIELDS.TRADE_CREDIT_LIMIT_VALUE]: 0,
  [CUSTOMER_FIELDS.TRADE_CREDIT_LIMIT_MODEL]: '',
  [CUSTOMER_FIELDS.TRADE_CREDIT_LIMIT_STATUS]: '',
  [CUSTOMER_FIELDS.TRADE_CREDIT_LIMIT_CHECKBOX_VALUE]: [],
  [CUSTOMER_FIELDS.TRADE_CREDIT_LIMIT_CHECKBOX_VALUE_FROM]: 0,
  [CUSTOMER_FIELDS.TRADE_CREDIT_LIMIT_CHECKBOX_VALUE_TO]: 0,
  [CUSTOMER_FIELDS.CHANGE_CREDIBILITY]: 0,
  [CUSTOMER_FIELDS.CHANGE_LIMIT]: 0
};

export enum TOOLTIPS {
  MONITORING = 'Filtr pozwala wylistować klientów, dla których monitorowanie w KRD jest włączone lub wyłączone.',
  CREDIBILITY = 'Filtr pozwala wylistować klientów posiadających wskazane kategorie wiarygodności płatniczej.',
  DEBT = 'Filtr pozwala wylistować klientów według wartości długu wpisanego do KRD.',
  COMPANY = 'Możesz filtrować listę klientów według nazwy klienta',
  REPORTS = 'Filtr pozwala wylistować klientów, dla których został pobrany raport (w ostatnich 7 dniach).',
  CLIENTSLIST = 'Lista uwzględnia wszystkich klientów dodanych do aplikacji w ramach Twojej firmy. Możesz filtrować wyniki za pomocą filtrów.',
  SALE_GROSS = 'Filtr pozwala wylistować klientów według wartości sprzedaży brutto (jeśli dane te zostały wprowadzone do aplikacji).',
  DEBT_GROSS = 'Filtr pozwala wylistować klientów według wartości należności brutto (jeśli dane te zostały wprowadzone do aplikacji).',
  LAST_DEBT = 'Filtr pozwala wylistować klientów, dla których w KRD dodany został dług we wskazanym okresie.',
  KRD = 'Filtr pozwala wylistować klientów, dla których w KRD wystąpiło zdarzenie we wskazanym okresie.',
  TRADE_CREDIT_LIMIT = 'Filtr pozwala wylistować klientów według wysokości limitu kupieckiego.',
  CHANGE_CREDITBILITY = 'Filtr pozwala wylistować klientów, dla których nastąpiła zmiana kategorii wiarygodności płatniczej we wskazanym okresie.',
  CHANGE_LIMIT = 'Filtr pozwala wylistować klientów, dla których nastąpiła zmiana wysokości limitu kupieckiego we wskazanym okresie.'
}

export enum ACTIONS {
  START_MONITORING = '1',
  CANCEL_MONITORING = '0'
}
