import { memo, FC } from 'react';
import moment from 'moment';
import { TableCell, TableRow } from '@mui/material';
import Name from './Name';
import { CredibilityIcon } from 'assets/images/icons';
import { numberWithSpace } from 'lib/utils';
import { FIELDS, HistoryDate, Row, TYPES, datesRadioButtons, HISTORY_FIELDS } from '../historyChangesList.types';
import { parseType } from '../utils';
import classes from '../HistoryChangesList.module.scss';
import { HeadCell } from './TableHead.types';
import { RowsProps } from './Rows.types';
import { PATH } from 'router/routes';
import { useFormikFilters } from 'lib/hooks';

const Rows: FC<RowsProps> = memo(({ rows, daysCells, isSummaryView, formik }) => {
  const newValue = (value: string | null, type: TYPES) => {
    if (type === TYPES.SCORING && value && typeof value === 'string') {
      return <CredibilityIcon letter={value === 'X' ? 'none' : value} />;
    } else if (type === TYPES.DEBT && value && typeof value === 'string') {
      return numberWithSpace(value);
    } else {
      return '';
    }
  };
  const [sessionFilters, setSessionFilters] = useFormikFilters('historyFilterValues');

  const tableCellStyles = { padding: '1.5rem', whiteSpace: 'nowrap' };
  const tableCellStylesWithBorder = { ...tableCellStyles, borderRight: '1px solid rgba(224, 224, 224, 1)' };

  return (
    <>
      {rows.map((row: Row, id: number) => {
        return (
          <TableRow
            key={`${row.taxIdentificationNumber}${id}`}
            hover
            tabIndex={-1}
            id={`history-changes-row-${id + 1}`}>
            <TableCell
              onClick={() => window.open(`${PATH.CUSTOMER_PROFILE}/${row.counterpartyId}`, '_blank')}
              sx={{ ...tableCellStyles, textDecoration: 'underline', cursor: 'pointer' }}
              id={`history-changes-row-name-${id + 1}`}>
              <Name name={row.name} />
            </TableCell>
            <TableCell
              onClick={() => window.open(`${PATH.CUSTOMER_PROFILE}/${row.counterpartyId}`, '_blank')}
              id={`history-changes-row-tax-${id + 1}`}
              sx={{ ...tableCellStyles, cursor: 'pointer' }}>
              {row.taxIdentificationNumber?.replace('PL', '')}
            </TableCell>
            <TableCell
              onClick={() => window.open(`${PATH.CUSTOMER_PROFILE}/${row.counterpartyId}`, '_blank')}
              id={`history-changes-row-type-${id + 1}`}
              sx={{ ...tableCellStylesWithBorder, cursor: 'pointer' }}>
              {parseType(row.type)}
            </TableCell>
            {daysCells.map((el: HeadCell, id: number) => {
              const styles = {
                ...(el.id === (isSummaryView ? FIELDS.WEEK_1 : FIELDS.DAY_1)
                  ? tableCellStylesWithBorder
                  : tableCellStyles),
                textAlign: 'right',
                paddingRight: '14px'
              };

              const isCurrentWeek = el.id === 'current_week';
              const weekId = isCurrentWeek ? 1 : parseInt(el.id?.split('week')[1]);
              const foundWeek = isCurrentWeek ? datesRadioButtons[1] : datesRadioButtons?.[weekId + 1];

              const dayDataId = `history-changes-row-day-${el.id}`;
              const foundDate = row.dates?.find((date: HistoryDate) => {
                const isSameDay = moment(date.day).isSame(moment(el.labelBottom), 'day');
                const isLastItem = daysCells.length === id + 1;

                if (isLastItem && isSameDay) {
                  return null;
                }

                if (isSameDay) {
                  return date;
                }
              });

              const handleChangeFilter = () => {
                if (foundWeek) {
                  const parsedFilters = sessionFilters && JSON.parse(sessionFilters);
                  const filters = {
                    ...parsedFilters,
                    [HISTORY_FIELDS.DATES]: foundWeek.label,
                    [HISTORY_FIELDS.SELECTED_DATE]: foundWeek.value
                  };
                  setSessionFilters(JSON.stringify(filters));
                  formik.setFieldValue(HISTORY_FIELDS.DATES, foundWeek.label);
                  formik.setFieldValue(HISTORY_FIELDS.SELECTED_DATE, foundWeek.value);
                }
              };

              if (isSummaryView) {
                return (
                  <TableCell
                    onClick={handleChangeFilter}
                    id={dayDataId}
                    sx={{ ...styles, cursor: 'pointer' }}
                    className={classes.newValue}
                    key={el.id}>
                    {newValue(daysCells?.length === id + 1 ? row.currentValue : row?.entries?.[id]?.newValue, row.type)}
                  </TableCell>
                );
              } else {
                if (foundDate) {
                  return (
                    <TableCell id={dayDataId} sx={styles} className={classes.newValue} key={el.id}>
                      {newValue(foundDate.newValue, row.type)}
                    </TableCell>
                  );
                }
                if (el.id === FIELDS.DAY_CURRENT) {
                  return (
                    <TableCell id={dayDataId} sx={styles} className={classes.newValue} key={el.id}>
                      {newValue(row.currentValue, row.type)}
                    </TableCell>
                  );
                } else {
                  return <TableCell id={dayDataId} sx={styles} key={el.id}></TableCell>;
                }
              }
            })}
          </TableRow>
        );
      })}
    </>
  );
});

export default Rows;
