import { useCallback } from 'react';
import { useParams } from 'react-router-dom';
import { Paper, Breadcrumbs, Loader } from 'components';
import { PATH } from 'router/routes';
import { updateReport, getReport } from 'api/services';
import { useFetch } from 'lib/hooks';
import { FormikSubmitValues, ReportForm } from '../shared';
import { Box } from '@mui/material';
import SideNav from 'components/SideNav';
import classes from '../../../App.module.scss';
import { ReportRecipientItemDto, ReportRiskGroupItemDto } from 'api/models';

export enum FIELDS {
  COMPANY_ID = 'companyId',
  REPORT_ID = 'reportId',
  NAME = 'name',
  RISK_GROUPS = 'riskGroups',
  FREQUENCY = 'frequency',
  RECIPIENTS = 'recipients',
  SHOULD_CONTAIN_DEBTORS = 'shouldContainDebtors'
}

export type FormikValues = {
  [FIELDS.COMPANY_ID]: number;
  [FIELDS.REPORT_ID]: number;
  [FIELDS.NAME]: string;
  [FIELDS.RISK_GROUPS]: number[];
  [FIELDS.FREQUENCY]: number;
  [FIELDS.RECIPIENTS]: number[];
  [FIELDS.SHOULD_CONTAIN_DEBTORS]: boolean;
};

const EditReport = () => {
  const { id } = useParams();

  const { result, isLoading } = useFetch<any>(useCallback(() => getReport(id || ''), [id]));
  const breadcrumbs = [{ to: PATH.REPORTS_LIST, label: 'Kreator raportów' }];

  const valuesForForm: FormikValues = {
    [FIELDS.COMPANY_ID]: result?.companyId || 0,
    [FIELDS.REPORT_ID]: result?.reportId || 0,
    [FIELDS.NAME]: result?.name || '',
    [FIELDS.FREQUENCY]: result?.frequency || 0,
    [FIELDS.RECIPIENTS]: result?.recipients.map((recipient: ReportRecipientItemDto) => recipient.externalId) || [],
    [FIELDS.RISK_GROUPS]: result?.riskGroups.map((riskGroup: ReportRiskGroupItemDto) => riskGroup.externalId) || [],
    [FIELDS.SHOULD_CONTAIN_DEBTORS]: result?.shouldContainDebtors || false
  };

  const handleSubmit = useCallback((values: FormikSubmitValues) => updateReport(id || '', values), [id]);

  return (
    <Box sx={{ display: 'flex' }}>
      <Box className={classes.sidebar}>
        <SideNav />
      </Box>
      <Paper fullSize>
        <Breadcrumbs breadcrumbs={breadcrumbs} />
        <Loader isLoading={isLoading}>
          <ReportForm inEditMode initialValues={valuesForForm} submitHandler={handleSubmit} />
        </Loader>
      </Paper>
    </Box>
  );
};

export default EditReport;
