// TODO: #989
// import { useCallback, useEffect, useState } from 'react';
// import { useAuth } from 'oidc-react';
// import { useDispatch } from 'react-redux';
// import { BrowserRouter } from 'react-router-dom';
// import { owuType } from './index.types';
// import { ROLE } from 'config/oidc';
// import { Layout, ActivityDetector, Loader } from 'components';
// import { getCompanyThunk } from 'store/company';
// import { useFetch } from 'lib/hooks';
// import { getCompanyOwuAgreement } from 'api/services';
// import { CompanyOwuStatusDto } from 'api/models';
// import Routes from './Rts';

// const Router = () => {
//   const [owuData, setOwuData] = useState<owuType>({ ignore: true, companyId: '' });

//   const dispatch = useDispatch();
//   const auth = useAuth();
//   const userRole = auth?.userData?.profile?.Role;
//   const companyId = auth?.userData?.profile?.Company;

//   const { result: owuStatus, isLoading } = useFetch<CompanyOwuStatusDto>(
//     useCallback(() => getCompanyOwuAgreement(owuData.companyId), [owuData.companyId]),
//     [owuData.companyId],
//     owuData.ignore ?? [ROLE.ADMIN_KG, ROLE.INTEGRATOR].includes(userRole)
//   );

//   useEffect(() => {
//     if (companyId) {
//       if (userRole !== ROLE.CLIENT_USER) {
//         dispatch(getCompanyThunk(companyId));
//       }
//       setOwuData({ ignore: false, companyId });
//     }
//   }, [companyId, dispatch, userRole]);

//   return (
//     <BrowserRouter>
//       <Layout showNav={![ROLE.CLIENT_ADMIN, ROLE.CLIENT_USER].includes(userRole) || !!owuStatus?.isAgreementsAccepted}>
//         <ActivityDetector>
//           <Loader isLoading={auth?.isLoading || isLoading}>
//             <Routes owuStatus={owuStatus} />
//           </Loader>
//         </ActivityDetector>
//       </Layout>
//     </BrowserRouter>
//   );
// };

// export default Router;

import { useEffect, useState } from 'react';
import { useAuth } from 'oidc-react';
import { BrowserRouter, Route, Routes as RouterRoutes } from 'react-router-dom';
import { ROLE } from 'config/oidc';
import { Layout, ActivityDetector, Loader } from 'components';
import { getCompanyThunk } from 'store/company';
import Routes from './Rts';
import { GeneratingReport } from 'domains';
import { PATH } from './routes';
import DeactivatedUser from 'domains/deactivatedUser';
import { LOCAL_STORAGE_ACTIONS } from 'components/ActivityDetector/ActivityDetector.types';
import { useSelector, useDispatch } from 'react-redux';
import * as signalR from '@microsoft/signalr';
import { setConnection, connectionSelector } from 'store/connection';

const Router = () => {
  const dispatch = useDispatch();
  const auth = useAuth();
  const userRole = auth?.userData?.profile?.Role;
  const companyId = auth?.userData?.profile?.Company;
  const curatorSelectedCompanyId = localStorage.getItem(LOCAL_STORAGE_ACTIONS.CURATOR_SELECTED_COMPANY_ID);
  const authToken = localStorage.getItem(LOCAL_STORAGE_ACTIONS.AUTH_TOKEN);
  const hubConnectionUrl = process.env.REACT_APP_API_URL + '/hubs/push-notifications';

  const dispatchCompany = (companyId: string) => {
    if (authToken && window.location.pathname !== PATH.SESSION_EXPIRED) {
      dispatch(getCompanyThunk(companyId));
    }
  };

  const connection = useSelector(connectionSelector);

  useEffect(() => {
    const pushNotificationsConnection = new signalR.HubConnectionBuilder()
      .configureLogging(signalR.LogLevel.Debug)
      .withUrl(hubConnectionUrl, {
        accessTokenFactory: () => localStorage.getItem(LOCAL_STORAGE_ACTIONS.AUTH_TOKEN) || '',
        skipNegotiation: true,
        transport: signalR.HttpTransportType.WebSockets
      })
      .withAutomaticReconnect()
      .build();

    dispatch(setConnection(pushNotificationsConnection));
  }, []);

  useEffect(() => {
    const authToken = localStorage.getItem(LOCAL_STORAGE_ACTIONS.AUTH_TOKEN);

    if (!auth?.isLoading && auth?.userData && authToken && connection) {
      connection.start().then(() => {
        connection.invoke('SubscribeForNotifications', parseInt(companyId));
      });
    }
  }, [auth, connection]);

  useEffect(() => {
    if (companyId) {
      if (![ROLE.CLIENT_USER, ROLE.CURATOR, ROLE.INTEGRATOR].includes(userRole)) {
        dispatchCompany(companyId);
      }

      if (userRole === ROLE.CURATOR && curatorSelectedCompanyId) {
        dispatchCompany(curatorSelectedCompanyId);
      }
    }
  }, [companyId, dispatch, userRole]);

  useEffect(() => {
    const { hash } = window.location;
    if (hash !== '') {
      // Push onto callback queue so it runs after the DOM is updated,
      // this is required when navigating from a different page so that
      // the element is rendered on the page before trying to getElementById.
      setTimeout(() => {
        const id = decodeURIComponent(hash).replace('#', '');
        const element = document.getElementById(id);
        if (element) element.scrollIntoView();
      }, 0);
    }
  });

  if (window.location.pathname === PATH.SHOW_REPORT) {
    return (
      <BrowserRouter>
        <RouterRoutes>
          <Route path={PATH.SHOW_REPORT} element={<GeneratingReport />} />
        </RouterRoutes>
      </BrowserRouter>
    );
  } else if (window.location.pathname === PATH.DEACTIVATED_USER) {
    return (
      <BrowserRouter>
        <RouterRoutes>
          <Route path={PATH.DEACTIVATED_USER} element={<DeactivatedUser />} />
        </RouterRoutes>
      </BrowserRouter>
    );
  } else {
    return (
      <BrowserRouter>
        <Layout>
          <ActivityDetector>
            <Loader isLoading={auth?.isLoading}>
              <Routes />
            </Loader>
          </ActivityDetector>
        </Layout>
      </BrowserRouter>
    );
  }
};

export default Router;
