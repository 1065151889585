import { FIELDS } from '../../shared';

export const initialValues = {
  [FIELDS.COMPANY_ID]: 0,
  [FIELDS.REPORT_ID]: 0,
  [FIELDS.NAME]: '',
  [FIELDS.RISK_GROUPS]: [],
  [FIELDS.FREQUENCY]: 7,
  [FIELDS.RECIPIENTS]: [],
  [FIELDS.SHOULD_CONTAIN_DEBTORS]: false
};
