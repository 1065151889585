import {
  ApiResponse,
  ReportItemDto,
  ReportRecipientItemDto,
  ReportRiskGroupItemDto,
  ReportUsersDto,
  ReportUserDto
} from 'api/models';
import { AxiosPromise } from 'axios';
import { ReactElement } from 'react';

export enum FIELDS {
  COMPANY_ID = 'companyId',
  REPORT_ID = 'reportId',
  NAME = 'name',
  RISK_GROUPS = 'riskGroups',
  FREQUENCY = 'frequency',
  RECIPIENTS = 'recipients',
  SHOULD_CONTAIN_DEBTORS = 'shouldContainDebtors'
}

export type FormikValues = {
  [FIELDS.COMPANY_ID]: number;
  [FIELDS.REPORT_ID]: number;
  [FIELDS.NAME]: string;
  [FIELDS.RISK_GROUPS]: number[];
  [FIELDS.FREQUENCY]: number;
  [FIELDS.RECIPIENTS]: number[];
  [FIELDS.SHOULD_CONTAIN_DEBTORS]: boolean;
};

export type FormikSubmitValues = {
  [FIELDS.COMPANY_ID]: number;
  [FIELDS.REPORT_ID]: number;
  [FIELDS.NAME]: string;
  [FIELDS.RISK_GROUPS]: ReportRiskGroupItemDto[];
  [FIELDS.FREQUENCY]: number;
  [FIELDS.RECIPIENTS]: ReportRecipientItemDto[];
  [FIELDS.SHOULD_CONTAIN_DEBTORS]: boolean;
};

export type FieldItem = {
  name: FIELDS;
  label: string;
  value: string | number;
  error?: boolean;
  helperText?: string | false;
  disabled?: boolean;
  styles?: object;
  tooltipText?: string | ReactElement;
};

export type FieldType = FieldItem[];

export type Props = {
  inEditMode?: boolean;
  initialValues: FormikValues;
  submitHandler: (values: FormikSubmitValues) => AxiosPromise<ApiResponse<string>>;
};

export type RecipientsListProps = {
  selectedAllRecipients: boolean;
  setSelectedAllRecipients: (flag: boolean) => void;
  searchValue: string;
  setSearchValue: (value: string) => void;
  checkAll: (event: any, field: FIELDS, state: boolean, callback: Function, availableValues: number[]) => void;
  users: ReportUsersDto | null;
  recipientsValue: number[];
  multiCheckboxHandler: (event: any, field: FIELDS, currentValues: number[]) => void;
};

export type FormControlProps = {
  riskGroup: ReportUserDto;
  multiCheckboxHandler: (event: any, field: FIELDS, currentValues: number[]) => void;
  recipientsValue: number[];
};

export type RecipientsProps = {
  multiCheckboxHandler: (event: any, field: FIELDS, currentValues: number[]) => void;
  recipientsValue: number[];
  users: ReportUsersDto | null;
  searchValue: string;
};
