import { FIELDS, ReportRow } from '../reportsList.types';

export const createRows = (report?: ReportRow[]) => {
  if (!report) return [];

  return report.map((result) => ({
    [FIELDS.REPORT_ID]: result.id,
    [FIELDS.NAME]: result.name,
    [FIELDS.REPORT_TYPE]: result.reportType,
    [FIELDS.FREQUENCY]: result.frequency,
  }));
};