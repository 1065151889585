import { FC, useCallback, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useFormik } from 'formik';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import { ButtonProps } from '@mui/material/Button';
import { PageTitle, TextField, Dialog, StyledButton, StyledCheckbox, RadioGroup, StyledRadioButton } from 'components';
import { Props as DialogProps } from 'components/Dialog/Dialog.types';
import { PATH } from 'router/routes';
import { useFetch, useSendRequest, useToggle } from 'lib/hooks';
import { FIELDS, Props as ComponentProps } from '../types';
import { createTheme, FormControl, FormControlLabel, FormHelperText } from '@mui/material';
import { useAuth } from 'oidc-react';
import { getDictionariesUsers, getRiskGroups } from 'api/services';
import { ReportUsersDto, RiskGroupDto, RiskGroupItemDto } from 'api/models';
import { reportSchema } from '../schema';
import { useDispatch } from 'react-redux';
import { setSuccessMessage } from 'store/success';
import { ROLE } from 'config/oidc';
import { useDidUpdateEffect } from 'lib/hooks';
import RecipientsList from './RecipientsList';

export const theme = createTheme({
  components: {
    MuiFormControlLabel: {
      styleOverrides: {
        label: {
          overflow: 'hidden',
          textOverflow: 'ellipsis'
        }
      }
    }
  }
});

const ReportForm: FC<ComponentProps> = ({ inEditMode, initialValues, submitHandler }) => {
  const [isAcceptModalOpen, setAcceptModalOpen] = useState(false);
  const [isCancelModalOpen, toggleCancelModal] = useToggle();
  const [currentCompany, setCurrentCompany] = useState<string>('');
  const [selectedAllTypes, setSelectedAllTypes] = useState(false);
  const [selectedAllRecipients, setSelectedAllRecipients] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const [checkboxError, setCheckboxError] = useState('');

  const navigate = useNavigate();
  const { id } = useParams();
  const dispatch = useDispatch();

  const curatorSelectedCompanyId = localStorage.getItem('curatorSelectedCompanyId');
  const auth = useAuth();
  const userRole = auth?.userData?.profile?.Role;
  const companyId =
    userRole === ROLE.CURATOR && curatorSelectedCompanyId ? curatorSelectedCompanyId : auth?.userData?.profile?.Company;

  useEffect(() => {
    if (companyId) {
      setCurrentCompany(companyId);
    }
  }, [companyId]);

  const { result: riskGroupsDict } = useFetch<RiskGroupDto>(
    useCallback(() => getRiskGroups(), [currentCompany]),
    [currentCompany],
    !currentCompany
  );

  const { result: users } = useFetch<ReportUsersDto>(
    useCallback(() => getDictionariesUsers(currentCompany), [currentCompany]),
    [currentCompany],
    !currentCompany
  );

  const {
    result: postResult,
    isLoading: isDataSending,
    validationErrors,
    sendData,
    setValidationErrors
  } = useSendRequest();

  const submitCallback = useCallback(() => {
    setAcceptModalOpen(false);
    dispatch(setSuccessMessage('Dane zostały zapisane'));
  }, [id, inEditMode, navigate, dispatch]);

  const formik = useFormik({
    initialValues,
    validationSchema: reportSchema,
    onSubmit: (values, helpers) => {
      const recipients =
        users?.results
          .filter((item) => formik.values[FIELDS.RECIPIENTS].includes(item.id))
          .map((item) => ({ ...item, externalId: item.id })) ?? [];
      const riskGroups =
        riskGroupsDict?.riskGroups
          .filter((item) => formik.values[FIELDS.RISK_GROUPS].includes(item.id))
          .map((item) => ({ ...item, externalId: item.id })) ?? [];
      const frequencyToNumber = Number(formik.values[FIELDS.FREQUENCY]);

      const newValues = {
        ...values,
        riskGroups: riskGroups,
        frequency: frequencyToNumber,
        companyId: Number(companyId),
        recipients: recipients
      };

      sendData(
        () => submitHandler(newValues),
        submitCallback,
        inEditMode
          ? () => {
              setAcceptModalOpen(false);
            }
          : undefined
      );
    }
  });

  const riskGroups = formik.values.riskGroups;
  const shouldContainDebtors = formik.values.shouldContainDebtors;

  useDidUpdateEffect(() => {
    if (!shouldContainDebtors && !riskGroups?.length) {
      setCheckboxError('Pole jest wymagane.');
    } else {
      setCheckboxError('');
    }
  }, [riskGroups, shouldContainDebtors]);

  useEffect(() => {
    if (!isDataSending && postResult) {
      setTimeout(closeWindow, 5000);
    }
  }, [isDataSending, inEditMode, postResult, navigate]);

  const checkRequiredFields = () => {
    const requiredFields = [FIELDS.NAME];

    return requiredFields.every((field) => formik.values[field]);
  };

  const checkOneOf = () => (!formik.values.riskGroups?.length && !formik.values.shouldContainDebtors ? false : true);

  const shouldFormBeDisabled =
    !checkRequiredFields() || !checkOneOf() || checkboxError
      ? true
      : false || Object.keys(formik.errors).length > 0 || validationErrors.length > 0 || !!postResult || !formik.dirty;

  const closeWindow = (): void => {
    window.close();
  };

  const acceptButtonProps: ButtonProps = {
    variant: 'contained',
    disabled: shouldFormBeDisabled || isDataSending,
    type: inEditMode ? 'button' : 'submit',
    onClick: inEditMode ? () => setAcceptModalOpen(true) : undefined
  };

  const cancelButtonLabel = inEditMode
    ? 'Czy chcesz anulować modyfikację ustawień raportu?’'
    : 'Czy chcesz anulować dodanie nowego raportu?';

  const cancelDialogProps: DialogProps = {
    isOpen: isCancelModalOpen,
    closeModal: toggleCancelModal,
    accept: inEditMode ? () => navigate(`${PATH.REPORTS_LIST}`) : closeWindow,
    acceptLabel: 'Tak',
    closeLabel: 'Nie'
  };

  const radioBoxHandler = (event: any, field: FIELDS) => {
    const { value, checked } = event.target;

    if (checked) {
      formik.setFieldValue(field, value);
    }
  };

  const shouldContainDebtorsHandler = (event: any) => {
    formik.setFieldValue(FIELDS.SHOULD_CONTAIN_DEBTORS, !formik.values[FIELDS.SHOULD_CONTAIN_DEBTORS]);
  };

  const multiCheckboxHandler = useCallback((event: any, field: FIELDS, currentValues: number[]) => {
    const { value, checked } = event.target;
    const valueNumber = Number(value);
    if (checked) {
      const newValues = [...currentValues, valueNumber];
      formik.setFieldValue(field, newValues);
    } else {
      const newValues = currentValues.filter((item) => item !== valueNumber);
      formik.setFieldValue(field, newValues);
    }
  }, []);

  const checkAll = useCallback(
    (event: any, field: FIELDS, state: boolean, callback: Function, availableValues: number[]) => {
      if (event.target.checked) {
        formik.setFieldValue(field, availableValues);

        if (field === FIELDS.RISK_GROUPS) {
          formik.setFieldValue(FIELDS.SHOULD_CONTAIN_DEBTORS, true);
        }
      } else {
        formik.setFieldValue(field, []);
        if (field === FIELDS.RISK_GROUPS) {
          formik.setFieldValue(FIELDS.SHOULD_CONTAIN_DEBTORS, false);
        }
      }
      callback(!state);
    },
    []
  );

  const pageTitle = inEditMode ? 'Modyfikacja raportu' : 'Nowy raport';

  return (
    <Box sx={{ padding: '1rem 0' }}>
      <PageTitle dataTest="new-report-page-title" sx={{ padding: '0 1.5rem' }}>
        {pageTitle}
      </PageTitle>
      <form onSubmit={formik.handleSubmit}>
        <Box sx={{ display: 'flex', borderBottom: '1px solid #E0E0E0', paddingBottom: '2.25rem' }}>
          <Box sx={{ padding: '0 1.5rem', width: '50%' }}>
            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
              <Box sx={{ fontSize: '20px', marginTop: '20px' }}>Nazwa raportu</Box>
              <Box sx={{ display: 'flex', width: '100%', justifyContent: 'space-between' }} key={1}>
                <TextField
                  key={'Nazwa raportu'}
                  field={{
                    name: FIELDS.NAME,
                    label: 'Nazwa raportu',
                    value: formik.values[FIELDS.NAME],
                    error: formik.touched[FIELDS.NAME] && !!formik.errors[FIELDS.NAME],
                    helperText: formik.touched[FIELDS.NAME] && formik.errors[FIELDS.NAME]
                  }}
                  validationErrors={validationErrors}
                  onChangeHandler={formik.handleChange}
                  onBlurHandler={formik.handleBlur}
                  errorsSetter={setValidationErrors}
                  autoFocus={true}
                  disabled={false}
                  dataTest="new-report-client-name-input"
                />
              </Box>
            </Box>
          </Box>
        </Box>
        <Box sx={{ display: 'flex', borderBottom: '1px solid #E0E0E0', paddingBottom: '2.25rem' }}>
          <Box sx={{ padding: '0 1.5rem', width: '50%' }}>
            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
              <Box sx={{ fontSize: '20px', marginTop: '20px' }}>Zawartość raportu</Box>
              <Box sx={{ padding: '20px 0 0 5px', display: 'flex', flexDirection: 'column' }}>
                <FormControl component="fieldset">
                  <Box sx={{ width: '100%' }}>
                    <FormControlLabel
                      onChange={(event) => {
                        checkAll(
                          event,
                          FIELDS.RISK_GROUPS,
                          selectedAllTypes,
                          setSelectedAllTypes,
                          riskGroupsDict?.riskGroups?.map((item) => item.id) ?? []
                        );
                      }}
                      name={'selectAll'}
                      key={'selectAll'}
                      control={
                        <StyledCheckbox
                          id="new-report-select-all-risk-checkbox"
                          sx={{ padding: '0.2rem 0.2rem 0.2rem 0.2rem' }}
                          checked={selectedAllTypes}
                        />
                      }
                      label={'Zaznacz/odznacz wszystkie'}
                    />
                  </Box>
                  {riskGroupsDict?.riskGroups?.map((riskGroup: RiskGroupItemDto) => (
                    <Box sx={{ width: '100%' }} key={riskGroup.id}>
                      <FormControlLabel
                        onChange={(event) =>
                          multiCheckboxHandler(event, FIELDS.RISK_GROUPS, formik.values[FIELDS.RISK_GROUPS])
                        }
                        value={riskGroup.id}
                        name={'riskGroupCode'}
                        key={riskGroup.id}
                        control={
                          <StyledCheckbox
                            sx={{ padding: '0.2rem 0.2rem 0.2rem 0.2rem', pointerEvents: 'none' }}
                            checked={formik.values[FIELDS.RISK_GROUPS].includes(riskGroup.id)}
                            id={`new-report-select-${riskGroup?.code}-risk-checkbox`}
                          />
                        }
                        label={`Klienci z kategorią wiarygodności płatniczej ${riskGroup.code}`}
                      />
                    </Box>
                  ))}
                  <Box sx={{ width: '100%' }}>
                    <FormControlLabel
                      onChange={shouldContainDebtorsHandler}
                      name={'shouldContainDebtors'}
                      key={'shouldContainDebtors'}
                      control={
                        <StyledCheckbox
                          sx={{ padding: '0.2rem 0.2rem 0.2rem 0.2rem', pointerEvents: 'none' }}
                          checked={!!formik.values[FIELDS.SHOULD_CONTAIN_DEBTORS] === true}
                          id="new-report-select-all-krd-clients-checkbox"
                        />
                      }
                      label={`Klienci wpisani do KRD`}
                    />
                  </Box>
                  <FormHelperText error={checkboxError ? true : false}>{checkboxError}</FormHelperText>
                </FormControl>
              </Box>
            </Box>
            <Box sx={{ margin: '1rem 0 0 0' }}>
              <Box sx={{ fontSize: '20px', marginTop: '20px' }}>Częstotliwość wysyłania raportu</Box>
              <RadioGroup title="" noPadding={true} isLast={true}>
                <Box sx={{ display: 'flex', padding: '0 0 0 .3rem', flexDirection: 'column' }}>
                  <Box>
                    <FormControlLabel
                      onChange={(event) => radioBoxHandler(event, FIELDS.FREQUENCY)}
                      value={1}
                      name={'frequency'}
                      key={'b1'}
                      control={
                        <StyledRadioButton
                          sx={{ padding: '0.2rem 0.2rem 0.2rem 0.2rem' }}
                          checked={Number(formik.values[FIELDS.FREQUENCY]) === 1}
                          id="new-report-radio-button-1"
                        />
                      }
                      label={'co 1 dzień'}
                    />
                  </Box>
                  <Box>
                    <FormControlLabel
                      onChange={(event) => radioBoxHandler(event, FIELDS.FREQUENCY)}
                      value={7}
                      name={'frequency'}
                      key={'b2'}
                      control={
                        <StyledRadioButton
                          sx={{ padding: '0.2rem 0.2rem 0.2rem 0.2rem' }}
                          checked={Number(formik.values[FIELDS.FREQUENCY]) === 7}
                          id="new-report-radio-button-2"
                        />
                      }
                      label={'co 1 tydzień (poniedziałek)'}
                    />
                  </Box>
                  <Box>
                    <FormControlLabel
                      onChange={(event) => radioBoxHandler(event, FIELDS.FREQUENCY)}
                      value={14}
                      name={'frequency'}
                      key={'b3'}
                      control={
                        <StyledRadioButton
                          sx={{ padding: '0.2rem 0.2rem 0.2rem 0.2rem' }}
                          checked={Number(formik.values[FIELDS.FREQUENCY]) === 14}
                          id="new-report-radio-button-3"
                        />
                      }
                      label={'co 2 tygodnie (poniedziałek)'}
                    />
                  </Box>
                  <Box>
                    <FormControlLabel
                      onChange={(event) => radioBoxHandler(event, FIELDS.FREQUENCY)}
                      value={30}
                      name={'frequency'}
                      key={'b4'}
                      control={
                        <StyledRadioButton
                          sx={{ padding: '0.2rem 0.2rem 0.2rem 0.2rem' }}
                          checked={Number(formik.values[FIELDS.FREQUENCY]) === 30}
                          id="new-report-radio-button-4"
                        />
                      }
                      label={'co miesiąc (1-szego kalendarzowego dnia miesiąca)'}
                    />
                  </Box>
                </Box>
              </RadioGroup>
            </Box>
          </Box>
        </Box>

        <RecipientsList
          multiCheckboxHandler={multiCheckboxHandler}
          recipientsValue={formik.values[FIELDS.RECIPIENTS]}
          selectedAllRecipients={selectedAllRecipients}
          setSelectedAllRecipients={setSelectedAllRecipients}
          setSearchValue={setSearchValue}
          searchValue={searchValue}
          users={users}
          checkAll={checkAll}
        />

        <Stack spacing={3} direction="row" sx={{ padding: '1.5rem 1.5rem 0.5rem 1.5rem', textTransform: 'uppercase' }}>
          <StyledButton id="new-report-save-button" {...acceptButtonProps}>
            Zapisz raport
          </StyledButton>
          <StyledButton id="new-report-cancel-button" variant="text" onMouseDown={toggleCancelModal}>
            Anuluj
          </StyledButton>
        </Stack>
      </form>

      {inEditMode && (
        <Dialog
          isOpen={[...validationErrors].length === 0 && isAcceptModalOpen}
          isPending={isDataSending}
          closeModal={() => setAcceptModalOpen(false)}
          accept={formik.submitForm}
          acceptLabel="Tak"
          closeLabel="Nie">
          Czy chcesz zapisać wprowadzone zmiany?
        </Dialog>
      )}

      <Dialog {...cancelDialogProps}>{cancelButtonLabel}</Dialog>
    </Box>
  );
};

export default ReportForm;
