import { ReportDetailsDto } from '.';

export enum REPORT_STATE_MESSAGE {
  NO_ACCESS = 'ERROR_REPORT_REQUEST_NO_ACCESS'
}

export type ReportDto = {
  result: string;
};

export type ReportItemDto = {
  id: number;
  name: string;
  riskGroups: ReportRiskGroupItemDto[];
  frequency: number;
  shouldContainDebtors: boolean;
  recipients: ReportRecipientItemDto[];
};

export type ReportRiskGroupItemDto = {
  externalId: number;
};

export type ReportRecipientItemDto = {
  externalId: number;
  email: string;
  firstName: string;
  lastName: string;
};

export type RiskGroupDto = {
  riskGroups: RiskGroupItemDto[];
};

export type RiskGroupItemDto = {
  id: number;
  code: string;
  value: string;
};

export type ReportUsersDto = {
  results: ReportUserDto[];
  totalSize: number;
};

export type ReportUserDto = {
  id: number;
  email: string;
  firstName: string;
  lastName: string;
};

export type LatestReportsDto = {
  availableReportsCount: number;
  reports: ReportDetailsDto[];
};

export type ExportCounterpartiesDto = {
  monitorClient: number;
  debtValueFrom: number;
  debtValueTo: number;
  report: boolean;
  companyId: number;
  riskGroupIds: number[];
  grossSaleLast12MonthsFrom: number;
  grossSaleLast12MonthsTo: number;
  grossDebtFrom: number;
  grossDebtTo: number;
  lastDebt: number;
  lastEvent: number;
  orderByColumn: string;
  orderByAscending: boolean;
  filters: string;
  tradeCreditLimitChangePeriod: number;
  riskGroupChangePeriod: number;
  tradeCreditLimitFilter: number;
  tradeCreditLimitFrom: number;
  tradeCreditLimitTo: number;
};

export type ExportHistoryDto = {
  companyId: number;
  types: number[];
  orderByColumn: string;
  orderByAscending: boolean;
  filters: string;
};

export type ReportHistoryItemDto = {
  counterpartyName: string;
  taxIdentificationNumber: string;
  requesterUserName: string;
  generatedAt: string;
  reportRequestId: number;
  state: number;
};
