import { memo, FC } from 'react';
import { ReportUserDto } from 'api/models';
import { FIELDS, RecipientsProps, FormControlProps } from '../types';
import Box from '@mui/material/Box';
import { createTheme, FormControlLabel } from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';
import { StyledCheckbox } from 'components';

export const theme = createTheme({
  components: {
    MuiFormControlLabel: {
      styleOverrides: {
        label: {
          overflow: 'hidden',
          textOverflow: 'ellipsis'
        }
      }
    }
  }
});

const FormControl: FC<FormControlProps> = memo(({ riskGroup, multiCheckboxHandler, recipientsValue }) => {
  return (
    <Box sx={{ width: '100%' }}>
      <ThemeProvider theme={theme}>
        <FormControlLabel
          sx={{ whiteSpace: 'nowrap', marginRight: '20px', maxWidth: '100%' }}
          onChange={(event) => multiCheckboxHandler(event, FIELDS.RECIPIENTS, recipientsValue)}
          value={riskGroup.id}
          name={'user'}
          key={riskGroup.id}
          control={
            <StyledCheckbox
              sx={{ padding: '0.2rem 0.2rem 0.2rem 0.2rem' }}
              checked={recipientsValue.includes(riskGroup.id)}
            />
          }
          label={
            <Box sx={{ textOverflow: 'ellipsis', overflow: 'hidden' }}>
              {riskGroup.firstName} {riskGroup.lastName}
            </Box>
          }
        />
      </ThemeProvider>
    </Box>
  );
});

const Recipents: FC<RecipientsProps> = memo(({ users, multiCheckboxHandler, recipientsValue, searchValue }) => {
  const reciepientsLength = users?.results?.length;
  const halfOfReciepients = reciepientsLength ? Math.ceil(reciepientsLength / 2) : 0;
  const sortedList = users?.results.sort((a: ReportUserDto, b: ReportUserDto) => a.lastName.localeCompare(b.lastName));

  const recipientsList = (start: number, end: number = 0) => {
    return (
      <Box sx={{ display: 'flex', width: '50%', flexDirection: 'column', padding: '0 1rem' }}>
        {sortedList
          ?.filter(
            (riskGroup: ReportUserDto) =>
              `${riskGroup.firstName.toLocaleLowerCase()} ${riskGroup.lastName.toLocaleLowerCase()}`.includes(
                searchValue.length > 2 ? searchValue.toLocaleLowerCase() : ''
              ) ||
              `${riskGroup.lastName.toLocaleLowerCase()} ${riskGroup.firstName.toLocaleLowerCase()}`.includes(
                searchValue.length > 2 ? searchValue.toLocaleLowerCase() : ''
              )
          )
          .slice(start, end)
          .map((riskGroup: ReportUserDto) => (
            <FormControl
              key={riskGroup.id}
              riskGroup={riskGroup}
              multiCheckboxHandler={multiCheckboxHandler}
              recipientsValue={recipientsValue}
            />
          ))}
      </Box>
    );
  };

  return (
    <Box sx={{ overflow: 'scroll', height: '200px', position: 'relative', display: 'flex', padding: '1rem 0' }}>
      {recipientsList(0, halfOfReciepients)}
      {recipientsList(halfOfReciepients, users?.results?.length)}
    </Box>
  );
});

export default Recipents;
