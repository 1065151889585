import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import styles from '../components/tutorial.module.scss';

export type TutorialContentSectionProps = {
  name: string;
  index: string;
  subsections: Array<TutorialContentSubsectionProps>;
};

export type TutorialContentSubsectionProps = {
  name: string;
  index: string;
  id: string;
  content: () => JSX.Element;
};

export type TutorialMenuSectionProps = {
  name: string;
  index: string;
  subsections: Array<TutorialMenuSubsectionProps>;
};

export type TutorialMenuSubsectionProps = {
  name: string;
  index: string;
  id: string;
};

export const TutorialContent = [
  {
    name: 'Zakładka "Lista klientów"',
    subsections: [
      {
        name: 'Jak działa moduł filtrowania?',
        content: function (): JSX.Element {
          return (
            <div className={styles.tutorialSubSectionWrapper}>
              <p>
                Moduł filtrowania zawiera szereg filtrów służących do zawężania wyników na liście klientów. Możesz
                dowolnie ustawiać poszczególne filtry, aby dopasować listę klientów do bieżących potrzeb. Filtry
                działają jednocześnie, co oznacza, że jeśli ustawisz np. trzy filtry naraz, to na liście wyników
                zobaczysz klientów spełniających jednocześnie te trzy kryteria. Jeśli nie użyjesz żadnego filtru, to na
                liście klientów znajdziesz wszystkich klientów Twojej firmy, którzy zostali dodani do RiskRadar.
              </p>
              <p>W module dostępne są następujące filtry: </p>
              <ul>
                <li>
                  Monitorowanie klienta – pozwala wylistować klientów, którzy mają włączone / wyłączone monitorowanie w
                  RiskRadar, przy czym:
                  <ul>
                    <li>
                      jeśli zaznaczona zostanie opcja „włączone” to lista wyników uwzględni klientów, którzy mają
                      monitorowanie włączone i poprawnie uruchomione,
                    </li>
                    <li>
                      jeśli zaznaczona zostanie opcja „wyłączone” to lista wyników uwzględni klientów, którzy: mają
                      monitorowanie wyłączone, mają monitorowanie w trakcie uruchamiania, mają monitorowanie w trakcie
                      wyłączania,
                    </li>
                    <li>
                      jeśli obie opcje (tj. „włączone” oraz „wyłączone”) są jednocześnie zaznaczone lub odznaczone to
                      lista wyników uwzględni wszystkich klientów danej firmy (o ile nie są ustawione inne filtry).
                    </li>
                  </ul>
                </li>
                <li>
                  Sprzedaż brutto - pozwala wylistować klientów, do których wartość sprzedaży brutto jest we wskazanym
                  przedziale kwotowym.
                </li>
                <li>
                  Należności brutto - pozwala wylistować klientów, od których wartość należności brutto jest we
                  wskazanym przedziale kwotowym.
                </li>
                <li>
                  Wiarygodność płatnicza – pozwala wylistować klientów, którzy posiadają daną kategorię wiarygodności
                  płatniczej według informacji z KRD BIG S.A.
                </li>
                <li>
                  Zadłużenie w KRD – pozwala wylistować klientów, których zadłużenie według danych z KRD BIG S.A. jest
                  we wskazanym przedziale kwotowym.
                </li>
                <li>
                  Limit kupiecki – pozwala wylistować klientów, których kwota limitu kupieckiego jest we wskazanym
                  przedziale kwotowym lub limit kupiecki nie został wyznaczony.
                </li>
                <li>
                  Ostatni dług dodany do KRD – pozwala wylistować klientów, dla których zidentyfikowane zostało
                  zdarzenie dotyczące dopisania negatywnej informacji gospodarczej do KRD BIG S.A. w dniu wczorajszym
                  lub w ostatnich 7 / 30 / 60 / 90 dniach lub jest brak zdarzeń.
                </li>
                <li>
                  Zdarzenia w KRD – pozwala wylistować klientów, dla których pojawiły się jakiekolwiek zdarzenia w
                  ramach sprawdzania zdarzeń w KRD BIG S.A. w dniu wczorajszym lub w ostatnich 7 / 30 / 60 / 90 dniach
                  lub jest brak zdarzeń.
                </li>
                <li>
                  Zmiana wiarygodności – pozwala wylistować klientów, dla których nastąpiła zmiana kategorii
                  wiarygodności płatniczej w dniu dzisiejszym lub w ostatnich 7 / 30 / 60 / 90 dniach lub zmiana nie
                  wystąpiła.
                </li>
                <li>
                  Zmiana limitu kupieckiego - pozwala wylistować klientów, dla których nastąpiła zmiana limitu
                  kupieckiego w dniu dzisiejszym lub w ostatnich 7 / 30 / 60 / 90 dniach lub zmiana nie wystąpiła.
                </li>
                <li>
                  Pobrany raport o kliencie – pozwala wylistować klientów, dla których został pobrany od KG przynajmniej
                  jeden raport (w ramach usługi „Raport KG”) i nie jest on starszy niż 7 dni.
                </li>
              </ul>
              <p>
                Ustawienia filtrowania i wyszukiwania są zapamiętywane do zakończenia sesji. Oznacza to, że jeśli
                przykładowo ustawisz kilka filtrów i następnie przejdziesz do innej zakładki (np. do Samouczka), to przy
                powrocie do zakładki „Lista klientów” ustawienia filtrów i wyszukiwania będą takie jak były wcześniej,
                nie zostaną wyczyszczone. Jeśli chcesz wyczyścić filtry, skorzystaj z przycisku „Wyczyść filtry”.
              </p>
              <p>
                W przypadku, gdy wartości w filtrach nie były zmieniane lub przywrócono im wartości domyślne, wówczas
                przycisk „Wyczyść filtry” jest szary i nieaktywny. Jeśli natomiast wartości w filtrach były zmieniane
                przez użytkownika i są inne niż domyślne, wówczas przycisk będzie aktywny i koloru zielonego.
              </p>
              <p className={styles.textUnderline}>Przykład zastosowania filtrów</p>
              <p>
                Jeśli chcesz sprawdzić, którzy monitorowani klienci zostali usunięci z systemu KRD BIG S.A. w ostatnim
                tygodniu, to ustaw filtry w następujący sposób:
              </p>
              <ul>
                <li>Zadłużenie w KRD: od 0 do 0,</li>
                <li>Zdarzenia w KRD: do 7 dni.</li>
              </ul>
              <div className={styles.tutorialImageWrapper}>
                <img
                  src={require('../../../assets/images/tutorial/1-1a.png')}
                  alt="Jak działa moduł filtrowania?"
                  className={styles.verticalImg}
                />
                <img
                  src={require('../../../assets/images/tutorial/1-1b.png')}
                  alt="Jak działa moduł filtrowania?"
                  className={`${styles.verticalImg} ${styles.verticalImgFullHeight}`}
                />
              </div>
            </div>
          );
        }
      },
      {
        name: 'Jak działa moduł z listą klientów?',
        content: function (): JSX.Element {
          return (
            <div className={styles.tutorialSubSectionWrapper}>
              <p>
                Moduł z listą klientów zawiera najważniejsze informacje o klientach. Pozycje na liście zawierają
                informacje o poszczególnych klientach. Możesz elastycznie dopasowywać listę klientów do bieżących
                potrzeb za pomocą szeregu filtrów.
              </p>
              <p>
                Może zdarzyć się sytuacja, że dany klient wystąpi więcej niż jeden raz na liście. Może to wynikać z
                tego, że klient ten był dodany do RiskRadar zarówno ręcznie, jak i za pomocą importu CSV. Dotyczy to
                również sytuacji, gdy ręcznie zostaną dodani klienci o tym samym numerze NIP (np. oddziały danej firmy).
                W takich sytuacjach informacje o usługach (tj. zadłużenie w KRD BIG S.A., kategoria wiarygodności
                płatniczej, raport) będą identyczne dla każdego wiersza z tym samym numerem NIP. Opłaty za usługi są
                wówczas naliczane jak za jeden podmiot.
              </p>
              <p>
                Jeśli interesuje Cię konkretny klient, to z widoku listy klientów możesz otworzyć kartę tego klienta i
                zapoznać się z bardziej szczegółowymi informacjami na jego temat.
              </p>
              <p>W module tym możesz włączać usługę monitorowania klienta. Można to zrobić na dwa sposoby:</p>
              <ul>
                <li>pojedynczo dla wybranego klienta,</li>
                <li>grupowo dla wielu wybranych klientów jednocześnie.</li>
              </ul>
              <p>
                Moduł ten daje też możliwość pobierania aktualnego raportu na temat danego klienta oraz otwarcia
                ostatniego raportu pobranego w ciągu ostatnich 7 dni.
              </p>
              <p>Lista klientów składa się z następujących kolumn:</p>
              <ul>
                <li>Checkboxy – służą do wykonywania akcji grupowych na wielu klientach,</li>
                <li>Klient - zawiera nazwę klienta (informacja dodana ręcznie lub w procesie importu pliku CSV),</li>
                <li>NIP - zawiera NIP klienta (informacja dodana ręcznie lub w procesie importu pliku CSV),</li>
                <li>
                  Wiarygodność – zawiera informację o kategorii wiarygodności płatniczej klienta (informacja dostarczona
                  przez KRD BIG S.A.),
                </li>
                <li>
                  Zadłużenie w KRD (PLN) - zawiera wartość łącznego zadłużenia danego klienta na podstawie informacji
                  negatywnych wpisanych do KRD BIG S.A. (dane dostarczone przez KRD BIG S.A.),
                </li>
                <li>
                  Limit kupiecki (PLN) - zawiera informację o limicie kupieckim klienta (informacja dostarczona przez
                  KRD BIG S.A.),
                </li>
                <li>Raporty – zawiera linki do raportów (raporty są dostarczane przez KG sp.j.),</li>
                <li>Opis 1 – zawiera dowolny opis klienta wprowadzony przez użytkownika,</li>
                <li>Opis 2 – zawiera dowolny opis klienta wprowadzony przez użytkownika,</li>
                <li>
                  Sprzedaż (PLN) – zawiera wartość sprzedaży brutto (informacja dodana w procesie importu pliku CSV),
                </li>
                <li>
                  Należności (PLN) - zawiera wartość należności brutto (informacja dodana w procesie importu pliku CSV).
                </li>
              </ul>
              <p>
                W module tym masz też możliwość ręcznego dodawania klientów za pomocą przycisku „Dodaj klienta”. Po jego
                kliknięciu w nowej zakładce przeglądarki otwiera się ekran, za pomocą którego można dodać podmiot do
                RiskRadar.
              </p>
              <p>
                Wyniki na liście klientów są stronicowane. Domyślnie na jednej stronie prezentowanych jest 100 klientów.
                Możesz zmienić to ustawienie według własnych preferencji (np. 10, 20, 50 wyników na jednej stronie).
              </p>
              <div className={styles.tutorialImageWrapper}>
                <img
                  src={require('../../../assets/images/tutorial/1-2.png')}
                  alt="Jak działa moduł z listą klientów?"
                  className={styles.horizontallImg}
                />
              </div>
            </div>
          );
        }
      },
      {
        name: 'Jak działa moduł z wykresem?',
        content: function (): JSX.Element {
          return (
            <div className={styles.tutorialSubSectionWrapper}>
              <p>
                Moduł ten przedstawia portfel klientów dodanych do RiskRadar według kategorii wiarygodności płatniczej.
              </p>
              <p>
                Wykres słupkowy obrazuje liczbę klientów, wartość sprzedaży lub wartość należności dotyczącą klientów w
                poszczególnych kategoriach wiarygodności płatniczej – od A do H.
              </p>
              <p>
                Domyślnie wykres pokazuje liczbę klientów, jednak można to zmienić na wartość sprzedaży lub wartość
                należności. W celu zmiany wyświetlanych wartości, należy korzystać z przycisków „Liczba klientów”,
                „Sprzedaż” lub „Należności” w prawej górnej części modułu.
              </p>
              <p>
                W ramach pozycji „Brak kategorii” uwzględniani są klienci, dla których monitorowanie jest włączone,
                jednak w danym momencie nie jest możliwe wykonanie analizy wiarygodności płatniczej. Przyczyny braku
                kategorii są opisane w punkcie „4.1. Czym jest kategoria wiarygodności płatniczej w ramach analizy
                wiarygodności płatniczej?” w Samouczku.
              </p>
              <p>
                Wykres zawiera też pozycję „Brak włączonego monitorowania” – pokazuje ona dane dotyczące klientów, dla
                których aktualnie nie jest uruchomione monitorowanie, a tym samym nie ma informacji o kategorii
                wiarygodności płatniczej, zdarzeniach, poziomie zadłużenia.
              </p>
              <p>
                Kliknięcie na przyciski z liczbami klientów znajdujące się bezpośrednio pod wykresem spowoduje
                automatyczne ustawienie kryteriów filtrowania oraz wylistowanie klientów spełniających te kryteria.
                Przykładowo, jeśli klikniesz na przycisk „19 klientów” znajdujący się pod słupkiem z kategorią D, to w
                filtrze „Wiarygodność płatnicza” zostanie zaznaczona pozycja D, a na liście klientów pojawi się 19
                klientów z kategorią wiarygodności płatniczej D.
              </p>
              <p>
                Wykres słupkowy obejmuje wszystkich klientów danej firmy dodanych do RiskRadar, także tych, którzy nie
                mają podanej wartości sprzedaży czy należności (RiskRadar przyjmuje wtedy, że wartość sprzedaży czy
                należności równa jest zeru). W przypadku, gdy w RiskRadar istnieje więcej niż jeden klient o tym samym
                numerze NIP, na potrzeby wykresu słupkowego liczony jest on pojedynczo (aby uniknąć wielokrotnego
                liczenia np. sprzedaży).
              </p>
              <p>
                Sekcja „Klienci wpisani do KRD” stanowi podsumowanie liczby klientów wpisanych do KRD BIG S.A. oraz
                aktualnego zadłużenia tych klientów. Liczba klientów w tej sekcji nie musi pokrywać się z liczbą
                klientów posiadających kategorię wiarygodności płatniczej H, ponieważ może się zdarzyć, że nawet klient
                posiadający kategorię A jest wpisany do KRD BIG S.A. na niewielką kwotę. Więcej informacji na temat
                kategorii wiarygodności płatniczej znajdziesz w punkcie „4.1. Czym jest kategoria wiarygodności
                płatniczej w ramach analizy wiarygodności płatniczej?” w Samouczku.
              </p>
              <p>
                Wykres kołowy pokazuje liczbę klientów w poszczególnych kategoriach wiarygodności płatniczej. Liczby
                klientów są tu analogiczne jak na wykresie słupkowym. Wewnątrz wykresu kołowego są widoczne 2 liczby:
              </p>
              <ul>
                <li>"X klientów w RiskRadar” – obrazuje, ilu klientów jest aktualnie w RiskRadar,</li>
                <li>„w tym monitorowanych Y” – obrazuje, ilu klientów ma aktualnie włączone monitorowanie.</li>
              </ul>
              <p>Poglądowa wizualizacja modułu z wykresem:</p>
              <div className={styles.tutorialImageWrapper}>
                <img
                  src={require('../../../assets/images/tutorial/1-3.png')}
                  alt="Jak działa moduł z wykresem?"
                  className={styles.horizontallImg}
                />
              </div>
            </div>
          );
        }
      },
      {
        name: 'Jak działają akcje grupowe?',
        content: function (): JSX.Element {
          return (
            <div className={styles.tutorialSubSectionWrapper}>
              <p>
                Akcje grupowe pozwalają na wykonywanie operacji na wielu klientach jednocześnie. W celu wykonania akcji
                grupowej trzeba najpierw zaznaczyć klientów. Możesz tego dokonać na dwa sposoby.
              </p>
              <ul>
                <li>
                  Po pierwsze, możesz zaznaczyć checkboxy przy poszczególnych klientach – ta metoda jest lepsza, gdy
                  chcesz zaznaczyć wybranych klientów z listy.
                </li>
                <li>
                  Po drugie, możesz zaznaczyć od razu wszystkich klientów na danej stronie za pomocą checkboxu zawartego
                  w nagłówku tabeli. Użycie tego checkboxa powoduje zaznaczenie wszystkich klientów widocznych na danej
                  stronie, nie powoduje natomiast zaznaczenia wszystkich klientów na liście wyników.
                  <ul>
                    <li>
                      Przykładowo, jeśli lista wyników zawiera 100 klientów, a na stronie widać 20 z nich, to użycie
                      checkboxu zawartego w nagłówku tabeli spowoduje zaznaczenie 20, a nie 100 klientów. Metoda ta jest
                      lepsza, gdy chcesz zaznaczyć szybko wszystkich klientów widocznych na danej stronie. Jeśli chcesz
                      oznaczyć za jednym razem większą liczbę klientów, zwiększ liczbę wyników pokazywanej na jednej
                      stronie z 20 do np. 100 za pomocą pola wyboru umieszczonego w prawej dolnej części modułu.
                    </li>
                  </ul>
                </li>
              </ul>
              <p>Po zaznaczeniu klientów możesz wykonać następujące akcje grupowe dotyczące wybranych klientów:</p>
              <ul>
                <li>
                  włączenie monitorowania – ta akcja uruchomi proces włączenia monitorowania na wszystkich wybranych
                  klientach, za wyjątkiem tych, dla których monitorowanie jest już włączone (więcej informacji na temat
                  włączania monitorowania znajdziesz w punkcie „3.2. Jak włączyć usługę monitorowania?” w Samouczku),
                </li>
                <li>
                  wyłączenie monitorowania - ta akcja uruchomi proces wyłączenia monitorowania na wszystkich wybranych
                  klientach, u których jest włączone monitorowanie.
                </li>
              </ul>
              <div className={styles.tutorialImageWrapper}>
                <img
                  src={require('../../../assets/images/tutorial/1-4.png')}
                  alt="Jak działają akcje grupowe?"
                  className={styles.horizontallImg}
                />
              </div>
              <p>
                Wykonywanie akcji grupowych jest wygodne, ale trzeba pamiętać, że ma wpływ na licznik wykorzystania
                usług. Jeśli przykładowo włączysz monitorowanie dla 100 klientów choćby na krótki czas (np. kilka
                sekund), to spowoduje to zwiększenie stanu licznika usługi monitorowania o 100. Jeśli Twój limit liczby
                monitorowanych klientów w ramach opłaty miesięcznej został już wcześniej wykorzystany, to za włączenie
                monitorowania na tych 100 klientach zostanie naliczona dodatkowa opłata jednostkowa zgodnie z cennikiem.
              </p>
            </div>
          );
        }
      },
      {
        name: 'Jak działa funkcja "Eksport do csv”? ',
        content: function (): JSX.Element {
          return (
            <div className={styles.tutorialSubSectionWrapper}>
              <p>
                Funkcja „Eksport do csv” pozwala wyeksportować dane z aktualnego widoku do pliku csv. Dzięki temu możesz
                pracować na tych danych poza serwisem RiskRadar, w tym przetwarzać je stosownie do Twoich potrzeb.
                Eksportowane są dane o klientach uwzględnionych na liście wyników w danej chwili, z uwzględnieniem
                filtrów, wyszukiwania, sortowania i wszystkich stron wyników. Gotowy plik csv z wyeksportowanymi danymi
                będzie dostępny w zakładce menu "Historia eksportu danych". Gdy plik będzie gotowy do pobrania,
                otrzymasz powiadomienie email. Plik jest dostępny do pobrania przez 7 dni, po tym czasie zostanie trwale
                usunięty z RiskRadar.
              </p>
              <div className={styles.tutorialImageWrapper}>
                <img
                  src={require('../../../assets/images/tutorial/1-5.png')}
                  alt='Jak działa funkcja "Eksport do csv”?'
                  className={styles.horizontallImg}
                />
              </div>
            </div>
          );
        }
      }
    ]
  },
  {
    name: 'Karta klienta',
    subsections: [
      {
        name: 'Sekcja "Dane klienta"',
        content: function (): JSX.Element {
          return (
            <div className={styles.tutorialSubSectionWrapper}>
              <p>
                Sekcja ta zawiera podstawowe informacje na temat danego klienta (NIP, nazwa, opis, adres). Pole „Źródło”
                zawiera informację, w jaki sposób klient został dodany do RiskRadar - ręcznie lub za pomocą importu CSV.
                Możesz edytować dane klienta, który został ręcznie dodany do RiskRadar. Nie możesz jednak edytować
                danych klienta, który został dodany do RiskRadar za pomocą importu CSV. Więcej informacji na temat
                importu CSV znajdziesz w punkcie „7.3. Do czego służy i jak działa import za pomocą pliku CSV?”
                Samouczka.
              </p>
              <p>
                Jeśli chcesz wprowadzić zmiany dla klienta dodanego ręcznie do RiskRadar, użyj przycisku „Modyfikuj”,
                który przeniesie Cię do ekranu edycji danych klienta w nowej zakładce przeglądarki. Nie ma możliwości
                edytowania pola z numerem NIP. Po wprowadzeniu zmian należy je zapisać za pomocą przycisku „Zapisz”.
                Jeśli chcesz dowiedzieć się więcej o dodawaniu nowego klienta do RiskRadar oraz usuwaniu klienta
                istniejącego, zapoznaj się z punktem „7. Zarządzanie klientami” w Samouczku.
              </p>
              <div className={styles.tutorialImageWrapper}>
                <img
                  src={require('../../../assets/images/tutorial/2-1.png')}
                  alt='Sekcja "Dane klienta"'
                  className={styles.horizontallImg}
                />
              </div>
            </div>
          );
        }
      },
      {
        name: 'Sekcja "Wiarygodność płatnicza"',
        content: function (): JSX.Element {
          return (
            <div className={styles.tutorialSubSectionWrapper}>
              <p>
                W tej sekcji prezentowana jest informacja o kategorii wiarygodności płatniczej klienta stanowiąca wynik
                analizy wiarygodności płatniczej. Informacja ta pochodzi z KRD BIG S.A. i jest udostępniana poprzez
                RiskRadar. Informacja o kategorii wiarygodności płatniczej jest generowana na bazie własnych algorytmów
                KRD BIG S.A., całkowicie niezależnie od informacji o klientach zawartych w RiskRadar. Informacja ta jest
                prezentowana tylko dla tych klientów, dla których w RiskRadar jest włączona usługa monitorowania. Zatem,
                jeśli chcesz posiadać informację o kategorii wiarygodności płatniczej danego klienta, najpierw włącz
                jego monitorowanie w RiskRadar. Analiza wiarygodności płatniczej jest jednym z elementów usługi
                monitorowania w RiskRadar. Jeśli chcesz dowiedzieć się więcej na temat analizy wiarygodności płatniczej,
                zapoznaj się z punktem „4.1. Czym jest kategoria wiarygodności płatniczej w ramach analizy wiarygodności
                płatniczej?” w Samouczku.
              </p>
              <div className={styles.tutorialImageWrapper}>
                <img
                  src={require('../../../assets/images/tutorial/2-3.png')}
                  alt='Sekcja "Wiarygodność płatnicza"'
                  className={styles.horizontallImg}
                />
              </div>
            </div>
          );
        }
      },
      {
        name: 'Sekcja "Monitorowanie"',
        content: function (): JSX.Element {
          return (
            <div className={styles.tutorialSubSectionWrapper}>
              <p>Sekcja zawiera bieżące informacje dotyczące danego klienta pochodzące z KRD BIG S.A. tj.:</p>
              <ul>
                <li>
                  informacja o łącznej kwocie zadłużenia na podstawie negatywnych informacji gospodarczych z KRD BIG
                  S.A. lub o braku informacji gospodarczych oraz
                </li>
                <li>
                  lista zdarzeń z ostatnich 90 dni będąca wynikiem sprawdzania zdarzeń dotyczących klienta o danym
                  numerze NIP w KRD BIG S.A.
                </li>
              </ul>
              <p>
                Sekcja zawiera również suwak służący do włączenia/wyłączenia usługi monitorowania w RiskRadar oraz
                komunikat o dacie wyłączenia monitorowania, jeśli użytkownik ustawił ją jako datę przyszłą (np. za
                tydzień od dziś). Jeśli chcesz posiadać tego rodzaju informacje o kliencie, najpierw włącz jego
                monitorowanie. Jeśli chcesz dowiedzieć się więcej na temat usługi monitorowania, zapoznaj się z punktem
                „3. Usługa monitorowania klienta” w Samouczku.
              </p>
              <div className={styles.tutorialImageWrapper}>
                <img
                  src={require('../../../assets/images/tutorial/2-4.png')}
                  alt='Sekcja "Monitorowanie"'
                  className={styles.horizontallImg}
                />
              </div>
            </div>
          );
        }
      },
      {
        name: 'Sekcja "Raporty"',
        content: function (): JSX.Element {
          return (
            <div className={styles.tutorialSubSectionWrapper}>
              <p>
                W tej sekcji prezentowane są raporty KG o kliencie pobrane z poziomu serwisu RiskRadar oraz z poziomu
                cyklicznych raportów e-mail. Masz też możliwość pobrania aktualnego raportu za pomocą przycisku „Pobierz
                nowy raport”. Jeśli w ramach Twojej organizacji raporty o danym kliencie pobrało kilku użytkowników, to
                w tej sekcji zostaną wyświetlone wszystkie te raporty. Raporty starsze niż 7 dni nie są prezentowane z
                uwagi na ryzyko ich nieaktualności. Jeśli jednak chcesz zachować raport na dłużej niż 7 dni, możesz go w
                każdej chwili pobrać na swoim urządzeniu jako plik PDF. Jeśli chcesz dowiedzieć się więcej na temat
                raportów, zapoznaj się z punktem „5. Usługa raportu o kliencie” w Samouczku.
              </p>
              <div className={styles.tutorialImageWrapper}>
                <img
                  src={require('../../../assets/images/tutorial/2-5.png')}
                  alt='Sekcja "Raporty"'
                  className={styles.horizontallImg}
                />
              </div>
            </div>
          );
        }
      },
      {
        name: 'Sekcja "Sprzedaż i należności"',
        content: function (): JSX.Element {
          return (
            <div className={styles.tutorialSubSectionWrapper}>
              <p>
                Sekcja ta zawiera informacje o wartości sprzedaży brutto za ostatnie 12 miesięcy oraz wartości
                należności brutto na dany dzień. Dane te są wprowadzane do RiskRadar w drodze importu pliku CSV (są to
                dane o charakterze opcjonalnym, nie ma konieczności ich wprowadzania). Nie ma możliwości wprowadzenia
                tych informacji w trybie ręcznego dodawania klienta. Dokonując porównań pomiędzy tymi wartościami warto
                pamiętać, że wartość sprzedaży dotyczy pewnego okresu (12 miesięcy), tymczasem wartość należności to
                stan na dany dzień (a dokładniej na dzień importu), przez co może także uwzględniać należności spoza
                okresu, którego dotyczy wartość sprzedaży. Daty podane w nawiasach oznaczają datę importu informacji do
                RiskRadar.
              </p>
              <div className={styles.tutorialImageWrapper}>
                <img
                  src={require('../../../assets/images/tutorial/2-2.png')}
                  alt='Sekcja "Sprzedaż i należności"'
                  className={styles.horizontallImg}
                />
              </div>
            </div>
          );
        }
      },
      {
        name: 'Sekcja „Limit kupiecki”',
        content: function (): JSX.Element {
          return (
            <div className={styles.tutorialSubSectionWrapper}>
              <p>
                W tej sekcji prezentowana jest informacja o limicie kupieckim klienta stanowiąca wynik analizy
                wiarygodności płatniczej. Informacja ta pochodzi z KRD BIG S.A. i jest udostępniana poprzez RiskRadar.
                Informacja o limicie kupieckim jest generowana na bazie własnych algorytmów KRD BIG S.A., całkowicie
                niezależnie od informacji o klientach zawartych w RiskRadar. Informacja ta jest prezentowana tylko dla
                tych klientów, dla których w RiskRadar jest włączona usługa monitorowania. Zatem, jeśli chcesz posiadać
                informację o limicie kupieckim danego klienta, najpierw włącz jego monitorowanie w RiskRadar. Analiza
                wiarygodności płatniczej jest jednym z elementów usługi monitorowania w RiskRadar. Jeśli chcesz
                dowiedzieć się więcej na temat analizy wiarygodności płatniczej, zapoznaj się z punktem „4.2. Czym jest
                limit kupiecki w ramach analizy wiarygodności płatniczej?” w Samouczku.
              </p>
              <p>W sekcji tej prezentowane są następujące informacje dotyczące limitu kupieckiego:</p>
              <ul>
                <li>rekomendowana kwota limitu kupieckiego wyznaczona dla danego kontrahenta,</li>
                <li>data wyznaczenia limitu kupieckiego,</li>
                <li>model, na podstawie którego została wyznaczona kwota limitu.</li>
              </ul>
              <p>Wizualizacja poglądowa dla sytuacji, gdy monitorowanie jest wyłączone:</p>
              <div className={styles.tutorialImageWrapper}>
                <img
                  src={require('../../../assets/images/tutorial/2-6.png')}
                  alt="Sekcja „Limit kupiecki”"
                  className={styles.horizontallImg}
                />
              </div>
            </div>
          );
        }
      }
    ]
  },
  {
    name: 'Usługa monitorowania klienta',
    subsections: [
      {
        name: 'Na czym polega usługa monitorowania?',
        content: function (): JSX.Element {
          return (
            <div className={styles.tutorialSubSectionWrapper}>
              <p>
                Monitorowanie klienta w RiskRadar polega na codziennym sprawdzaniu jego sytuacji w KRD BIG S.A. i
                wyświetlaniu następujących informacji:
              </p>
              <ul>
                <li>
                  zdarzeń odnotowanych w KRD BIG S.A. o danym podmiocie (w ramach usługi „Monitorowanie PRO” KRD BIG
                  S.A.),
                </li>
                <li>
                  wartości łącznego zadłużenia danego podmiotu (w ramach usługi „Udostępnianie informacji gospodarczej”
                  KRD BIG S.A.),
                </li>
                <li>
                  kategorii wiarygodności płatniczej danego podmiotu (w ramach usługi „Analiza wiarygodności płatniczej
                  podmiotów niebędących konsumentem” KRD BIG S.A.).
                </li>
                <li>
                  limitu kupieckiego danego podmiotu (w ramach usługi „Analiza wiarygodności płatniczej podmiotów
                  niebędących konsumentem” KRD BIG S.A.).
                </li>
              </ul>
              <p>
                Uruchomienie sprawdzania zdarzeń wyzwala uruchomienie pobierania informacji o wartości łącznego
                zadłużenia (informacja gospodarcza) oraz uruchomienie pobierania informacji o kategorii wiarygodności
                płatniczej i limicie kupieckim.
              </p>
              <p>
                KRD BIG S.A. jako Biuro Informacji Gospodarczej może zgodnie z „Ustawą o udostępnianiu informacji
                gospodarczych i wymianie danych gospodarczych” przyjmować informacje gospodarcze od wierzycieli,
                przechowywać je i ujawniać na zasadach określonych w tej ustawie. KRD BIG S.A. udostępnia tylko
                informacje aktualne, nie udostępnia informacji historycznych.
              </p>
              <p>
                KRD BIG S.A. udostępnia mechanizmy monitorowania zdarzeń związanych z udostępnianymi przez KRD BIG S.A.
                informacjami gospodarczymi w zakresie dotyczącym informacji o dłużniku na następujących zasadach:
              </p>
              <ul>
                <li>
                  mechanizm monitorowania polega na informowaniu o zmianach dotyczących monitorowanego podmiotu w
                  systemie KRD BIG S.A., w szczególności o fakcie dopisania, usunięcia lub aktualizacji informacji
                  gospodarczej, bez specyfikowania treści informacji gospodarczych, albo o braku zmian,
                </li>
                <li>
                  informacja dostarczana jest przez KRD BIG S.A. poprzez kanał komunikacyjny, którym jest RiskRadar,
                </li>
                <li>
                  informacja o zdarzeniu udzielona w ramach monitorowania nie ma charakteru informacji gospodarczej.
                </li>
              </ul>
              <h4>Sprawdzanie zdarzeń</h4>
              <p>W ramach sprawdzania zdarzeń w KRD BIG S.A. uwzględniane są: </p>
              <ul>
                <li>dodanie sumy zobowiązań od jednego wierzyciela na kwotę większą niż 0 zł.,</li>
                <li>usunięcie sumy zobowiązań od jednego wierzyciela na kwotę większą niż 0 zł.,</li>
                <li>aktualizacja sumy zobowiązań w górę o kwotę większą niż 0 zł.,</li>
                <li>aktualizacja sumy zobowiązań w dół o kwotę większą niż 0 zł.,</li>
                <li>łączna ilość wierzycieli dłużnika większa niż 0,</li>
                <li>łączna kwota zadłużenia w bazie dla dłużnika jest większa niż 0 zł.,</li>
                <li>dodanie dłużnika do bazy KRD BIG S.A.,</li>
                <li>usunięcie dłużnika z bazy KRD BIG S.A.,</li>
                <li>zmiana danych dłużnika (np. nazwy, adresu).</li>
              </ul>
              <h4>Pobieranie informacji o stanie zadłużenia</h4>
              <p>
                Pobieranie informacji o wartości łącznego zadłużenia polega na odpytywaniu bazy KRD BIG S.A. o
                informacje gospodarcze w postaci salda zadłużenia danego podmiotu.
              </p>
              <p>
                Pobranie informacji gospodarczej o wysokości łącznego zadłużenia danego podmiotu jest wykonywane po raz
                pierwszy w momencie udostępnienia usługi „Monitorowanie PRO” oraz później za każdym razem, gdy (w ramach
                uruchomionej już usługi „Monitorowanie PRO”) wystąpiło jedno z poniższych zdarzeń odnośnie danego
                podmiotu:
              </p>
              <ul>
                <li>aktualizacja sumy zobowiązań w górę,</li>
                <li>aktualizacja sumy zobowiązań w dół,</li>
                <li>dodanie sumy zobowiązań od jednego Wierzyciela,</li>
                <li>usunięcie sumy zobowiązań od jednego Wierzyciela,</li>
                <li>łączna ilość wierzycieli dłużnika,</li>
                <li>łączna kwota zadłużenia w bazie dla dłużnika,</li>
                <li>dodanie dłużnika do bazy KRD,</li>
                <li>usunięcie dłużnika z bazy KRD.</li>
              </ul>
              <p>Dla danego podmiotu w ciągu jednej doby może wystąpić tylko jedno z tych zdarzeń albo żadne z nich.</p>
              <p>
                W przypadku braku powyższych zdarzeń w ramach usługi „Monitorowanie PRO” powodujących aktualizację
                wysokości łącznego zadłużenia, pobranie informacji gospodarczej o wysokości łącznego zadłużenia danego
                podmiotu jest wykonywane zawsze po 90 dniach od ostatniego pobrania informacji gospodarczej o wysokości
                łącznego zadłużenia danego podmiotu.
              </p>
              <h4>Pobieranie informacji o kategorii wiarygodności płatniczej</h4>
              <p>
                Kategoria wiarygodności płatniczej danego podmiotu jest nadawana przez KRD BIG S.A. w postaci liter od A
                do H w wyniku analizy wiarygodności płatniczej tego podmiotu.
              </p>
              <p>
                Pobranie informacji o kategorii wiarygodności płatniczej danego podmiotu jest wykonywane po raz pierwszy
                w momencie udostępnienia usługi „Monitorowanie PRO” a następnie raz na dobę w godzinach rannych.
              </p>
              <p>
                W przypadku wystąpienia zdarzeń wpływających na kategorię wiarygodności płatniczej (np. znaczny wzrost
                zadłużenia w systemie KRD BIG S.A.) informacja o zmianie kategorii wiarygodności płatniczej w serwisie
                RiskRadar może być widoczna z opóźnieniem jednego dnia, ponieważ pobieranie informacji o kategorii
                wiarygodności płatniczej danego podmiotu odbywa się raz na dobę.
              </p>
              <p>
                Bardziej szczegółowe informacje na ten temat znajdziesz w punkcie „4.1. Czym jest kategoria
                wiarygodności płatniczej w ramach analizy wiarygodności płatniczej?” w Samouczku.
              </p>
              <p className={styles.textUnderline}>Pobieranie informacji o limicie kupieckim</p>
              <p>
                Limit kupiecki danego podmiotu jest wyznaczany przez KRD BIG S.A. w postaci określonej kwoty w wyniku
                analizy wiarygodności płatniczej tego podmiotu.
              </p>
              <p>
                Limit kupiecki to maksymalna kwota, wyrażona w PLN, do jakiej (na podstawie analizy statystycznej)
                rekomendowana jest sprzedaż kontrahentowi usług lub towarów z odroczonym terminem płatności.
              </p>
              <p>W ramach usługi limitu kupieckiego dostępne są następujące informacje:</p>
              <ul>
                <li>rekomendowana kwota limitu kupieckiego wyznaczona dla danego kontrahenta,</li>
                <li>data wyznaczenia limitu kupieckiego,</li>
                <li>model, na podstawie którego została wyznaczona kwota limitu.</li>
              </ul>
              <p>
                Bardziej szczegółowe informacje na ten temat znajdziesz w punkcie „4.2. Czym jest limit kupiecki w
                ramach analizy wiarygodności płatniczej?”.
              </p>
            </div>
          );
        }
      },
      {
        name: 'Jak włączyć usługę monitorowania?',
        content: function (): JSX.Element {
          return (
            <div className={styles.tutorialSubSectionWrapper}>
              <p>Monitorowanie klienta możesz włączyć na 3 sposoby:</p>
              <ul>
                <li>
                  w karcie klienta w sekcji „Monitorowanie” – za pomocą suwaka,
                  <div className={styles.tutorialImageWrapper}>
                    <img
                      src={require('../../../assets/images/tutorial/3-2a.png')}
                      alt="Jak włączyć usługę monitorowania?"
                      className={styles.horizontallImg}
                    />
                  </div>
                </li>
                <li>
                  na liście klientów – pojedynczo, za pomocą przycisku „Włącz monitorowanie”,
                  <div className={styles.tutorialImageWrapper}>
                    <img
                      src={require('../../../assets/images/tutorial/3-2b.png')}
                      alt="Jak włączyć usługę monitorowania?"
                      className={styles.horizontallImg}
                    />
                  </div>
                </li>
                <li>
                  na liście klientów – zbiorczo, za pomocą akcji grupowej „Włącz monitorowanie”, po uprzednim
                  zaznaczeniu klientów,
                  <div className={styles.tutorialImageWrapper}>
                    <img
                      src={require('../../../assets/images/tutorial/3-2c.png')}
                      alt="Jak włączyć usługę monitorowania?"
                      className={styles.horizontallImg}
                    />
                  </div>
                </li>
                <li>
                  za pomocą odpowiedniego kodu operacji w ramach importu pliku CSV (więcej informacji na ten temat
                  zawiera punkt „7.6. Wykonywanie działań masowych za pomocą kodów operacji przy imporcie pliku CSV.” w
                  Samouczku).
                </li>
              </ul>
              <p>Uruchomienie monitorowania klienta w RiskRadar powoduje jednoczesne uruchomienie:</p>
              <ul>
                <li>sprawdzania zdarzeń w KRD BIG S.A.,</li>
                <li>pobierania informacji o łącznym zadłużeniu w KRD BIG S.A.,</li>
                <li>pobierania informacji o kategorii wiarygodności płatniczej w KRD BIG S.A.</li>
              </ul>
              <p>
                Uruchomienie monitorowania nie następuje natychmiast, a po jednej pełnej dobie następującej po jego
                włączeniu (doba rozumiana jest jako okres od godziny 00:00 do godziny 23:59, a nie jako okres 24h
                liczony od momentu włączenia do momentu uruchomienia monitorowania). Przykładowo, jeśli włączysz
                monitorowanie w poniedziałek o godzinie 15, to informacje z monitorowania pojawią się w RiskRadar w
                środę około godziny 1 w nocy. W czasie pomiędzy włączeniem a uruchomieniem monitorowania zobaczysz
                komunikat „Trwa włączanie monitorowania. Monitorowanie uruchomi się po 2 dniach (w godzinach rannych) od
                momentu zlecenia jego włączenia.”. Mechanizm ten dotyczy zarówno włączania monitorowania dla
                pojedynczego klienta, jak i włączania monitorowania dla wielu klientów jednocześnie, w ramach akcji
                grupowej.
              </p>
              <p>
                Jeśli próba włączenia monitorowania ma miejsce po godzinie 23:00 to pojawi się komunikat o braku
                możliwości wykonania operacji w tym dniu oraz propozycja włączenia monitorowania w kolejnym dniu. Po
                wyrażeniu zgody, monitorowanie zostanie włączone w kolejnym dniu.
              </p>
              <p>
                Naliczenie usług następuje w momencie włączenia monitorowania (tj. kliknięcia przycisku „Włącz
                monitorowanie”) a nie z chwilą uruchomienia monitorowania (tj. rozpoczęcia wyświetlania informacji w
                RiskRadar). Naliczenie odbywa się w ramach przyznanego limitu (jeśli limit nie został wykorzystany) lub
                w ramach przekroczenia (jeśli limit został już wykorzystany). Więcej informacji na ten temat znajdziesz
                w punkcie „6. Opłaty miesięczne i przekroczenia” w Samouczku.
              </p>
              <p>
                Z uwagi na to, że włączanie monitorowania powoduje naliczanie usług, wykonuj to działanie rozważnie, po
                przemyśleniu, czy rzeczywiście chcesz rozpocząć monitorowanie danego klienta lub klientów. Przykładowo,
                jeśli włączysz monitorowanie jednocześnie dla 50 klientów w ramach akcji grupowej, celowo lub
                przypadkowo, to spowoduje to naliczenie usług.
              </p>
              <p>
                Dodatkowe informacje dotyczące przekraczania limitów dostępnych w ramach opłaty miesięcznej – wraz z
                konkretnymi przykładami – opisane są w punkcie „6.2. Jakie są opłaty za przekroczenie limitów usług w
                ramach opłaty miesięcznej?” w Samouczku.
              </p>
              <p>
                W okresie pomiędzy włączeniem a uruchomieniem monitorowania danego klienta filtr „Monitorowanie klienta”
                w module filtrowania traktuje tego klienta tak jakby miał status monitorowania wyłączony (informacje z
                monitorowania nie są bowiem jeszcze dostępne w RiskRadar).
              </p>
              <p>
                W okresie pomiędzy włączeniem a uruchomieniem monitorowania możesz wykonać operację jego wyłączenia,
                jednak takie działanie nie cofa licznika usług. Innymi słowy, jeśli włączysz monitorowanie np. o 13:20,
                a wyłączysz o 13:25 to usługa zostanie naliczona (w ramach opłaty miesięcznej lub jako przekroczenie
                limitu).
              </p>
              <p>
                Informacje na temat licznika usług znajdziesz w punkcie „6.4. Jak działa licznik usług?” w Samouczku.
              </p>
            </div>
          );
        }
      },
      {
        name: 'Jak wyłączyć usługę monitorowania?',
        content: function (): JSX.Element {
          return (
            <div className={styles.tutorialSubSectionWrapper}>
              <p>Monitorowanie klienta możesz wyłączyć na 2 sposoby:</p>
              <ul>
                <li>
                  w karcie klienta w sekcji „Monitorowanie” – za pomocą suwaka,
                  <div className={styles.tutorialImageWrapper}>
                    <img
                      src={require('../../../assets/images/tutorial/3-2a.png')}
                      alt="Jak wyłączyć usługę monitorowania?"
                      className={styles.horizontallImg}
                    />
                  </div>
                </li>
                <li>
                  na liście klientów – zbiorczo, za pomocą akcji grupowej „Wyłącz monitorowanie”, po uprzednim
                  zaznaczeniu klientów,
                  <div className={styles.tutorialImageWrapper}>
                    <img
                      src={require('../../../assets/images/tutorial/3-2c.png')}
                      alt="Jak wyłączyć usługę monitorowania?"
                      className={styles.horizontallImg}
                    />
                  </div>
                </li>
                <li>
                  za pomocą odpowiedniego kodu operacji w ramach importu pliku CSV (więcej informacji na ten temat
                  zawiera punkt „7.6. Wykonywanie działań masowych za pomocą kodów operacji przy imporcie pliku CSV.” w
                  Samouczku).
                </li>
              </ul>
              <p>
                Wyłączając monitorowanie możesz też wskazać konkretną datę przyszłą (np. za tydzień), po której
                monitorowanie zostanie wyłączone i informacje przestaną być odświeżane.
              </p>
              <p>
                Wyłączenie monitorowania powoduje zaprzestanie wyświetlania w serwisie RiskRadar informacji o
                monitorowanym kliencie. Skutkiem wyłączenia monitorowania jest usunięcie historii monitorowania klienta
                z RiskRadar, w tym usunięcie informacji o zdarzeniach w KRD BIG S.A. (o ile takie miały miejsce),
                informacji o poziomie zadłużenia w KRD BIG S.A., informacji o kategorii wiarygodności płatniczej.
              </p>
              <p>
                Jeśli próba wyłączenia monitorowania ma miejsce po godzinie 23:00 to pojawi się komunikat o braku
                możliwości wykonania operacji w tym dniu oraz propozycja wyłączenia monitorowania w kolejnym dniu. Po
                wyrażeniu zgody, monitorowanie zostanie wyłączone w kolejnym dniu.
              </p>
              <p>
                W czasie pomiędzy wyłączeniem monitorowania danego numeru NIP a końcem doby (tj. 23:59), w której
                nastąpiło wyłączenie monitorowania, prezentowany jest komunikat „Trwa wyłączanie monitorowania.
                Monitorowanie zostanie zatrzymane z końcem doby, w której zlecono jego wyłączenie.”. W tym czasie nie
                jest możliwe ponowne włączenie usługi monitorowania dla tego numeru NIP.
              </p>
              <p>
                Od razu po wyłączeniu monitorowania danego klienta filtr „Monitorowanie klienta” w module filtrowania
                traktuje tego klienta tak jakby miał status monitorowania wyłączony (informacje z monitorowania nie są
                bowiem już dostępne w RiskRadar).
              </p>
              <p>
                W okresie pomiędzy włączeniem a uruchomieniem monitorowania możesz wykonać operację jego wyłączenia,
                jednak takie działanie nie cofa licznika usług. Innymi słowy, jeśli włączysz monitorowanie np. o 13:20,
                a wyłączysz o 13:25 to usługa zostanie naliczona (w ramach opłaty miesięcznej lub jako przekroczenie
                limitu).
              </p>
              <p>
                W przypadku ponownego włączenia monitorowania dla danego klienta dane historyczne (z wcześniej
                włączonego monitorowania) nie są pokazywane.
              </p>
              <p>
                Informacje na temat licznika usług znajdziesz w punkcie „6.4. Jak działa licznik usług?” w Samouczku.
              </p>
            </div>
          );
        }
      },
      {
        name: 'Jakie skutki dla Twojego klienta powoduje włączenie jego monitorowania w RiskRadar?',
        content: function (): JSX.Element {
          return (
            <div className={styles.tutorialSubSectionWrapper}>
              <p>
                Skutkiem włączenia przez Ciebie monitorowania Twojego klienta w RiskRadar jest odnotowanie zapytania w
                Rejestrze Zapytań KRD BIG S.A. (zgodnie z ustawą). Każdy podmiot, który na swój wniosek sprawdzi Rejestr
                Zapytań, uzyska wiedzę o tym, kto i kiedy go sprawdzał oraz jaką informację uzyskał. Jeśli Twój klient
                posiada płatną usługę automonitorowania w KRD BIG S.A. to otrzyma automatyczne powiadomienie o wykonanym
                przez Ciebie odpytaniu o jego dane w KRD BIG S.A.
              </p>
            </div>
          );
        }
      }
    ]
  },
  {
    name: 'Analiza wiarygodności płatniczej',
    subsections: [
      {
        name: 'Czym jest kategoria wiarygodności płatniczej w ramach analizy wiarygodności płatniczej?',
        content: function (): JSX.Element {
          return (
            <div className={styles.tutorialSubSectionWrapper}>
              <p>
                Wyznaczanie kategorii wiarygodności płatniczej (tzw. scoringu) w ramach analizy wiarygodności płatniczej
                jest przeprowadzana przez KRD BIG S.A. i realizowane w ramach usługi monitorowania klienta w RiskRadar.
                Polega na analizie podmiotu pod kątem jego wiarygodności płatniczej, a następnie nadaniu mu jednej z
                następujących kategorii wiarygodności płatniczej:
              </p>
              <ul>
                <li>A – Najwyższa ocena,</li>
                <li>B – Bardzo wysoka ocena,</li>
                <li>C – Wysoka ocena,</li>
                <li>D – Średnia ocena,</li>
                <li>E – Niska ocena,</li>
                <li>F – Bardzo niska ocena,</li>
                <li>G – Najniższa ocena,</li>
                <li>H – Istotne zadłużenie w systemie KRD BIG S.A.</li>
              </ul>
              <p>
                W przypadku podmiotu z przychodem rocznym do 100 milionów złotych (zgodnie z ostatnim dostępnym
                sprawozdaniem finansowym) lub brakiem tej informacji, dana kategoria (od A do G) określa poziom
                prawdopodobieństwa wpisania podmiotu do systemu KRD BIG S.A. na kwotę wyższą niż 2000 złotych. W
                przypadku podmiotu z przychodem rocznym co najmniej 100 milionów złotych dana kategoria (od A do G)
                określa poziom prawdopodobieństwa wpisania podmiotu do systemu KRD BIG S.A. na kwotę wyższą niż 0,05%
                przychodu.
              </p>
              <p>
                Gdy zwiększa się ryzyko dopisania podmiotu do systemu KRD BIG S.A., to jednocześnie obniża się jego
                wiarygodność płatnicza. Kategorię A posiadają podmioty, dla których ryzyko wynosi około 0,5%, natomiast
                dla podmiotów z kategorii G ryzyko wynosi ponad 32%.
              </p>
              <p>
                Kategoria H oznacza, iż dany podmiot widnieje w systemie KRD BIG S.A. na kwotę wyższą niż 2.000 złotych
                w przypadku, gdy jego przychód roczny wynosi do 100 mln złotych (zgodnie z ostatnim dostępnym
                sprawozdaniem finansowym) lub nie dysponujemy informacją o przychodzie. Dla podmiotów, których przychód
                roczny wynosi co najmniej 100 mln złotych kategoria H przypisywana jest w sytuacji, gdy kwota dopisana
                do systemu KRD BIG S.A. przekracza 0,05% jego przychodu rocznego.
              </p>
              <p>
                Na losowej próbie ponad 1 mln NIP ryzyko dopisania do systemu KRD BIG S.A. dla poszczególnych grup
                kształtowało się następująco (według analiz KRD BIG S.A.):
              </p>
              <ul>
                <li>A – 0,5%</li>
                <li>B – 1,4%</li>
                <li>C – 2,6%</li>
                <li>D – 4,3%</li>
                <li>E – 6,5%</li>
                <li>F – 11,4%</li>
                <li>G – 32,1%</li>
              </ul>
              <p>
                W niektórych przypadkach wyznaczanie kategorii wiarygodności płatniczej nie jest wykonywane. Zwracany
                jest wówczas powód braku wyznaczenia kategorii. Wyróżniamy następujące przyczyny:
              </p>
              <ul>
                <li>
                  brak wystarczających danych – KRD BIG S.A. nie dysponuje wystarczającymi informacjami niezbędnymi do
                  przeprowadzenia analizy (sytuacja taka dotyczy około 2% podmiotów aktywnych),
                </li>
                <li>wykreślony z rejestru (źródło: CEIDG) – podmiot ma statusu w rejestrze CEIDG: „wykreślony”,</li>
                <li>wykreślony z rejestru (źródło: KRS) – podmiot ma status w rejestrze KRS: „wykreślona”,</li>
                <li>zakończył działalność (źródło: REGON) – podmiot zakończył już działalność wg REGON,</li>
                <li>
                  w likwidacji lub upadłości (źródło: KRS) – podmiot ma jeden ze statusów w rejestrze KRS: „w
                  likwidacji”, „w upadłości likwidacyjnej”,
                </li>
                <li>błędny NIP (nie spełnia sumy kontrolnej dla walidacji polskiego numeru NIP).</li>
              </ul>
              <p>
                Do wyznaczenia kategorii wiarygodności płatniczej wykorzystywane są unikalne zasoby bazodanowe KRD BIG
                S.A. oraz rejestry publiczne CEIDG, KRS, REGON. Wiarygodność płatnicza wyznaczana jest w oparciu o
                metody uczenia maszynowego. Wynik analizy cechuje się wysokimi współczynnikami jakości, co zostało
                sprawdzone na puli około 1 mln pomiotów wybranych losowo (według analiz KRD BIG S.A.).
              </p>
              <p>Wyznaczanie kategorii wiarygodności płatniczej przeprowadzana jest w następujący sposób:</p>
              <ol>
                <li>Pobranie/aktualizacja danych z rejestrów publicznych CEIDG, KRS, REGON.</li>
                <li>Oczyszczenie i standaryzacja pobranych danych.</li>
                <li>
                  W oparciu o informacje z rejestrów publicznych oraz zasoby bazodanowe KRD BIG S.A. utworzenie
                  charakterystyk mających wpływ na wiarygodność płatniczą.
                </li>
                <li>
                  Przy pomocy algorytmu uczenia maszynowego wyznaczenie prawdopodobieństwa dopisania podmiotu do systemu
                  KRD BIG S.A.{' '}
                </li>
                <li>
                  Przypisanie kategorii wiarygodności płatniczej (uzależnione od ryzyka wyznaczonego w punkcie 4).
                </li>
              </ol>
              <p>
                Analiza wiarygodności płatniczej dłużnika niebędącego konsumentem przeprowadzana jest na podstawie
                artykułu 31a ustawy o udostępnianiu informacji gospodarczych i wymianie danych gospodarczych. Wynik
                analizy jest udostępniany bez konieczności pozyskiwania zgody od analizowanego podmiotu. Analizie
                podlegają podmioty niebędące konsumentami np. przedsiębiorstwa mikro, średnie i duże instytucje. Analiza
                wiarygodności płatniczej jest dokonywana dla polskich podmiotów (tj. posiadających numer NIP), nie jest
                dokonywana dla podmiotów zagranicznych.
              </p>
              <p>
                Co do zasady, bezpieczniej jest współpracować z podmiotami posiadającymi wyższą kategorię wiarygodności
                płatniczej (na przykład kategorię „A”), a zachować szczególną ostrożność współpracując z podmiotami
                posiadającymi niższą kategorię wiarygodności płatniczej (na przykład kategorię „H”).
              </p>
              <p>
                Analiza wiarygodności płatniczej bazuje na analizie statystycznej, co oznacza, że w grupie podmiotów z
                wyższą kategorią mogą sporadycznie trafić się podmioty niewiarygodne, i na odwrót – w grupie podmiotów z
                niższą kategorią mogą sporadycznie trafić się podmioty wiarygodne. Z tego względu wynik analizy
                wiarygodności płatniczej należy traktować raczej jako pewną wskazówkę w procesie decyzyjnym, a nie
                gwarancję wiarygodności podmiotu (lub jej braku).
              </p>
            </div>
          );
        }
      },
      {
        name: 'Czym jest limit kupiecki w ramach analizy wiarygodności płatniczej?',
        content: function (): JSX.Element {
          return (
            <div className={styles.tutorialSubSectionWrapper}>
              <p>
                Usługa limitu kupieckiego polega na wyznaczeniu maksymalnej kwoty, wyrażonej w PLN, do jakiej - na
                podstawie analizy statystycznej - rekomendowana jest sprzedaż kontrahentowi usług lub towarów z
                odroczonym terminem płatności.
              </p>
              <p>
                Limit ten może być ustalany jako kwota maksymalna na jednorazowe zamówienie lub wszystkie zamówienia w
                określonym czasie, obejmującym na przykład miesiąc lub rok. Obliczenia opierają się na założeniu, że
                oceniany podmiot ma 5 dostawców, którzy dostarczają usługi lub towary w podobnej kwocie i w tym samym
                czasie ze średnim terminem płatności wynoszącym 60 dni.
              </p>
              <p>W ramach usługi limitu kupieckiego prezentowane są następujące informacje:</p>
              <ul>
                <li>rekomendowana kwota limitu kupieckiego wyznaczona dla danego kontrahenta,</li>
                <li>data wyznaczenia limitu kupieckiego,</li>
                <li>model, na podstawie którego została wyznaczona kwota limitu.</li>
              </ul>
              <p>
                Kwota limitu kupieckiego stanowi rekomendację i nie należy jej utożsamiać z decyzją kredytową. Decyzja
                ta zawsze winna być podjęta przez dostawcę towarów i usług. Wynik kalkulacji limitu kupieckiego należy
                traktować raczej jako pewną wskazówkę w procesie decyzyjnym, a nie gwarancję zdolności do spłaty
                zobowiązań na kwotę wskazaną w rekomendowanej wysokości limitu kupieckiego.
              </p>
              <p>
                Wyznaczenie limitu kupieckiego jako elementu analizy wiarygodności płatniczej podmiotu niebędącego
                konsumentem jest wykonywane przez KRD BIG S.A. na podstawie artykułu 31a ustawy z dnia 9 kwietnia 2010
                r. o udostępnianiu informacji gospodarczych i wymianie danych gospodarczych. Wyznaczenie limitu
                kupieckiego i udostępnienie jego wyniku nie wymaga zgody ocenianego podmiotu.
              </p>
              <p>
                Wyznaczanie limitu kupieckiego może być wykonane wyłącznie dla podmiotów identyfikowanych na podstawie
                NIP będących przedsiębiorcami (przedsiębiorstwa mikro, średnie i duże), wpisanymi do rejestrów
                publicznych (KRS, CEIDG) oraz dla spółek cywilnych. Jeżeli podmiot nie spełnia wszystkich powyższych
                warunków (np. rolnik nieprowadzący działalności gospodarczej), to limit kupiecki nie zostanie
                wyznaczony. Wyznaczenie limitu kupieckiego nie jest wykonywane dla konsumentów.
              </p>
              <p>Limit kupiecki oraz status jego dostępności może być wyznaczony w oparciu o jeden z dwóch modeli:</p>
              <ul>
                <li>
                  Model 1 - limit wyznaczony na podstawie danych umożliwiających ocenę skali działalności i ryzyka
                  niewypłacalności podmiotu, z uwzględnieniem danych ze sprawozdań finansowych (M1),
                </li>
                <li>
                  Model 2 - limit wyznaczony na podstawie danych umożliwiających ocenę skali działalności i ryzyka
                  niewypłacalności podmiotu (M2).
                </li>
              </ul>
              <p>Sposób wyznaczenia wartości limitu dla powyższych modeli jest następujący:</p>
              <ol>
                <li>
                  Pobranie/aktualizacja danych na temat analizowanego podmiotu niebędącego konsumentem z rejestrów
                  publicznych CEIDG, KRS, REGON.
                </li>
                <li>
                  Pobranie/aktualizacja danych pochodzących ze sprawozdań finansowych w KRS, o ile zostały złożone.
                </li>
                <li>Oczyszczenie i standaryzacja pobranych danych.</li>
                <li>
                  Utworzenie:
                  <ol className={styles.tableOrderedList}>
                    <li>
                      <span className={styles.counter}>a)</span> w przypadku Modelu 1 – wskaźników finansowych
                      obrazujących poziom zadłużenia bilansowego, efektywności gospodarowania (rotacji należności i
                      zobowiązań), płynności, rentowności oraz stabilności finansowej.
                    </li>
                    <li>
                      <span className={styles.counter}>b)</span> w przypadku Modelu 2 – charakterystyk mających wpływ na
                      skalę działalności podmiotu oraz wiarygodność płatniczą.
                    </li>
                  </ol>
                </li>
                <li>
                  Przypisanie analizowanemu podmiotowi rekomendowanej wysokości limitu kupieckiego lub wskazanie
                  przyczyny braku jego wyznaczenia.
                </li>
                <li>
                  Podmioty o wysokim poziomie kategorii wiarygodności płatniczej (od A do C), u których istnieją, po
                  ocenie w Modelu 1, wątpliwości co do dobrej kondycji finansowej lub podmioty, których sprawozdania
                  finansowe nie są dostępne lub są starsze niż 3 lata, mają wyznaczony limit kupiecki w oparciu o Model
                  2.
                </li>
              </ol>
              <p>
                Wyznaczanie limitu kupieckiego bazuje na analizie statystycznej, co oznacza, że (zwłaszcza w przypadku
                wyznaczenia limitu kupieckiego w Modelu 2) mogą trafić się podmioty, dla których kwota limitu jest
                niższa od rzeczywistej zdolności podmiotu do spłaty zobowiązań. Zdecydowanie rzadziej, ze względu na
                przyjęte podejście ostrożnościowe, zdarzyć się mogą przypadki odwrotne, tzn. kwota wyznaczonego limitu
                kupieckiego może być wyższa od rzeczywistej zdolności podmiotu.
              </p>
              <p>Przykładowe dane brane pod uwagę przy wyznaczeniu limitu kupieckiego w Modelu 1:</p>
              <ul>
                <li>kategoria wiarygodności płatniczej,</li>
                <li>skala działalności mierzona poziomem średniomiesięcznych obrotów z działalności podstawowej,</li>
                <li>poziom osiąganych zysków (zysk netto oraz EBITDA),</li>
                <li>stan bilansowy środków pieniężnych oraz aktywów płynnych (należności krótkoterminowe, zapasy),</li>
                <li>poziom bilansowy zobowiązań długo- i krótkoterminowych,</li>
                <li>okres rotacji należności i zobowiązań handlowych,</li>
                <li>poziom kapitału obrotowego,</li>
                <li>wskaźnik płynności szybkiej oraz bieżącej,</li>
                <li>
                  wskaźnik zadłużenia, tj. relacji zobowiązań bilansowych do zysku netto i EBITDA oraz relacji
                  zobowiązań do posiadanych kapitałów własnych.
                </li>
              </ul>
              <p>Przykładowe dane brane pod uwagę przy wyznaczaniu limitu kupieckiego w Modelu 2:</p>
              <ul>
                <li>kategoria wiarygodności płatniczej,</li>
                <li>
                  czas prowadzenia działalności, mierzony od daty rejestracji podmiotu, z wyłączeniem okresów
                  zawieszenia działalności,
                </li>
                <li>forma prawna,</li>
                <li>główne PKD.</li>
              </ul>
              <p>Model 1 i Model 2 są stosowane dla następujących przypadków:</p>
              <ol>
                <li>
                  W Modelu 1 wyznaczana jest rekomendowana wysokość limitu kupieckiego (jednak nie wyższa niż 3.000.000
                  PLN, a limit nie przekroczy 120% średniomiesięcznych obrotów analizowanego podmiotu za ostatni
                  zamknięty rok obrachunkowy) w odniesieniu do podmiotu, który na moment jego wyznaczania posiada
                  opublikowane w KRS aktualne, tzn. nie starsze niż 3 lata (licząc od dnia zamknięcia roku
                  obrachunkowego), sprawozdanie finansowe, z zastrzeżeniem przypadków określonych w punktach 2b i 2c
                  poniżej.
                </li>
                <li>
                  W Modelu 2 wyznaczana jest rekomendowana wysokość limitu kupieckiego (nie wyższa jednak niż 50.000
                  PLN) w odniesieniu do podmiotu, który na moment jego wyznaczania spełnia co najmniej jedno z
                  poniższych:
                  <ol className={styles.tableOrderedList}>
                    <li>
                      <span className={styles.counter}>a)</span> nie posiada opublikowanego w KRS sprawozdania
                      finansowego,
                    </li>
                    <li>
                      <span className={styles.counter}>b)</span> posiada opublikowane w KRS nie starsze niż 3 lata
                      (licząc od dnia zamknięcia roku obrachunkowego) sprawozdanie finansowe, jednak w oparciu o Model 1
                      rekomendowany limit kupiecki wynosiłby zero, a jednocześnie kategoria wiarygodności płatniczej
                      takiego podmiotu wynosi od A do C,
                    </li>
                    <li>
                      <span className={styles.counter}>c)</span> posiada opublikowane w KRS sprawozdanie finansowe
                      starsze niż 3 lata (licząc od dnia zamknięcia roku obrachunkowego), a jednocześnie kategoria
                      wiarygodności płatniczej takiego podmiotu wynosi od A do E,
                    </li>
                    <li>
                      <span className={styles.counter}>d)</span> prowadzi jednoosobową działalność gospodarczą
                      potwierdzoną wpisem w CEIDG,
                    </li>
                    <li>
                      <span className={styles.counter}>e)</span> nie są spełnione warunki dla wyznaczenia limitu w
                      Modelu 1.
                    </li>
                  </ol>
                </li>
              </ol>
              <p>
                Wskazane powyżej kwoty (tj. 3.000.000 PLN dla Modelu 1 oraz 50.000 PLN dla Modelu 2) stanowią maksymalne
                wartości przyjęte w Modelach 1 i 2 na potrzeby wyznaczenia limitu. Oznacza to, że niezależnie od
                okoliczności nasz model ograniczy się do ustalonej, ściśle określonej kwoty jako górnej granicy
                dostępnego limitu, nawet jeśli w szczególnych przypadkach możliwym byłoby wyznaczenie wyższego Limitu
                Kupieckiego.
              </p>
              <p>Limit kupiecki nie jest wyznaczony w przypadku, gdy zachodzi jedna z poniższych sytuacji:</p>
              <ol className={styles.tableOrderedList}>
                <li>
                  <span className={styles.counter}>a)</span> Błędny NIP - podany NIP nie istnieje (nie spełnia sumy
                  kontrolnej dla walidacji polskiego numeru NIP).
                </li>
                <li>
                  <span className={styles.counter}>b)</span> Brak wystarczających danych - brak danych niezbędnych do
                  wyznaczenia limitu kupieckiego (KRD BIG S.A. nie dysponuje wystarczającymi informacjami niezbędnymi do
                  przeprowadzenia analizy, w szczególności danymi dot. formy prawnej, kodu pocztowego, głównego PKD lub
                  niezgodnych sum bilansowych).
                </li>
                <li>
                  <span className={styles.counter}>c)</span> Wykreślony z rejestru (źródło: CEIDG) - podmiot posiada
                  status w CEIDG: wykreślony.
                </li>
                <li>
                  <span className={styles.counter}>d)</span> Wykreślony z rejestru (źródło: KRS) - podmiot posiada
                  status w KRS: wykreślona.
                </li>
                <li>
                  <span className={styles.counter}>e)</span> Podmiot nie jest zarejestrowany w CEDIG lub KRS - podmiot,
                  który nie jest zarejestrowany w KRS lub CEIDG (wykluczenie nie dotyczy spółek cywilnych).
                </li>
                <li>
                  <span className={styles.counter}>f)</span> W likwidacji (źródło: KRS) – podmiot posiada jeden ze
                  statusów w KRS: w likwidacji, w upadłości likwidacyjnej.
                </li>
                <li>
                  <span className={styles.counter}>g)</span> W restrukturyzacji (źródło: KRS) - podmiot posiada status w
                  KRS: w restrukturyzacji.
                </li>
                <li>
                  <span className={styles.counter}>h)</span> Działalność zawieszona (źródło: KRS) – podmiot posiada
                  status w KRS: zawieszony.
                </li>
                <li>
                  <span className={styles.counter}>i)</span> Działalność zawieszona (źródło: CEIDG) – podmiot posiada
                  status w CEIDG: zawieszony.
                </li>
                <li>
                  <span className={styles.counter}>j)</span> Działalność zakończona (źródło: REGON) – spółka cywilna
                  zakończyła działalność w REGON.
                </li>
              </ol>
              <p>Dodatkowe informacje dostępne są na stronie:</p>
              <a
                href="https://kaczmarskigroup.pl/limit-kupiecki-w-ramach-analizy-wiarygodnosci-platniczej"
                rel="noopener noreferrer"
                target="_blank">
                https://kaczmarskigroup.pl/limit-kupiecki-w-ramach-analizy-wiarygodnosci-platniczej
              </a>
            </div>
          );
        }
      },
      {
        name: 'Jak włączyć pobieranie informacji o kategorii wiarygodności płatniczej i limicie kupieckim?',
        content: function (): JSX.Element {
          return (
            <div className={styles.tutorialSubSectionWrapper}>
              <p>
                Kategoria wiarygodności płatniczej oraz limit kupiecki są wyznaczane w ramach analizy wiarygodności
                płatniczej. Analiza ta jest jednym z trzech powiązanych ze sobą elementów usługi monitorowania w
                RiskRadar. Wyłączenie pobierania informacji o kategorii wiarygodności płatniczej oraz limicie kupieckim
                wymaga więc wyłączenia usługi monitorowania w RiskRadar.
              </p>
              <p>
                Jeśli Twoja firma posiada dostęp do RiskRadar w wersji bez usługi limitu kupieckiego, informacje o
                limicie kupieckim nie będą prezentowane. Aktywacja tej usługi wymaga zmiany umowy – w tym celu
                skontaktuj się z opiekunem Twojej firmy w KG.
              </p>
            </div>
          );
        }
      },
      {
        name: 'Jak wyłączyć pobieranie informacji o kategorii wiarygodności płatniczej i limicie kupieckim?',
        content: function (): JSX.Element {
          return (
            <div className={styles.tutorialSubSectionWrapper}>
              <p>
                Kategoria wiarygodności płatniczej oraz limit kupiecki są wyznaczane w ramach analizy wiarygodności
                płatniczej. Analiza ta jest jednym z trzech powiązanych ze sobą elementów usługi monitorowania w
                RiskRadar. Wyłączenie pobierania informacji o kategorii wiarygodności płatniczej oraz limicie kupieckim
                wymaga więc wyłączenia usługi monitorowania w RiskRadar.
              </p>
            </div>
          );
        }
      },
      {
        name: 'Czy i w jakich obszarach podmiot ma wpływ na wynik analizy wiarygodności płatniczej w postaci kategorii wiarygodności płatniczej?',
        content: function (): JSX.Element {
          return (
            <div className={styles.tutorialSubSectionWrapper}>
              <p>
                Przy wykonywaniu przez KRD BIG S.A. analizy wiarygodności płatniczej w postaci kategorii wiarygodności
                płatniczej podmiotu niebędącego konsumentem branych pod uwagę jest kilkaset informacji o podmiocie
                gospodarczym i jego otoczeniu z następujących źródeł:
              </p>
              <ul>
                <li>CEIDG,</li>
                <li>REGON,</li>
                <li>KRS,</li>
                <li>KRD BIG S.A.</li>
              </ul>
              <p>
                Tylko w przypadku oceny „H” istnieje jednoznaczna odpowiedź na to, co należy zrobić, by zmienić ocenę na
                inną (wyższą); jest nią usunięcie zobowiązań figurujących w KRD BIG S.A., np. na skutek spłaty. W
                przypadku kategorii od A do G wpływ na daną ocenę ma najczęściej wiele czynników, również tych, na które
                sam podmiot nie ma wpływu lub jest on ograniczony.
              </p>
              <p>Przykładowe informacje, na które podmiot oceniany ma bezpośredni wpływ:</p>
              <table className={styles.table}>
                <tr>
                  <th className={styles.th}>Cecha / Kategoria</th>
                  <th className={styles.th}>Co ma wpływ na ocenę?</th>
                  <th className={styles.th}>Kiedy ocena może się podwyższyć?</th>
                </tr>
                <tr>
                  <td className={styles.td}>Informacje dotyczące kapitału</td>
                  <td className={styles.td}>Wielkość kapitału</td>
                  <td className={styles.td}>Jeżeli zwiększy się kapitał podmiotu (dotyczy spółek prawa handlowego)</td>
                </tr>
                <tr>
                  <td className={styles.td}>Informacje dotyczące przychodu rocznego</td>
                  <td className={styles.td}>Wielkość przychodu rocznego</td>
                  <td className={styles.td}>
                    Jeżeli podmiot zwiększy przychód roczny oraz dostarczy terminowo swoje sprawozdanie finansowe do
                    Repozytorium Dokumentów Finansowych eKRS.
                  </td>
                </tr>
                <tr>
                  <td className={styles.td}>Informacje dotyczące rentowności</td>
                  <td className={styles.td}>
                    Rentowność na podstawie sprawozdania finansowego (rachunek zysków i strat)
                  </td>
                  <td className={styles.td}>
                    Jeżeli podmiot zwiększy rentowność oraz dostarczy terminowo swoje sprawozdanie finansowe do
                    Repozytorium Dokumentów Finansowych eKRS.
                  </td>
                </tr>
                <tr>
                  <td className={styles.td}>
                    Widoczność podmiotu na rynku, rozumiana jako zakres wypełnienia danych kontaktowych w wymienionych
                    powyżej rejestrach publicznych
                  </td>
                  <td className={styles.td}>Rozpoznawalność na rynku.</td>
                  <td className={styles.td}>
                    Jeżeli podmiot zwiększy swoją widoczność w Internecie, np. założy stronę internetową jeżeli jej
                    dotąd nie posiadał, udostępni dane kontaktowe dla potencjalnych klientów w rejestrach publicznych.
                  </td>
                </tr>
                <tr>
                  <td className={styles.td}>Aktualne informacje gospodarcze</td>
                  <td className={styles.td}>Brak negatywnych wpisów w KRD BIG S.A.</td>
                  <td className={styles.td}>
                    Jeżeli podmiot: reguluje swoje zobowiązania w terminie, przez co nie trafia do KRD BIG S.A., nie
                    podejmuje ryzykownej współpracy z mało wiarygodnymi kontrahentami (weryfikując ich np. w KRD BIG
                    S.A.).
                  </td>
                </tr>
              </table>
              <p>Przykładowe informacje, na które podmiot oceniany nie ma wpływu lub jest on ograniczony:</p>
              <table className={styles.table}>
                <tr>
                  <th className={styles.th}>Cecha / Kategoria</th>
                  <th className={styles.th}>Co ma wpływ na ocenę?</th>
                  <th className={styles.th}>Kiedy ocena może się podwyższyć?</th>
                </tr>
                <tr>
                  <td className={styles.td}>Informacje dotyczące działalności podmiotu na rynku</td>
                  <td className={styles.td}>Czas działania na rynku</td>
                  <td className={styles.td}>Jeżeli podmiot dłużej będzie działał na rynku.</td>
                </tr>
                <tr>
                  <td className={styles.td}>Informacje dotyczące branży podmiotu</td>
                  <td className={styles.td}>
                    Częstotliwość występowania problemów płatniczych w branży podmiotu, szczególnie w jego regionie
                  </td>
                  <td className={styles.td}>
                    Jeżeli sytuacja w branży podmiotu ulegnie poprawie, tj. rzadziej występować w niej będą problemy
                    płatnicze (w szczególności w jego regionie).
                  </td>
                </tr>
                <tr>
                  <td className={styles.td}>Informacje dotyczące regionu, w którym działa podmiot</td>
                  <td className={styles.td}>
                    Częstotliwość występowania problemów płatniczych w regionie, w którym działa podmiot
                  </td>
                  <td className={styles.td}>
                    Jeżeli sytuacja w regionie podmiotu ulegnie poprawie, tj. rzadziej występować w nim będą problemy
                    płatnicze (w szczególności w jego branży).
                  </td>
                </tr>
                <tr>
                  <td className={styles.td}>Informacje dotyczące konkurencji podmiotu</td>
                  <td className={styles.td}>Liczba podmiotów konkurencyjnych</td>
                  <td className={styles.td}>Jeżeli w regionie podmiotu dla jego branży zmniejszy się konkurencja.</td>
                </tr>
                <tr>
                  <td className={styles.td}>
                    Informacje dotyczące zobowiązań znajdujących się w KRD BIG S.A. w danej branży i regionie
                  </td>
                  <td className={styles.td}>
                    Informacje dotyczące długości okresu, w jakim zobowiązania w danym segmencie historycznie znajdowały
                    się w KRD BIG S.A.
                  </td>
                  <td className={styles.td}>
                    Jeżeli sytuacja w branży podmiotu ulegnie poprawie, tj. problemy płatnicze będą występowały u
                    podmiotów krócej/będą szybciej spłacane (w szczególności w jego regionie).
                  </td>
                </tr>
              </table>
            </div>
          );
        }
      },
      {
        name: 'Jak interpretować poszczególne kategorie wiarygodności płatniczej i przenieść je na działania w swoim biznesie?',
        content: function (): JSX.Element {
          return (
            <div className={styles.tutorialSubSectionWrapper}>
              <p>
                Ze względu na to, że kategoria wiarygodności płatniczej w ramach analizy wiarygodności płatniczej
                określa poziom prawdopodobieństwa (ryzyka) wpisania podmiotu do rejestru dłużników KRD BIG S.A. - a nie
                zapewnienie, że tak się wydarzy - Twoja organizacja powinna wypracować własny sposób interpretacji
                wyniku, a przede wszystkim działań, które należy podjąć, w zależności od otrzymanej przed rozpoczęciem
                lub w trakcie współpracy oceny podmiotu. Sposób ten powinien być dostosowany do rodzaju działalności i
                aktualnej sytuacji Twojej organizacji. Może być to jedno podejście dla wszystkich kontrahentów, ale też
                może być zróżnicowane w zależności od ich rodzaju czy też wielkości transakcji. Poniżej znajdziesz
                <span className={styles.bold}> przykładowe działania</span>, jakie można podjąć w poszczególnych
                kategoriach wiarygodności płatniczej. Pamiętaj jednak, że każdorazowo musisz samodzielnie ocenić i
                podjąć decyzję w oparciu o szereg zróżnicowanych informacji, a wykonana przez KRD BIG S.A. analiza
                stanowi jedynie wsparcie w podjęciu tej decyzji przez Twoją organizację.
              </p>
              <p className={styles.textUnderline}>Przykładowe działania dotyczące nowego kontrahenta</p>
              <table className={styles.table}>
                <tr>
                  <th className={styles.scoringTh}>Kategoria</th>
                  <th className={styles.scoringTh}>Status</th>
                  <th className={styles.scoringTh}>
                    Propozycja przykładowych działań dodatkowych do ograniczenia ryzyka
                  </th>
                </tr>
                <tr>
                  <td className={styles.td}>A</td>
                  <td className={styles.td}>Kontrahent z grupy najmniej ryzykownych do współpracy</td>
                  <td className={styles.td}>
                    Brak działań - współpraca z tym kontrahentem jest obecnie najmniej ryzykowna, jak i z dużym
                    prawdopodobieństwem będzie on nadal wiarygodnym partnerem w najbliższej przyszłości.{' '}
                  </td>
                </tr>
                <tr>
                  <td className={styles.td}>B, C</td>
                  <td className={styles.td}>Kontrahent z grupy niskiego ryzyka do współpracy</td>
                  <td className={styles.td}>
                    Brak działań, jednak jeśli to nie jest jednorazowa współpraca zalecamy stałe i cykliczne
                    weryfikowanie oceny, np. raz na miesiąc.
                  </td>
                </tr>
                <tr>
                  <td className={styles.td}>D, E</td>
                  <td className={styles.td}>Kontrahent z grupy średniego ryzyka - zachowanie ostrożności</td>
                  <td className={styles.td}>
                    Sprawdź w Raporcie o kliencie:
                    <ul>
                      <li>
                        wynik finansowy z ostatniego dostępnego sprawozdania finansowego (jeśli kontrahent nie jest
                        jednoosobową działalnością gospodarczą); wątpliwości może budzić brak dostępnego w KRS
                        sprawozdania finansowego za ostatni okres rozliczeniowy,
                      </li>
                      <li>sprawdź, czy kontrahent posiada informacje pozytywne.</li>
                    </ul>
                  </td>
                </tr>
                <tr>
                  <td className={styles.td}>F, G</td>
                  <td className={styles.td}>Kontrahent z grupy najwyższego ryzyka - zalecana pogłębiona analiza</td>
                  <td className={styles.td}>
                    <ul>
                      <li>
                        Unikaj transakcji z odroczonym terminem płatności - gdy kontrahent nie będzie w stanie zapłacić
                        za usługę od razu, poleć mu rozłożenie{' '}
                        <a
                          className={styles.blueLink}
                          href="https://fakturatka.pl/sprzedawaj-na-raty"
                          rel="noopener noreferrer"
                          target="_blank">
                          płatności na raty
                        </a>
                        .
                      </li>
                      <li>
                        Sprawdź w Raporcie o kliencie:
                        <ol className={styles.tableOrderedList}>
                          <li>
                            <span className={styles.counter}>1)</span> sytuację finansową kontrahenta w KRS,
                          </li>
                          <li>
                            <span className={styles.counter}>2)</span> jeśli kontrahent nie jest jednoosobową
                            działalnością gospodarczą, pobierz wynik finansowy z ostatniego dostępnego sprawozdania
                            finansowego i porównaj go do poprzednich lat; wątpliwości może budzić brak dostępnego w KRS
                            sprawozdania finansowego za ostatni okres rozliczeniowy,
                          </li>
                          <li>
                            <span className={styles.counter}>3)</span> zobacz powiązania kontrahenta – możliwe, że
                            rozpoznasz wśród nich swoich stałych Klientów, którzy będą w stanie poświadczyć jego
                            wiarygodność.
                          </li>
                        </ol>
                      </li>
                      <li>Jeśli to nie jednorazowa transakcja, stale monitoruj kontrahenta w RiskRadar.</li>
                    </ul>
                  </td>
                </tr>
                <tr>
                  <td className={styles.td}>H </td>
                  <td className={styles.td}>Kontrahent zadłużony w KRD BIG S.A. </td>
                  <td className={styles.td}>
                    <ul>
                      <li>
                        Unikaj transakcji z odroczonym terminem płatności - gdy kontrahent nie będzie w stanie zapłacić
                        za usługę od razu, poleć mu rozłożenie{' '}
                        <a
                          className={styles.blueLink}
                          href="https://fakturatka.pl/sprzedawaj-na-raty"
                          rel="noopener noreferrer"
                          target="_blank">
                          płatności na raty
                        </a>
                        .
                      </li>
                      <li>Jeśli to nie jednorazowa transakcja, stale monitoruj kontrahenta w RiskRadar.</li>
                    </ul>{' '}
                  </td>
                </tr>
              </table>
              <p className={styles.textUnderline}>Przykładowe działania dotyczące stałego kontrahenta </p>
              <table className={styles.table}>
                <tr>
                  <th className={styles.scoringTh}>Kategoria</th>
                  <th className={styles.scoringTh}>Status</th>
                  <th className={styles.scoringTh}>
                    Propozycja przykładowych działań dodatkowych do ograniczenia ryzyka
                  </th>
                </tr>
                <tr>
                  <td className={styles.td}>A</td>
                  <td className={styles.td}>Kontrahent z grupy najmniej ryzykownych do kontynuacji współpracy</td>
                  <td className={styles.td}>
                    <ul>
                      <li>
                        Brak natychmiastowych dodatkowych działań (współpraca z tym kontrahentem jest obecnie najmniej
                        ryzykowna, jak i z dużym prawdopodobieństwem będzie on nadal wiarygodnym partnerem w najbliższej
                        przyszłości).
                      </li>
                    </ul>
                  </td>
                </tr>
                <tr>
                  <td className={styles.td}>B, C</td>
                  <td className={styles.td}>Kontrahent z grupy niskiego ryzyka do kontynuacji współpracy</td>
                  <td className={styles.td}>
                    <ul>
                      <li>
                        Brak natychmiastowych dodatkowych działań (współpraca z tym kontrahentem jest obecnie mało
                        ryzykowna, jak i z dużym prawdopodobieństwem będzie on nadal wiarygodnym partnerem w najbliższej
                        przyszłości).
                      </li>
                      <li>
                        Ze względu na to, że ocena podmiotu może się zmienić w czasie - śledzenie jego sytuacji poprzez
                        stałe i cykliczne weryfikowanie oceny np. raz na miesiąc oraz stałe monitorowanie kontrahenta w
                        RiskRadar.
                      </li>
                    </ul>
                  </td>
                </tr>
                <tr>
                  <td className={styles.td}>D, E</td>
                  <td className={styles.td}>Kontrahent z grupy średniego ryzyka - zachowanie ostrożności</td>
                  <td className={styles.td}>
                    <ul>
                      <li>Zwróć uwagę czy Twoje dotychczasowe transakcje z kontrahentem przebiegały bez opóźnień.</li>
                      <li>
                        Ze względu na to, że sytuacja może się zmienić w czasie, stale monitoruj kontrahenta w
                        RiskRadar.
                      </li>
                      <li>
                        Jeśli kontrahent nie jest jednoosobową działalnością gospodarczą, upewnij się, że jest w dobrej
                        kondycji finansowej, sprawdzając w Raporcie jego wynik finansowy z ostatniego dostępnego
                        sprawozdania finansowego; wątpliwości może budzić brak dostępnego w KRS sprawozdania finansowego
                        za ostatni okres rozliczeniowy.
                      </li>
                    </ul>
                  </td>
                </tr>
                <tr>
                  <td className={styles.td}>F, G </td>
                  <td className={styles.td}>Kontrahent z grupy najwyższego ryzyka – zalecana pogłębiona analiza </td>
                  <td className={styles.td}>
                    <ul>
                      <li>Zwróć uwagę czy Twoje dotychczasowe transakcje z kontrahentem przebiegały bez opóźnień. </li>
                      <li>Stale monitoruj kontrahenta w RiskRadar. </li>
                      <li>
                        Upewnij się, że kondycja finansowa Twojego kontrahenta się nie pogarsza – sprawdź w Raporcie:
                        <ol className={styles.tableOrderedList}>
                          <li>
                            <span className={styles.counter}>1)</span> sytuację finansową kontrahenta w KRS,
                          </li>
                          <li>
                            <span className={styles.counter}>2)</span> wynik finansowy z ostatniego dostępnego
                            sprawozdania finansowego i porównaj go do poprzednich lat o ile nie jest to jednoosobowa
                            działalność gospodarcza; wątpliwości może budzić brak dostępnego w KRS sprawozdania
                            finansowego za ostatni okres rozliczeniowy.
                          </li>
                        </ol>
                      </li>
                    </ul>
                  </td>
                </tr>
                <tr>
                  <td className={styles.td}>H</td>
                  <td className={styles.td}>Kontrahent zadłużony w KRD BIG S.A.</td>
                  <td className={styles.td}>
                    <ul>
                      <li>Zwróć uwagę czy Twoje dotychczasowe transakcje z kontrahentem przebiegały bez opóźnień.</li>
                      <li>Sprawdź w Raporcie jaka jest sytuacja finansowa kontrahenta w KRS.</li>
                      <li>
                        Unikaj transakcji z odroczonym terminem płatności - gdy kontrahent nie będzie w stanie zapłacić
                        za usługę od razu, poleć mu rozłożenie{' '}
                        <a
                          className={styles.blueLink}
                          href="https://fakturatka.pl/sprzedawaj-na-raty"
                          rel="noopener noreferrer"
                          target="_blank">
                          płatności na raty
                        </a>
                        .
                      </li>
                    </ul>
                  </td>
                </tr>
              </table>
              <p className={styles.textUnderline}>Zastrzeżenie prawne</p>
              <p>
                KRD BIG S.A. nie ponosi odpowiedzialności z tytułu decyzji podjętych przez Twoją organizację na
                podstawie danych przekazanych w ramach wykonania usługi. Dane te nie stanowią opinii KRD BIG S.A.
                w przedmiocie współpracy Twojej organizacji ze wskazanym w zleceniu podmiotem i nie mogą stanowić
                podstawy dla roszczeń Twojej organizacji lub kontrahenta Twojej organizacji, którego dotyczyło zlecenie
                wykonania usługi, w stosunku do KRD BIG S.A. z tytułu podjęcia współpracy z przedsiębiorcą, którego
                dotyczyło zlecenie. Odpowiedzialność z tytułu decyzji podjętych na podstawie wykonanej przez KRD BIG
                S.A. usługi ponosi względem swoich kontrahentów wyłącznie Twoja organizacja.
              </p>
            </div>
          );
        }
      }
    ]
  },
  {
    name: 'Usługa raportu o kliencie',
    subsections: [
      {
        name: 'Na czym polega usługa raportu o kliencie?',
        content: function (): JSX.Element {
          return (
            <div className={styles.tutorialSubSectionWrapper}>
              <p>
                W ramach usługi „Raport KG” świadczonej przez Kaczmarski Group sp.j. masz możliwość zapoznania się ze
                szczegółowym raportem na temat klienta. Raport ten opiera się m.in. na danych z: KRD BIG S.A., wybranych
                giełd wierzytelności, Bazy Rzetelnych Firm, tzw. Białej Listy, KRS (w tym US i ZUS), CEIDG, UOKiK, KNF.
                Raport zawiera także wyniki analizy wiarygodności płatniczej (ujawnione KG przez KRD BIG S.A.) w postaci
                kategorii wiarygodności płatniczej oraz kwocie limitu kupieckiego. Raport jest dostępny w formie pliku
                PDF i zwykle zawiera od kilku do kilkunastu stron. Raporty są dostępne tylko dla tych klientów, dla
                których je pobierzesz.
              </p>
              <p>
                Pobrany raport jest dostępny do podglądu w RiskRadar przez okres 7 dni od dnia jego pobrania. Po 7
                dniach zalecane jest pobranie świeżego raportu z uwagi na możliwość dezaktualizacji informacji. Jeśli
                chcesz mieć dostęp do raportu dłużej niż 7 dni, możesz zapisać sobie dany raport na swoim urządzeniu.
              </p>
              <p>
                Masz dostęp do wszystkich raportów pobranych przez użytkowników Twojej firmy w ostatnich 7 dniach.
                Oznacza to, że jeśli Ty pobierzesz aktualny raport o danym kliencie, to inne osoby z Twojej firmy mające
                dostęp do RiskRadar także będą mogły się z nim zapoznać, bez dodatkowego naliczania usługi. Lista
                wszystkich wygenerowanych raportów dostępna jest w zakładce „Historia pobranych raportów” (więcej
                informacji na ten temat znajduje się w Samouczku, w punkcie „11.1. Co zawiera zakładka „Historia
                pobranych raportów”?”.
              </p>
              <p>Raport o kliencie można pobrać:</p>
              <ul>
                <li>w serwisie RiskRadar w zakładce „Lista klientów”,</li>
                <li>w serwisie RiskRadar w karcie danego klienta,</li>
                <li>
                  z cyklicznego raportu e-mail wygenerowanego za pośrednictwem kreatora raportów z serwisu RiskRadar,
                </li>
                <li>za pomocą przycisku „Nowy raport o dowolnym NIP” w Menu.</li>
              </ul>
              <p>
                Podobieństwa i różnice między pobieraniem raportów o klientach z serwisu a pobieraniem ich z cyklicznego
                raportu e-mail są opisane w Samouczku w punkcie „9.5 Pobranie raportu o kliencie z cyklicznego raportu
                e-mail a pobranie z serwisu RiskRadar.”.
              </p>
              <p>
                W przypadku pobierania raportu odnośnie niektórych podmiotów (np. podmiotu zagranicznego, oddziału
                przedsiębiorcy zagranicznego w Polsce czy gospodarstwa rolnego) w niektórych sekcjach raportu informacje
                mogą być niedostępne. Jest to normalna sytuacja. Wynika ona z tego, że w niektórych weryfikowanych
                źródłach sprawdzany podmiot może nie figurować. Analogiczna sytuacja wystąpi w przypadku pobierania
                raportu dla ciągu cyfr, który co prawda spełnia kryteria walidacji, ale nie jest numerem NIP
                jakiegokolwiek podmiotu (np. 3213213218).
              </p>
            </div>
          );
        }
      },
      {
        name: 'Jak pobrać kompleksowy raport o kliencie?',
        content: function (): JSX.Element {
          return (
            <div className={styles.tutorialSubSectionWrapper}>
              <p>Raport możesz pobrać w następujących miejscach:</p>
              <ul>
                <li>
                  na liście klientów – w kolumnie „Raporty” za pomocą przycisku w formie ikonki pobierania":
                  <div
                    className={`${styles.tutorialImageWrapper} ${styles.horizontallImgWithMarginBottom} ${styles.horizontallImgWithMarginTop}`}>
                    <img
                      src={require('../../../assets/images/tutorial/5-2a.png')}
                      alt="Jak wyłączyć usługę monitorowania?"
                      className={styles.horizontallImg}
                    />
                  </div>
                </li>
                <li>
                  w karcie klienta – za pomocą przycisku „Pobierz nowy raport” w sekcji „Raporty”
                  <div
                    className={`${styles.tutorialImageWrapper} ${styles.horizontallImgWithMarginBottom} ${styles.horizontallImgWithMarginTop}`}>
                    <img
                      src={require('../../../assets/images/tutorial/5-2b.png')}
                      alt="Jak wyłączyć usługę monitorowania?"
                      className={styles.horizontallImg}
                    />
                  </div>
                </li>
                <li className={styles.horizontallImgWithMarginBottom}>
                  w cyklicznym raporcie e-mail z listą monitorowanych klientów w wybranych kategoriach wiarygodności
                  płatniczej (szczegóły w Samouczku w punkcie „9.1. Co zawierają cykliczne raporty e-mail?”,{' '}
                </li>
                <li>
                  za pomocą przycisku „Nowy raport o dowolnym NIP” w Menu (szczegóły w Samouczku w punkcie „12.1. Do
                  czego służy i jak działa przycisk „Nowy raport o dowolnym NIP”?”
                </li>
              </ul>
              <p>
                Nie ma możliwości pobierania raportów w ramach akcji grupowej, czyli dla wielu klientów jednocześnie.
              </p>
              <p>
                Generowanie raportu trwa zwykle około 30 sekund. W czasie, kiedy raport jest generowany zobaczysz ikonę
                klepsydry i komunikat o trwającym procesie.
              </p>
              <p>Po wygenerowaniu się raportu o kliencie w serwisie RiskRadar:</p>
              <ul>
                <li>
                  w prawym górnym rogu ekranu pojawi się zielone powiadomienie z linkiem prowadzącym do widoku „Historia
                  pobranych raportów”, gdzie można pobrać wygenerowany raport:
                  <div
                    className={`${styles.tutorialImageWrapper} ${styles.horizontallImgWithMarginBottom} ${styles.horizontallImgWithMarginTop}`}>
                    <img
                      src={require('../../../assets/images/tutorial/5-2c.png')}
                      alt={`"Powiadomienie z linkiem "SPRAWDŹ".`}
                      className={styles.horizontallImg}
                    />
                  </div>
                </li>
                <li>
                  w widoku „Lista klientów” pojawi się samoczynnie (tj. bez konieczności odświeżania strony) zmieniona
                  ikona, która widoczna będzie do końca doby dla wszystkich użytkowników serwisu w Twojej firmie:
                  <div
                    className={`${styles.tutorialImageWrapper} ${styles.horizontallImgWithMarginBottom} ${styles.horizontallImgWithMarginTop}`}>
                    <img
                      src={require('../../../assets/images/tutorial/5-2d.png')}
                      alt="Zmieniona ikona na liście klientów."
                      className={styles.horizontallImg}
                    />
                  </div>
                </li>
                <li className={styles.horizontallImgWithMarginBottom}>
                  w karcie klienta link do pobrania raportu wyświetli się samoczynnie (bez konieczności odświeżenia
                  strony).
                </li>
                <li className={styles.horizontallImgWithMarginBottom}>
                  w widoku "Historia pobranych raportów" statusy pobieranych raportów zmienią się samoczynnie (bez
                  konieczności odświeżania strony).
                </li>
              </ul>
              <p>Podgląd pobranego wcześniej raportu jest możliwy w następujących miejscach:</p>
              <ul>
                <li>
                  na liście klientów – tu możesz przejrzeć trzy ostatnie pobrane raporty (nie starsze niż 7 dni) lub
                  skorzystać z opcji „Więcej”, która przekieruje Cię do karty klienta z pełną listą raportów,
                </li>
                <li>w karcie klienta – tu możesz przejrzeć wszystkie pobrane raporty (nie starsze niż 7 dni),</li>
                <li>
                  w zakładce „Historia pobranych raportów” – tu możesz przejrzeć wszystkie raporty pobrane w RiskRadar
                  przez wszystkich użytkowników Twojej organizacji (więcej w Samouczku, w punkcie „11.1. Co zawiera
                  zakładka „Historia pobranych raportów”?”).
                </li>
              </ul>
              <p>
                Naliczenie usług następuje w momencie kliknięcia przycisku służącego do pobierania raportu a nie w
                momencie udostępnienia raportu do pobrania. Naliczenie usług odbywa się w ramach przyznanego limitu
                (jeśli limit nie został wykorzystany) lub w ramach przekroczenia (jeśli limit został już wykorzystany).
                Więcej informacji na ten temat znajdziesz w punkcie „6. Opłaty miesięczne i przekroczenia” w Samouczku.
              </p>
              <p>
                Z uwagi na to, że wygenerowanie świeżego raportu powoduje naliczanie usług, wykonuj to działanie
                rozważnie, po przemyśleniu, czy rzeczywiście chcesz wygenerować aktualny raport o danym kliencie.
              </p>
              <p>
                Informacje na temat licznika usług znajdziesz w punkcie „6.4. Jak działa licznik usług?” w Samouczku.
              </p>
            </div>
          );
        }
      },
      {
        name: 'Jak wyłączyć usługę raportu o kliencie?',
        content: function (): JSX.Element {
          return (
            <div className={styles.tutorialSubSectionWrapper}>
              <p>
                Tej usługi nie wyłącza się. Raport można tylko pobrać, nie da się go wyłączyć tak jak usługi
                monitorowania. Nie ma możliwości cofnięcia ani anulowania operacji pobrania raportu.{' '}
              </p>
              <p>
                Można natomiast wyłączyć uprawnienia danego użytkownika do pobierania raportów i wtedy użytkownik ten
                nie będzie mógł pobierać raportów o klientach. Działanie to może wykonać Administrator firmy w ramach
                ustawiania uprawnień danego użytkownika.
              </p>
              <p>Informacje dotyczące sposobu naliczania pobrań raportów znajdują się w Samouczku w sekcjach:</p>
              <ul>
                <li>„6.1. Co składa się na usługi w ramach opłaty miesięcznej?” </li>
                <li>„6.4. Jak działa licznik usług?”</li>
              </ul>
            </div>
          );
        }
      }
    ]
  },
  {
    name: 'Opłaty miesięczne i przekroczenia',
    subsections: [
      {
        name: 'Co składa się na usługi w ramach opłaty miesięcznej?',
        content: function (): JSX.Element {
          return (
            <div className={styles.tutorialSubSectionWrapper}>
              <p>Korzystanie z RiskRadar możliwe jest po zawarciu i zaakceptowaniu:</p>
              <ul>
                <li>Indywidualnych Warunków Umowy o Świadczenie Pakietu Usług RiskRadar,</li>
                <li>Regulaminu Serwisu RiskRadar,</li>
                <li>Polityki Prywatności Serwisu RiskRadar,</li>
                <li>Ogólnych Warunków Świadczenia Usługi Monitorowania Kontrahenta,</li>
                <li>Ogólnych Warunków Świadczenia Usługi Raport KG.</li>
              </ul>
              <p>Na usługi w ramach opłaty miesięcznej składają się:</p>
              <ul>
                <li>
                  możliwość korzystania z usługi monitorowania dla określonej liczby klientów, w skład której wchodzą:
                  <ul>
                    <li>sprawdzanie kwoty zadłużenia w KRD BIG S.A.,</li>
                    <li>sprawdzanie zdarzeń w KRD BIG S.A.,</li>
                    <li>
                      sprawdzanie wyniku analizy wiarygodności płatniczej wykonywanej przez KRD BIG S.A.:
                      <ul className={styles.squareList}>
                        <li>w postaci kategorii wiarygodności płatniczej,</li>
                        <li>w postaci limitu kupieckiego (jeżeli usługa ta jest aktywna).</li>
                      </ul>
                    </li>
                  </ul>
                </li>
                <li>
                  możliwość pobrania określonej liczby raportów (KG sp.j.) o klientach zgodnie z limitem wskazanym w
                  Indywidualnych Warunkach Umowy
                </li>
                <li>możliwość korzystania z serwisu RiskRadar (KG sp.j.).</li>
              </ul>
              <p>
                Wspomniane powyżej „określone liczby” zależą od limitów dostępnych dla Twojej firmy w ramach opłaty
                miesięcznej (określonych w Indywidualnych Warunkach Umowy). Jeśli przykładowo Twoja firma posiada limit
                obejmujący możliwość monitorowania 100 podmiotów oraz pobrania raportów o 100 podmiotach, to w ramach
                tego możesz bez dodatkowych kosztów:{' '}
              </p>
              <ul>
                <li>monitorować jednocześnie do 100 dowolnych klientów w danym miesiącu,</li>
                <li>pobrać do 100 Raportów KG o dowolnych klientach w danym miesiącu.</li>
              </ul>
              <p>
                Usługi monitorowania oraz raportów są od siebie niezależne. Oznacza to, że możesz monitorować pewną
                grupę klientów i pobrać raporty dla zupełnie innej grupy klientów. Grupy te mogą, ale nie muszą się
                pokrywać. Przykładowo, dla danego klienta możesz:
              </p>
              <ul>
                <li>pobrać raport i mieć uruchomione monitorowanie,</li>
                <li>pobrać raport, ale mieć wyłączone monitorowanie,</li>
                <li>mieć uruchomione monitorowanie, ale nie pobierać raportu,</li>
                <li>mieć wyłączone monitorowanie i nie pobierać raportu.</li>
              </ul>
              <p>Po zakończeniu danego miesiąca i z chwilą rozpoczęcia nowego miesiąca: </p>
              <ul>
                <li>licznik monitorowanych firm nie resetuje się,</li>
                <li>licznik pobranych Raportów KG resetuje się do zera.</li>
              </ul>
              <p>
                Niewykorzystany limit raportów w danym miesiącu nie przechodzi na kolejny miesiąc. Jeśli chcesz
                dowiedzieć się więcej na ten temat, zapoznaj się z punktem „6.4 Jak działa licznik usług?” w Samouczku.{' '}
              </p>
            </div>
          );
        }
      },
      {
        name: 'Jakie są opłaty za przekroczenie przyznanych limitów usług w ramach opłaty miesięcznej?',
        content: function (): JSX.Element {
          return (
            <div className={styles.tutorialSubSectionWrapper}>
              <p>
                Po wykorzystaniu limitów w ramach opłaty miesięcznej naliczane są opłaty za ich przekroczenie. Opłaty te
                są naliczane osobno dla usługi monitorowania (składającej się ze sprawdzania kwoty zadłużenia w KRD BIG
                S.A., sprawdzania zdarzeń w KRD BIG S.A., sprawdzania kategorii wiarygodności płatniczej w KRD BIG S.A.)
                oraz osobno dla usługi raportu (usługa świadczona przez KG sp.j.). Wysokość opłat jest określona w
                cenniku w umowie.
              </p>
              <p>
                Przykład 1. Posiadasz limit w ramach opłaty miesięcznej na 100 podmiotów w zakresie pobierania raportów.
                Jeśli w danym miesiącu pobrano 110 aktualnych raportów o klientach, to przekroczenie wynosi 10 i za owe
                10 naliczana jest opłata jednostkowa zgodnie z obowiązującym cennikiem.
              </p>
              <p>
                Przykład 2. Posiadasz limit w ramach opłaty miesięcznej na 100 podmiotów w zakresie usługi
                monitorowania. Jeśli w danym miesiącu maksymalna liczba uruchomionych jednocześnie usług monitorowania
                klientów wynosi 110, to przekroczenie wynosi 10 i za owe 10 naliczana jest opłata jednostkowa zgodnie z
                obowiązującym cennikiem.
              </p>
              <p>
                Przykład 3. Posiadasz limit w ramach opłaty miesięcznej na 100 podmiotów w zakresie usługi monitorowania
                i w danym miesiącu:
              </p>
              <ul>
                <li>10-tego dnia miesiąca zostało włączone monitorowanie dla 100 klientów,</li>
                <li>15-tego dnia miesiąca zostało wyłączone monitorowanie dla 20 klientów,</li>
                <li>20-tego dnia miesiąca zostało włączone monitorowanie dla 20 klientów,</li>
              </ul>
              <p>
                to w takiej sytuacji nie nastąpiło przekroczenie limitu w ramach opłaty miesięcznej, ponieważ maksymalna
                liczba jednocześnie monitorowanych klientów wyniosła w tym miesiącu 100.
              </p>
              <p>
                Przykład 4. Posiadasz limit w ramach opłaty miesięcznej na 100 podmiotów w zakresie usługi monitorowania
                i w danym miesiącu:
              </p>
              <ul>
                <li>10-tego dnia miesiąca zostało włączone monitorowanie dla 100 klientów,</li>
                <li>15-tego dnia miesiąca o godz. 23:30 zostało wyłączone monitorowanie dla 20 klientów,</li>
                <li>16-tego dnia miesiąca o godz. 00:30 zostało włączone monitorowanie dla 20 klientów,</li>
              </ul>
              <p>
                to w takiej sytuacji nie nastąpiło przekroczenie limitu w ramach opłaty miesięcznej, ponieważ maksymalna
                liczba jednocześnie monitorowanych klientów wyniosła w tym miesiącu 100.
              </p>
              <p>
                Przykład 5. Posiadasz limit w ramach opłaty miesięcznej na 100 podmiotów w zakresie usługi monitorowania
                i w danym miesiącu:
              </p>
              <ul>
                <li>10-tego dnia miesiąca zostało włączone monitorowanie dla 100 klientów,</li>
                <li>15-tego dnia miesiąca zostało włączone monitorowanie dla 20 klientów,</li>
                <li>20-tego dnia miesiąca zostało wyłączone monitorowanie dla 20 klientów,</li>
              </ul>
              <p>
                to w takiej sytuacji nastąpiło przekroczenie limitu w ramach opłaty miesięcznej o 20, ponieważ
                maksymalna liczba jednocześnie monitorowanych klientów wyniosła w tym miesiącu 120.
              </p>
              <p>
                Przykład 6. Posiadasz limit w ramach opłaty miesięcznej na 100 podmiotów w zakresie usługi monitorowania
                i w danym miesiącu:
              </p>
              <ul>
                <li>10-tego dnia miesiąca zostało włączone monitorowanie dla 100 klientów,</li>
                <li>20-tego dnia miesiąca o godz. 11:00 zostało wyłączone monitorowanie dla 20 klientów,</li>
                <li>20-tego dnia miesiąca o godz. 14:00 zostało włączone monitorowanie dla 20 klientów,</li>
              </ul>
              <p>
                to w takiej sytuacji nastąpiło przekroczenie limitu w ramach opłaty miesięcznej o 20, ponieważ
                maksymalna liczba jednocześnie monitorowanych klientów wyniosła w tym miesiącu 120 (opłata naliczana
                jest za całą dobę niezależnie od tego, czy monitorowanie w danej dobie trwało 3 minuty czy 24 godziny).
              </p>
            </div>
          );
        }
      },
      {
        name: 'Jak dokonać zmiany limitów usług i opłaty miesięcznej?',
        content: function (): JSX.Element {
          return (
            <div className={styles.tutorialSubSectionWrapper}>
              <p>
                W celu dokonania zmiany limitów usług i opłaty miesięcznej na niższe lub wyższe skontaktuj się ze swoim
                opiekunem po stronie KG. Limit i opłatę miesięczną warto zmienić na wyższe, gdy koszt usług
                wykorzystanych poza opłatą miesięczną zaczyna przekraczać koszty opłaty miesięcznej wyższego poziomu.
                Limit i opłatę miesięczną warto zmienić na niższe, gdy w ramach Twojej firmy obecny limit w ramach
                opłaty miesięcznej jest w znacznym stopniu niewykorzystywany.
              </p>
            </div>
          );
        }
      },
      {
        name: 'Jak działa licznik usług?',
        content: function (): JSX.Element {
          return (
            <div className={styles.tutorialSubSectionWrapper}>
              <p>W RiskRadar, w pasku górnym po prawej stronie, dostępne są dwa liczniki usług:</p>
              <ul>
                <li>
                  licznik aktualnie monitorowanych klientów;
                  <ul>
                    <li>
                      pokazuje, dla ilu klientów jest aktualnie włączone monitorowanie (w ramach całej firmy, tj. przez
                      wszystkich użytkowników),
                    </li>
                    <li>licznik ten nie zeruje się na koniec każdego miesiąca kalendarzowego,</li>
                    <li>
                      stan licznika ulega zwiększeniu o 1 w momencie włączenia monitorowania klienta (włączanie
                      monitorowania jest opisane w punkcie „3.2. Jak włączyć usługę monitorowania?” w Samouczku),
                    </li>
                    <li>
                      stan licznika ulega zmniejszeniu o 1 w momencie wyłączenia monitorowania klienta (wyłączanie
                      monitorowania jest opisane w punkcie „3.3. Jak wyłączyć usługę monitorowania?” w Samouczku),
                    </li>
                  </ul>
                </li>
                <li>
                  licznik wszystkich Raportów KG o kliencie pobranych w bieżącym miesiącu;
                  <ul>
                    <li>
                      pokazuje, ile raportów zostało pobranych w danym miesiącu (w ramach całej firmy, tj. przez
                      wszystkich użytkowników):
                      <ul>
                        <li>uwzględniane są pobrania raportów o klientach z poziomu serwisu RiskRadar,</li>
                        <li>uwzględniane są pobrania raportów o klientach z poziomu cyklicznego raportu e-mail,</li>
                      </ul>
                    </li>
                    <li>licznik ten zeruje się na koniec każdego miesiąca kalendarzowego,</li>
                    <li>stan licznika ulega zwiększeniu o 1 w momencie pobrania raportu,</li>
                    <li>
                      stan licznika nie ulega zmniejszeniu w żadnej sytuacji (za wyjątkiem zerowania na koniec miesiąca
                      kalendarzowego).
                    </li>
                  </ul>
                </li>
              </ul>
              <div className={styles.tutorialImageWrapper}>
                <img
                  src={require('../../../assets/images/tutorial/6-4.png')}
                  alt="Jak działa licznik usług?"
                  className={styles.horizontallImg}
                />
              </div>
              <p>RiskRadar nie pokazuje:</p>
              <ul>
                <li>informacji o posiadanym limicie w ramach opłaty miesięcznej (np. 100 podmiotów),</li>
                <li>informacji o liczbie usług pozostałych do wykorzystania w ramach opłaty miesięcznej.</li>
              </ul>
            </div>
          );
        }
      }
    ]
  },
  {
    name: 'Zarządzanie klientami',
    subsections: [
      {
        name: 'Jak dodawać pojedynczych klientów?',
        content: function (): JSX.Element {
          return (
            <div className={styles.tutorialSubSectionWrapper}>
              <p>
                Na ekranie z listą klientów kliknij przycisk „Dodaj klienta”. Otworzy się nowa karta, w której możesz
                dodać nowego klienta wpisując poprawny NIP (pole obowiązkowe), nazwę (pole obowiązkowe), opis (2 pola
                opcjonalne), adres (pola opcjonalne). Nazwa klienta nie musi być pełną nazwą rejestrową (np. zgodnie z
                KRS), może być to nazwa uproszczona. Operację potwierdź przyciskiem „Zapisz”. Od tego momentu nowy
                klient jest widoczny w RiskRadar.
              </p>
              <p>
                Wprowadzając NIP pamiętaj, że jeśli firma jest z Polski, to numer NIP można wpisać bez prefiksu „PL” lub
                z prefiksem „PL” (obydwie formy są dopuszczalne). Jeśli firma jest z UE, to należy wpisać numer NIP (lub
                inny numer identyfikacyjny na potrzeby podatkowe w przypadku podmiotu zagranicznego z UE) zawsze z
                prefiksem danego kraju. Nie można dodawać klientów spoza UE.
              </p>
              <p>
                Dodanie w ten sposób klienta nie powoduje włączenia ani naliczenia żadnych usług w RiskRadar. Jeśli
                chcesz włączyć usługi dotyczące danego klienta, to zapoznaj się z punktem „3.2. Jak włączyć usługę
                monitorowania?” oraz „5.2. Jak włączyć usługę raportu o kliencie?” w Samouczku.
              </p>
              <div className={styles.tutorialImageWrapper}>
                <img
                  src={require('../../../assets/images/tutorial/7-1.png')}
                  alt="Jak dodawać pojedynczych klientów?"
                  className={styles.horizontallImg}
                />
              </div>
            </div>
          );
        }
      },
      {
        name: 'Jak usunąć klienta z RiskRadar?',
        content: function (): JSX.Element {
          return (
            <div className={styles.tutorialSubSectionWrapper}>
              <p>
                Operację tę możesz wykonać na karcie klienta za pomocą przycisku „Usuń klienta”. Po kliknięciu tego
                przycisku zobaczysz dodatkowy komunikat potwierdzający tę operację.
              </p>
              <p>
                Możliwość usunięcia klienta dotyczy każdego klienta, niezależnie od tego, czy był monitorowany, czy
                pobrano o nim raporty, itp. Działanie to spowoduje, że usunięty klient nie będzie już widoczny w
                RiskRadar, a wszystkie jego dane zostaną usunięte (np. historia zdarzeń w KRD BIG S.A., pobrane
                raporty). W przyszłości możesz dodać tego klienta ponownie.
              </p>
              <p>
                Usunięcie klienta powoduje automatyczne wyłączenie usługi monitorowania tego klienta (jeśli było
                włączone).
              </p>
              <div className={styles.tutorialImageWrapper}>
                <img
                  src={require('../../../assets/images/tutorial/7-2.png')}
                  alt="Jak usunąć klienta z RiskRadar?"
                  className={styles.horizontallImg}
                />
              </div>
            </div>
          );
        }
      },
      {
        name: 'Do czego służy i jak działa import za pomocą pliku CSV?',
        content: function (): JSX.Element {
          return (
            <div className={styles.tutorialSubSectionWrapper}>
              <p>
                Import za pomocą pliku CSV służy do wykonywania akcji masowych (np. dodawania do RiskRadar większej
                liczby klientów) w sprawny sposób. Wystarczy, że przygotujesz odpowiedni plik z danymi tych klientów.
                Wymagania dla takiego pliku są opisane w punkcie „7.4. Jakie kryteria musi spełniać plik CSV, aby import
                się powiódł?” w Samouczku.
              </p>
              <p>
                W RiskRadar dostępna jest lista przeprowadzonych importów plików CSV. Dla każdego importu lista
                prezentuje informacje o nazwie pliku (z możliwością jego pobrania), datę importu (na moment jego
                zakończenia), liczbę rekordów zaimportowanych (tylko dla importów zakończonych), status (oczekujący, w
                toku, zakończony, błędny). Lista importów zawiera pliki importowane w ciągu ostatnich 12 miesięcy, pliki
                importowane wcześniej nie są widoczne na liście.
              </p>
              <p>
                Podgląd listy importów jest dostępny dla wszystkich użytkowników w ramach Twojej firmy – każdy
                użytkownik może np. sprawdzić, kiedy miał miejsce ostatni import danych. Operację importu CSV może
                natomiast wykonywać wyłącznie osoba posiadająca do tego wymagane uprawnienia. Uprawnienia te nadaje
                administrator RiskRadar w Twojej firmie.
              </p>
              <p>W RiskRadar stosowana jest następująca logika przy importowaniu danych z pliku CSV:</p>
              <ul>
                <li>
                  jeśli dany klient jest w pliku CSV, a nie było go w RiskRadar, to zostanie on dodany do RiskRadar,
                </li>
                <li>
                  jeśli dany klient jest w pliku CSV i był w RiskRadar dodany przez import CSV, to jego dane zostaną
                  nadpisane,
                </li>
                <li>
                  jeśli danego klienta nie ma w pliku CSV, a był w RiskRadar dodany przez import CSV, to jego dane
                  zostaną oznaczone jako „stare”,
                </li>
                <li>
                  jeśli dany klient jest w pliku CSV i był w RiskRadar dodany ręcznie, to zostanie on dodany do
                  RiskRadar (w efekcie w RiskRadar będzie 2 klientów o tym samym numerze NIP).
                </li>
              </ul>
              <p>
                Podczas operacji importu danych z pliku CSV usuwane są wszystkie znaki cudzysłowu górnego i dolnego z
                pola „Nazwa firmy” niezależnie od tego, w którym miejscu nazwy firmy występują. Działanie to jest
                wykonywane w celu ułatwienia późniejszej pracy z danymi w RiskRadar, w szczególności sortowania po
                nazwie klienta.
              </p>
              <div className={styles.tutorialImageWrapper}>
                <img
                  src={require('../../../assets/images/tutorial/7-3.png')}
                  alt="Do czego służy i jak działa import za pomocą pliku CSV?"
                  className={styles.horizontallImg}
                />
              </div>
            </div>
          );
        }
      },
      {
        name: 'Jakie kryteria musi spełniać plik CSV, aby import się powiódł?',
        content: function (): JSX.Element {
          return (
            <div className={styles.tutorialSubSectionWrapper}>
              <p>RiskRadar stosuje następujące kryteria weryfikacji poprawności pliku CSV:</p>
              <ul>
                <li>kryteria poprawności dla pliku:</li>
                <ul>
                  <li>plik jest w formacie CSV,</li>
                  <li>plik ma kodowanie UTF8 BOM,</li>
                  <li>plik zawiera pierwszy wiersz z nazwami kolumn (dokładnie według szablonu),</li>
                  <li>kolumny mają odpowiednie nazwy (zgodnie z szablonem),</li>
                  <li>kolumny mają odpowiednią kolejność (zgodnie z szablonem),</li>
                  <li>separatory komórek są średnikami,</li>
                  <li>jest uzupełniony przynajmniej jeden rekord z danymi,</li>
                  <li>nie ma rekordów zawierających ten sam NIP,</li>
                  <li>w treści komórek nie występują następujące znaki niedozwolone (tj. średnik, enter);</li>
                </ul>
                <li>kryteria poprawności dla poszczególnych wierszy w pliku:</li>
                <ul>
                  <li>są uzupełnione wszystkie dane obowiązkowe, tj.: NIP i Nazwa firmy,</li>
                  <li>numery NIP są poprawne (10 cyfr lub 10 cyfr i prefiks kraju, poprawna suma kontrolna),</li>
                  <li>wartości liczbowe w polach Sprzedaż i Należności są liczbami bez spacji,</li>
                  <li>pozycje dziesiętne w polach Sprzedaż i Należności są oddzielone przecinkiem,</li>
                  <li>maksymalna liczba znaków w polach Sprzedaż i Należności nie przekracza 16,</li>
                  <li>maksymalna liczba znaków w polu „Nazwa firmy” nie przekracza 100 znaków,</li>
                  <li>maksymalna liczba znaków w polu „Opis 1” i „Opis 2” nie przekracza 30 znaków,</li>
                  <li>typ operacji to zawiera dokładnie jedną dozwoloną wartość (tj. puste, 1, 2, 3, 4).</li>
                </ul>
              </ul>
              <p>Zakres danych w pliku CSV:</p>
              <ul>
                <li>Typ operacji (pole opcjonalne),</li>
                <li>NIP (pole obligatoryjne),</li>
                <li>Nazwa firmy (pole obligatoryjne),</li>
                <li>Wartość sprzedaży brutto za ostatnie 12 miesięcy wyrażona w PLN (pole opcjonalne),</li>
                <li>Wartość należności brutto na dzień importu wyrażona w PLN (pole opcjonalne),</li>
                <li>Opis 1 (pole opcjonalne),</li>
                <li>Opis 2 (pole opcjonalne).</li>
              </ul>
              <p>Wskazówki dotyczące numerów NIP:</p>
              <ul>
                <li>jeśli podmiot jest z Polski to wpisz 10 cyfr bez prefiksu „PL” lub z prefiksem „PL”,</li>
                <li>
                  jeśli podmiot jest z UE to wpisz prefiks kraju i numer identyfikacyjny podmiotu (na potrzeby
                  podatkowe) zagranicznego w UE,
                </li>
                <li>jeśli podmiot jest spoza UE to nie wpisuj jego numeru NIP (takie podmioty nie są obsługiwane).</li>
              </ul>
              <p>Wzór pliku CSV służącego do dodawania klientów znajdziesz w zakładce „Import danych - CSV” w Menu. </p>
              <p>
                Przykład wypełnionego pliku CSV znajdziesz tutaj: <br />
                <a
                  href="https://riskradar.pl/wp-content/uploads/2022/08/Import-przyklad.csv"
                  rel="noopener noreferrer"
                  target="_blank">
                  https://riskradar.pl/wp-content/uploads/2022/08/Import-przyklad.csv
                </a>
              </p>
              <p>
                Odpowiedzi na często zadawane pytania dotyczące przygotowania poprawnego pliku CSV znajdziesz tutaj:{' '}
                <br />
                <a
                  href="https://riskradar.pl/wp-content/uploads/2022/09/Instrukcja-przygotowania-pliku-CSV.pdf"
                  rel="noopener noreferrer"
                  target="_blank">
                  https://riskradar.pl/wp-content/uploads/2022/09/Instrukcja-przygotowania-pliku-CSV.pdf
                </a>
              </p>
            </div>
          );
        }
      },
      {
        name: 'Jak dokonać importu danych o klientach za pomocą pliku CSV?',
        content: function (): JSX.Element {
          return (
            <div className={styles.tutorialSubSectionWrapper}>
              <h4>Metoda ręczna</h4>
              <p>
                Operację importu CSV może wykonywać wyłącznie osoba posiadająca do tego wymagane uprawnienie.
                Uprawnienia te nadaje administrator RiskRadar w Twojej firmie. Jeśli masz takie uprawnienia, możesz
                dokonać importu pliku CSV.
              </p>
              <p>
                W celu wykonania importu wejdź przez Menu do zakładki „Import danych - CSV” i pobierz na swoje
                urządzenie szablon pliku CSV. Uzupełnij dane w pliku CSV zgodnie z wytycznymi, o których mowa w punkcie
                „7.4. Jakie kryteria musi spełniać plik CSV, aby import się powiódł?” w Samouczku. Gotowy plik wgraj do
                RiskRadar. Następnie RiskRadar zweryfikuje poprawność pliku i danych w nim zawartych. Weryfikacja ta nie
                dotyczy jednak tego, czy dane są poprawne merytorycznie (np. czy zamiast przychodów 12345,67 nie powinno
                być 123456,70).
              </p>
              <p>
                W przypadku braku jakiegokolwiek błędu danych przy imporcie pliku CSV RiskRadar zaimportuje wszystkie
                dane z tego pliku i wyświetli informację o sukcesie importu wraz z liczbą rekordów poprawnie
                zaimportowanych. Dane z pliku CSV zostaną dodane do RiskRadar.
              </p>

              <p>W przypadku wystąpienia jednego lub więcej błędu danych przy imporcie pliku CSV RiskRadar:</p>
              <ul>
                <li>zaimportuje wszystkie poprawne rekordy z tego pliku (poprawne rekordy zostaną dodane),</li>
                <li>
                  wyświetli informację o sukcesie importu (status „Zakończony”) z liczbą rekordów zaimportowanych,
                </li>
                <li>wyświetli przycisk „Pobierz plik” pod którym znajduje się załadowany plik CSV,</li>
                <li>
                  wyświetli pomarańczowy trójkąt ostrzegawczy oraz przycisk „Pokaż błędy”, pod którym znajduje się wykaz
                  błędów dla rekordów, które nie zostały zaimportowane.
                </li>
              </ul>
              <p>
                Jeśli natomiast błędny jest sam plik (np. nie jest w formacie csv lub nie ma kodowania UTF8 BOM), to
                import jest przerywany, zwracany jest powód niewykonania importu i żadne dane nie są wtedy dodawane do
                RiskRadar.
              </p>

              <h4>Metoda automatyczna</h4>
              <p>
                RiskRadar umożliwia regularne i automatyczne pobieranie plików CSV z systemu ERP Twojej firmy i
                następnie importowanie danych w nich zawartych do RiskRadar. RiskRadar sprawdza, czy pojawił się nowy
                plik CSV z systemu ERP i jeśli to nastąpiło to dokonuje importu danych z tego pliku z zachowaniem
                kryteriów walidacji opisanych w punkcie „7.4. Jakie kryteria musi spełniać plik CSV, aby import się
                powiódł?” w Samouczku.
              </p>
              <p>
                W eksporcie danych z systemu ERP do pliku CSV uwzględniani są tylko ci klienci, do których nastąpiła
                sprzedaż w ostatnich 12 miesiącach. Nie są uwzględniani klienci, do których nie była realizowana
                sprzedaż w ostatnich 12 miesiącach.
              </p>
              <p>
                W przypadku braku jakiegokolwiek błędu danych przy imporcie pliku CSV RiskRadar zaimportuje wszystkie
                dane z tego pliku i wyświetli informację o sukcesie importu wraz z liczbą rekordów poprawnie
                zaimportowanych. Dane z pliku CSV zostaną dodane do RiskRadar.
              </p>

              <p>W przypadku wystąpienia jednego lub więcej błędu danych przy imporcie pliku CSV RiskRadar:</p>
              <ul>
                <li>zaimportuje wszystkie poprawne rekordy z tego pliku (poprawne rekordy zostaną dodane),</li>
                <li>
                  wyświetli informację o sukcesie importu (status „Zakończony”) z liczbą rekordów zaimportowanych,
                </li>
                <li>wyświetli przycisk „Pobierz plik” pod którym znajduje się załadowany plik CSV,</li>
                <li>
                  wyświetli pomarańczowy trójkąt ostrzegawczy oraz przycisk „Pokaż błędy”, pod którym znajduje się wykaz
                  błędów dla rekordów, które nie zostały zaimportowane.
                </li>
              </ul>

              <p>
                Jeśli natomiast błędny jest sam plik (np. nie jest w formacie csv lub nie ma kodowania UTF8 BOM), to
                import jest przerywany, zwracany jest powód niewykonania importu i żadne dane nie są wtedy dodawane do
                RiskRadar.
              </p>

              <p>
                Uruchomienie funkcjonalności importu plików CSV metodą automatyczną nie jest możliwe dla użytkowników
                RiskRadar (w tym dla administratora RiskRadar w Twojej firmie). Uruchomienie tej funkcjonalności wymaga
                wsparcia ze strony integratora rekomendowanego przez KG. Integrator wykonuje takie działania jak:
              </p>
              <ul>
                <li>dodanie do Twojego systemu ERP mechanizmu eksportującego dane o klientach,</li>
                <li>konfiguracja tego mechanizmu (np. częstotliwość eksportowania pliku CSV z systemu ERP),</li>
                <li>dokonanie integracji tego mechanizmu z RiskRadar (m.in. w zakresie przesyłania pliku),</li>
                <li>analiza możliwości usprawnienia i automatyzacji procesów weryfikacji kontrahentów.</li>
              </ul>
              <p>
                KG nie pokrywa kosztów wsparcia ze strony integratora – ani na etapie uruchomienia automatycznego
                importu plików CSV, ani na etapie utrzymania. Uzgodnienia w tym zakresie są pomiędzy Twoją firmą a
                integratorem.
              </p>
            </div>
          );
        }
      },
      {
        name: 'Wykonywanie działań masowych za pomocą kodów operacji przy imporcie pliku CSV.',
        content: function (): JSX.Element {
          return (
            <div className={styles.tutorialSubSectionWrapper}>
              <p>
                Serwis RiskRadar daje możliwość wykonania akcji masowych za pomocą kodów operacji wpisywanych w pliku
                CSV. Służy do tego kolumna „Typ operacji” w szablonie pliku CSV. Jest to pole opcjonalne. Dla danego
                NIPu może być wprowadzona tylko jedna wartość, tj. można zlecić tylko jeden rodzaj operacji dla danego
                NIPu w ramach danego importu pliku CSV.
              </p>
              <p>
                UWAGA: Użytkownik, który chce włączyć lub wyłączyć monitorowanie za pomocą kodów w imporcie CSV musi
                mieć nadane uprawnienie „Włączanie i wyłączanie monitorowania klienta”. W przeciwnym razie polecenia
                włączenia czy wyłączenia monitorowania nie zostaną wykonane.
              </p>
              <p>
                Użytkownik nie otrzymuje informacji zwrotnej o wykonaniu lub niewykonaniu operacji zleconych za pomocą
                kodów przy imporcie pliku CSV.
              </p>
              <p>
                Wpisanie kodu 1 (dodanie klienta i włączenie monitorowania) powoduje:
                <ul>
                  <li>dodanie klienta do RiskRadar (jeśli nie był wcześniej dodany przez CSV),</li>
                  <li>rozpoczęcie włączania monitorowania na danym NIP (jeśli było wyłączone),</li>
                  <li>nadpisanie danych klienta (jeśli istnieje w RiskRadar i był dodany przez CSV).</li>
                </ul>
              </p>
              <p>
                Wpisanie kodu 2 (dodanie klienta bez włączania monitorowania) lub pozostawienie pustego pola powoduje:
                <ul>
                  <li>dodanie klienta do RiskRadar (jeśli nie był wcześniej dodany przez CSV), </li>
                  <li>nadpisanie danych klienta (jeśli istnieje w RiskRadar i był dodany przez CSV).</li>
                </ul>
              </p>
              <p>
                Wpisanie kodu 3 (wyłączenie monitorowania) powoduje:
                <ul>
                  <li>
                    wyłączenie monitorowania na danym NIP (jeśli było włączone),
                    <ul>
                      <li>nastąpi to niezależnie od tego, czy klient był dodany przez CSV czy ręcznie,</li>
                    </ul>
                  </li>
                  <li>nadpisanie danych klienta (jeśli istnieje w RiskRadar i był dodany przez CSV).</li>
                </ul>
              </p>
              <p>
                Wpisanie kodu 4 (usunięcie klienta i wyłączenie monitorowania) powoduje:
                <ul>
                  <li>
                    usunięcie wszystkich kont klienta o danym NIP,
                    <ul>
                      <li>dotyczy to zarówno klientów dodanych ręcznie jak i przez CSV,</li>
                      <li>nastąpi to niezależnie od tego, jaką nazwę klienta wpisano w pliku CSV,</li>
                    </ul>
                  </li>
                  <li>
                    wyłączenie monitorowania na danym NIP (jeśli było włączone),
                    <ul>
                      <li>nastąpi to niezależnie od tego, czy klient był dodany przez CSV czy ręcznie.</li>
                    </ul>
                  </li>
                </ul>
              </p>
            </div>
          );
        }
      }
    ]
  },
  {
    name: 'Zarządzanie użytkownikami',
    subsections: [
      {
        name: 'Jakie są rodzaje użytkowników i jakie mają uprawnienia?',
        content: function (): JSX.Element {
          return (
            <div className={styles.tutorialSubSectionWrapper}>
              <p>W RiskRadar mogą występować następujące rodzaje użytkowników w ramach Twojej firmy:</p>
              <ul>
                <li>Administrator firmy,</li>
                <li>Pracownik firmy, </li>
                <li>Użytkownik bez dostępu do serwisu, </li>
                <li>Konto techniczne.</li>
              </ul>
              <p>Administrator firmy:</p>
              <ul>
                <li>
                  posiada następujące uprawnienia podstawowe:
                  <ul>
                    <li>przeglądanie danych na liście klientów i karcie klienta,</li>
                    <li>dodanie (ręczne) / aktualizacja / usunięcie klienta,</li>
                    <li>ustawianie / resetowanie własnego hasła,</li>
                    <li>wyświetlenie listy użytkowników firmy, </li>
                    <li>dodanie, aktualizacja, usunięcie kont użytkowników firmy,</li>
                    <li>zmiana statusu kont użytkowników (aktywne lub nieaktywne),</li>
                    <li>dodawanie i usuwanie uprawnień użytkowników firmy,</li>
                    <li>wyświetlanie listy cyklicznych raportów e-mail,</li>
                    <li>dodawanie, modyfikowanie, usuwanie raportów e-mail za pomocą kreatora raportów,</li>
                    <li>dodawanie, usuwanie użytkowników otrzymujących cykliczne raporty e-mail, </li>
                  </ul>
                </li>
                <li>
                  może mieć nadane uprawnienia dodatkowe:
                  <ul>
                    <li>włączanie i wyłączanie monitorowania klienta,</li>
                    <li>
                      możliwość pobierania aktualnego raportu o kliencie,
                      <ul>
                        <li>z poziomu serwisu RiskRadar,</li>
                        <li>z poziomu cyklicznego raportu e-mail (o ile jest na liście odbiorców),</li>
                      </ul>
                    </li>
                    <li>możliwość zarządzania importem danych z CSV.</li>
                  </ul>
                </li>
              </ul>
              <p>Pracownik firmy:</p>
              <ul>
                <li>
                  posiada następujące uprawnienia podstawowe:
                  <ul>
                    <li>przeglądanie danych na liście klientów i karcie klienta,</li>
                    <li>dodanie (ręczne) / aktualizacja / usunięcie klienta,</li>
                    <li>ustawianie / resetowanie własnego hasła.</li>
                  </ul>
                </li>
                <li>
                  może mieć nadane uprawnienia dodatkowe:
                  <ul>
                    <li>włączanie i wyłączanie monitorowania klienta,</li>
                    <li>
                      możliwość pobierania aktualnego raportu o kliencie,
                      <ul>
                        <li>z poziomu serwisu RiskRadar,</li>
                        <li>z poziomu cyklicznego raportu e-mail (o ile jest na liście odbiorców),</li>
                      </ul>
                    </li>
                    <li>możliwość zarządzania importem danych z CSV.</li>
                  </ul>
                </li>
              </ul>
              <p>Użytkownik bez dostępu do serwisu:</p>
              <ul>
                <li>nie otrzymuje e-mail z linkiem do ustawienia hasła logowania do RiskRadar,</li>
                <li>nie ma dostępu do serwisu RiskRadar,</li>
                <li>
                  posiada następujące uprawnienia podstawowe:
                  <ul>
                    <li>otrzymywanie cyklicznych raportów e-mail (o ile został dodany do list odbiorców),</li>
                  </ul>
                </li>
                <li>
                  może mieć nadane uprawnienia dodatkowe:
                  <ul>
                    <li>
                      możliwość pobierania aktualnego raportu o kliencie:
                      <ul>
                        <li>z poziomu cyklicznego raportu e-mail (o ile jest na liście odbiorców).</li>
                      </ul>
                    </li>
                  </ul>
                </li>
              </ul>
              <p>Konto techniczne:</p>
              <ul>
                <li>nie ma dostępu do serwisu RiskRadar,</li>
                <li>pozwala wygenerować token do konfiguracji automatycznego importu danych.</li>
              </ul>
              <p>
                Informacje o klientach w ramach danej firmy są dostępne dla wszystkich użytkowników danej firmy. Oznacza
                to, że każdy użytkownik widzi wszystkich klientów dodanych do RiskRadar w ramach danej firmy a nie tylko
                tych, których dodał we własnym zakresie. Podobna zasada dotyczy włączania usług – tzn. dowolny
                użytkownik danej firmy posiadający odpowiednie uprawnienia ma możliwość włączania np. monitorowania dla
                każdego klienta danej firmy. Przykład: jeśli 10 użytkowników dodało po 10 klientów, to w efekcie każdy
                użytkownik ma dostęp do informacji o wszystkich klientach (tj. 100) i może włączyć dla nich np.
                monitorowanie.{' '}
              </p>
            </div>
          );
        }
      },
      {
        name: 'Jak dodać nowego użytkownika?',
        content: function (): JSX.Element {
          return (
            <div className={styles.tutorialSubSectionWrapper}>
              <p>
                Operację dodania nowego konta użytkownika może wykonać Administrator firmy (może on także utworzyć konto
                dla kolejnego Administratora lub Administratorów firmy). Jeśli jesteś Administratorem firmy, wejdź do
                zakładki „Użytkownicy i uprawnienia” w Menu i użyj przycisku „Dodaj użytkownika”.
              </p>
              <p>Krok 1. Uzupełnij wymagane dane (imię i nazwisko, e-mail, telefon).</p>
              <p>
                Krok 2. Wybierz jedną z ról dla użytkownika:
                <ul>
                  <li>
                    Administrator firmy (może zarządzać użytkownikami oraz konfigurować w kreatorze raportów cykliczne
                    raporty wysyłane w formie e-mail),
                  </li>
                  <li>Pracownik firmy (może się zalogować do serwisu i korzystać z jego funkcji),</li>
                  <li>
                    Użytkownik bez dostępu do serwisu (nie może się zalogować do serwisu, ale może otrzymywać cykliczne
                    raporty e-mail),
                  </li>
                  <li>Konto techniczne (służy wyłącznie skonfigurowaniu automatycznego importu CSV).</li>
                </ul>
              </p>
              <p>
                Krok 3. Określ dodatkowe uprawnienia dla użytkownika:
                <ul>
                  <li>włączanie i wyłączanie monitorowania klienta,</li>
                  <li>możliwość pobierania aktualnego raportu o kliencie,</li>
                  <li>możliwość zarządzania importem danych z CSV.</li>
                </ul>
              </p>

              <p>Potwierdź operację klikając przycisk „Zapisz”.</p>
              <p>
                RiskRadar stworzy nowe konto użytkownika i wyśle do niego e-mail z instrukcją ustawienia hasła (o ile
                dany użytkownik ma uprawnienia dostępu do serwisu RiskRadar).{' '}
              </p>
              <p>
                Podczas tworzenia nowego konta użytkownika sprawdzana jest unikalność podanego adresu e-mail w serwisie
                RiskRadar. Oznacza to, że nie można założyć więcej niż jednego konta użytkownika dla danego adresu
                e-mail.
              </p>
              <div className={styles.tutorialImageWrapper}>
                <img
                  src={require('../../../assets/images/tutorial/8-2.png')}
                  alt="Jak dodać nowego użytkownika?"
                  className={styles.horizontallImg}
                />
              </div>
            </div>
          );
        }
      },
      {
        name: 'Jak usunąć istniejącego użytkownika?',
        content: function (): JSX.Element {
          return (
            <div className={styles.tutorialSubSectionWrapper}>
              <p>
                Administrator firmy może wykonać operację usunięcia istniejącego konta użytkownika (w tym innego
                Administratora firmy). Jeśli jesteś Administratorem firmy, wejdź do zakładki „Użytkownicy i uprawnienia”
                w Menu. Możesz usunąć konto użytkownika na dwa sposoby:
              </p>
              <ul>
                <li>z poziomu listy użytkowników, klikając w „Akcje”, a następnie przycisk „Usuń”,</li>
                <li>z poziomu karty użytkownika, klikając w przycisk „Usuń”.</li>
              </ul>
              <p>
                Wykonanie operacji usunięcia konta użytkownika spowoduje automatyczne wysłanie do usuniętego użytkownika
                powiadomienia e-mail o tym fakcie.
              </p>
              <div className={styles.tutorialImageWrapper}>
                <img
                  src={require('../../../assets/images/tutorial/8-3.png')}
                  alt="Jak usunąć istniejącego użytkownika?"
                  className={styles.horizontallImg}
                />
              </div>
            </div>
          );
        }
      },
      {
        name: 'Jak nadać hasło nowemu użytkownikowi?',
        content: function (): JSX.Element {
          return (
            <div className={styles.tutorialSubSectionWrapper}>
              <p>
                Użytkownik ustawia swoje hasło samodzielnie, bez udziału Administratora. Administrator firmy nie nadaje
                hasła nowemu użytkownikowi RiskRadar. Po założeniu nowego konta użytkownika, otrzymuje on e-mail z
                instrukcją dotyczącą samodzielnego ustawienia hasła.
              </p>
              <p>
                Pierwszy użytkownik RiskRadar w Twojej firmie ma nadany domyślnie status Administratora i w dniu
                uruchomienia RiskRadar otrzymuje e-mail z instrukcją dotyczącą samodzielnego ustawienia hasła.
                Administrator może nadawać następnie prawa dostępu dla innych użytkowników, co jest opisane w punkcie
                „8.2. Jak dodać nowego użytkownika?” w Samouczku.
              </p>
              <p>
                Link do ustawienia hasła jest ważny przez 24 godziny. Jeśli ten czas już minął, można ustawić własne
                hasło korzystając z funkcji “Nie pamiętam hasła” na stronie logowania{' '}
                <a href="https://panel.riskradar.pl/" rel="noopener noreferrer" target="_blank">
                  https://panel.riskradar.pl/
                </a>
                .
              </p>
              <p>
                W przypadku wygaśnięcia linku do ustawienia hasła zawartego w e-mailu z instrukcją samodzielnego
                ustawienia hasła, użytkownik otrzyma komunikat „Uprzejmie informujemy, że link do zmiany hasła wygasł.
                Jeśli chcesz zmienić swoje hasło, wejdź na stronę logowania i wygeneruj ponownie link do ustawienia
                hasła korzystając z funkcji "Nie pamiętam hasła". Jest to jedyna metoda ustawienia hasła w RiskRadar.”.
              </p>
            </div>
          );
        }
      },
      {
        name: 'Jak zresetować hasło istniejącego użytkownika?',
        content: function (): JSX.Element {
          return (
            <div className={styles.tutorialSubSectionWrapper}>
              <p>
                Administrator firmy nie zmienia hasła użytkownika RiskRadar. Użytkownik zmienia swoje hasło
                samodzielnie, bez udziału Administratora.
              </p>
              <p>
                Jeśli użytkownik chce zmienić swoje hasło (np. z powodu jego zapomnienia) to wykonuje kolejno
                następujące czynności:
              </p>
              <ul>
                <li>wchodzi na ekran logowania,</li>
                <li>klika w link „Nie pamiętam hasła”,</li>
                <li>otrzymuje e-mail z linkiem do ustawienia nowego hasła,</li>
                <li>klika w link, który otwiera ekran służący do zmiany hasła,</li>
                <li>wprowadza nowe hasło i potwierdza to przyciskiem „Zapisz”.</li>
              </ul>
            </div>
          );
        }
      },
      {
        name: 'Jak zmodyfikować rolę lub uprawnienia użytkownika?',
        content: function (): JSX.Element {
          return (
            <div className={styles.tutorialSubSectionWrapper}>
              <p>
                Będąc Administratorem firmy możesz zmienić zarówno rolę jak i uprawnienia danego użytkownika. W tym celu
                wejdź do zakładki „Użytkownicy i uprawnienia” w Menu, następnie kliknij w wybranego użytkownika. Otworzy
                się nowa karta przeglądarki z danymi użytkownika. Użyj przycisku „Modyfikuj” aby wejść w tryb edycji
                danych użytkownika. Dokonaj pożądanych zmian i zapisz zmiany.
              </p>
              <div className={styles.tutorialImageWrapper}>
                <img
                  src={require('../../../assets/images/tutorial/8-6.png')}
                  alt="Jak zmodyfikować rolę lub uprawnienia użytkownika?"
                  className={styles.horizontallImg}
                />
              </div>
            </div>
          );
        }
      }
    ]
  },
  {
    name: 'Kreator raportów cyklicznych',
    subsections: [
      {
        name: 'Co zawierają cykliczne raporty e-mail?',
        content: function (): JSX.Element {
          return (
            <div className={styles.tutorialSubSectionWrapper}>
              <p>
                Cykliczne raporty e-mail zawierają następujące informacje o klientach monitorowanych na zlecenie Twojej
                firmy w serwisie RiskRadar:
              </p>
              <ul>
                <li>
                  podsumowanie informacji o liczbie klientów:
                  <ul>
                    <li>wpisanych do bazy KRD,</li>
                    <li>w wybranych kategoriach wiarygodności płatniczej,</li>
                  </ul>
                </li>
                <li>listę klientów w ramach wybranych kategorii wiarygodności płatniczej,</li>
                <li>
                  przyciski „Pobierz” służące do pobierania kompleksowych Raportów KG o klientach (dostarczanych przez
                  KG sp.j.).
                </li>
              </ul>
              <p>
                Wyboru zawartości oraz odbiorców cyklicznego raportu e-mail dokonuje Administrator firmy w kreatorze
                raportów, w momencie tworzenia nowego cyklicznego raportu e-mail lub modyfikowania istniejącego.{' '}
              </p>

              <p>
                Jeśli dla zdefiniowanego cyklicznego raportu e-mail nie będzie ani jednego klienta posiadającego
                wskazane kategorie wiarygodności płatniczej, to e-mail z raportem nie będzie generowany do odbiorców
                (raport byłby wtedy pusty).
              </p>

              <p>
                E-mail z cyklicznym raportem może zawierać łącznie maksymalnie 1000 rekordów (sumaryczna ilość wierszy z
                danymi we wszystkich sekcjach), przy czym maksymalnie 500 rekordów w sekcji „Klienci wpisani do KRD” i
                maksymalnie 500 rekordów w sekcji „Ocena wiarygodności płatniczej monitorowanych klientów”.
              </p>

              <p>
                Cykliczne raporty e-mail są generowane około godziny 7 rano, zgodnie z częstotliwością określoną przez
                Administratora Twojej firmy w kreatorze raportów.{' '}
              </p>

              <p>
                Linki do kompleksowych Raportów KG o poszczególnych klientach są aktywne przez okres równy
                częstotliwości wysyłania danego cyklicznego raportu e-mail. Przykładowo, jeśli cykliczny raport e-mail
                jest generowany co tydzień, to linki do pobrania raportów są aktywne przez tydzień od momentu
                wygenerowania cyklicznego raportu e-mail. Kliknięcie w nieaktywny link spowoduje wygenerowanie
                komunikatu „Link wygasł lub nie posiadasz uprawnień do pobierania raportów”.
              </p>

              <p>
                Aktywny link będzie możliwy do wykorzystania wielokrotnie, tj. pobranych będzie tyle raportów, ile
                kliknięć w link. Każdorazowe kliknięcie w przycisk „Pobierz” przy danym kliencie powoduje zatem
                wygenerowanie aktualnego (na moment kliknięcia w przycisk „Pobierz”) raportu na jego temat (o ile
                użytkownik ma nadane uprawnienie do pobierania raportów).
              </p>

              <p>
                Każde pobranie raportu o kliencie z poziomu cyklicznego raportu e-mail jest rejestrowane w liczniku.
                Dodatkowe informacje na ten temat są zawarte w punkcie „6.4. Jak działa licznik usług?” w Samouczku.
              </p>

              <p>
                Do czasu pobrania raportu o kliencie będzie wyświetlany komunikat „Trwa generowanie raportu. Może to
                potrwać 30 sekund. W tym czasie pobieramy aktualne informacje z wielu źródeł. Nie odświeżaj strony i nie
                zamykaj przeglądarki.”. Po zakończeniu pobrania raportu, Raport KG prezentowany jest w przeglądarce.
              </p>

              <p>
                Każdy raport o kliencie pobrany z poziomu cyklicznego raportu e-mail będzie także widoczny w serwisie
                RiskRadar dla innych użytkowników (do 7 dni od jego wygenerowania).{' '}
              </p>

              <p>
                Przykładowa wizualizacja cyklicznego raportu e-mail w przypadku wybrania kategorii A, F, G, H oraz
                „Klienci wpisani do KRD”:
              </p>
              <div className={styles.tutorialImageWrapper}>
                <img
                  src={require('../../../assets/images/tutorial/9-1.png')}
                  alt="Co zawierają cykliczne raporty e-mail?"
                  className={styles.horizontallImg}
                />
              </div>
            </div>
          );
        }
      },
      {
        name: 'Jak utworzyć nowy cykliczny raport e-mail?',
        content: function (): JSX.Element {
          return (
            <div className={styles.tutorialSubSectionWrapper}>
              <p>
                Cykliczny raport e-mail może utworzyć Administrator firmy w zakładce „Kreator raportów cyklicznych”.
              </p>
              <p>
                Nie ma ograniczeń w zakresie liczby raportów e-mail, które można skonfigurować w kreatorze raportów.
              </p>
              <p>
                W celu utworzenia nowego cyklicznego raportu kliknij przycisk „Dodaj nowy raport” i następnie określ:
                <ul>
                  <li>nazwę raportu – np. „Raport dla Działu Sprzedaży”,</li>
                  <li>zawartość raportu – wskaż kategorie klientów, którzy zostaną uwzględnieni w raporcie,</li>
                  <li>częstotliwość wysyłania raportu – określ, jak często ma być generowany raport,</li>
                  <li>listę odbiorców – wskaż użytkowników RiskRadar, którzy będą otrzymywać raport.</li>
                </ul>
              </p>
              <p>
                Pierwszy raport po utworzeniu zostanie wysłany w najbliższym terminie wpisującym się w wybraną
                częstotliwość. Przykładowo, jeśli w środę utworzono nowy raport i ustawiono częstotliwość jego wysyłki
                co tydzień, to po raz pierwszy raport ten zostanie wygenerowany i wysłany w najbliższy poniedziałek.
              </p>
              <div className={styles.tutorialImageWrapper}>
                <img
                  src={require('../../../assets/images/tutorial/9-2.png')}
                  alt="Jak utworzyć nowy cykliczny raport e-mail?"
                  className={styles.horizontallImg}
                />
              </div>
            </div>
          );
        }
      },
      {
        name: 'Jak zmodyfikować istniejące cykliczne raporty e-mail?',
        content: function (): JSX.Element {
          return (
            <div className={styles.tutorialSubSectionWrapper}>
              <p>
                Utworzony cykliczny raport e-mail możesz modyfikować, np. zmienić listę odbiorców czy grupy klientów
                uwzględniane w raporcie. W tym celu wybierz z listy cyklicznych raportów e-mail ten, który chcesz
                zmodyfikować a następnie kliknij ikonkę ustawień i wybierz opcję „Modyfikuj”. Spowoduje to otworzenie
                okna z ustawieniami tego raportu. Pamiętaj o zapisaniu zmian za pomocą przycisku „Zapisz raport”.{' '}
              </p>
              <div className={styles.tutorialImageWrapper}>
                <img
                  src={require('../../../assets/images/tutorial/9-3.png')}
                  alt="Jak zmodyfikować istniejące cykliczne raporty e-mail?"
                  className={styles.horizontallImg}
                />
              </div>
            </div>
          );
        }
      },
      {
        name: 'Czy użytkownik może dostawać cykliczny raport e-mail a nie mieć dostępu do RiskRadar?',
        content: function (): JSX.Element {
          return (
            <div className={styles.tutorialSubSectionWrapper}>
              <p>
                Tak, jest to możliwe. W tym celu należy dodać nowego użytkownika typu „Użytkownik bez dostępu do
                serwisu” a następnie dodać tego użytkownika do listy odbiorców danego cyklicznego raportu e-mail.
              </p>
              <p>
                Ponadto, na etapie nadawania uprawnień temu użytkownikowi, można także określić, czy ma on mieć
                możliwość pobierania aktualnego raportu o kliencie (z poziomu cyklicznego raportu e-mail) czy nie.
              </p>
            </div>
          );
        }
      },
      {
        name: 'Pobranie raportu o kliencie z cyklicznego raportu e-mail a pobranie z serwisu RiskRadar.',
        content: function (): JSX.Element {
          return (
            <div className={styles.tutorialSubSectionWrapper}>
              <p>
                Pod względem zakresu danych raport o kliencie pobrany z poziomu cyklicznego raportu e-mail jest
                identyczny jak ten pobrany z poziomu serwisu RiskRadar. Pobrane raporty o klientach są dostępne w
                serwisie RiskRadar przez 7 dni, niezależnie od tego, czy pobranie nastąpiło z poziomu cyklicznego
                raportu e-mail czy z poziomu serwisu RiskRadar.{' '}
              </p>
              <p>
                Cykliczny raport e-mail uwzględnia wyłącznie tych klientów, co do których jest włączona usługa
                monitorowania w serwisie RiskRadar. Oznacza to, że z poziomu cyklicznego raportu e-mail nie można pobrać
                raportów o klientach, dla których nie jest włączona usługa monitorowania w serwisie RiskRadar. Z poziomu
                serwisu RiskRadar można natomiast pobrać raport o dowolnym kliencie dodanym do RiskRadar, niezależnie od
                tego czy ma włączone monitorowanie czy nie.{' '}
              </p>
            </div>
          );
        }
      },
      {
        name: 'Jak sprawdzić, jakie cykliczne raporty e-mail otrzymuje dany użytkownik?',
        content: function (): JSX.Element {
          return (
            <div className={styles.tutorialSubSectionWrapper}>
              <p>
                Może tego dokonać tylko Administrator firmy wchodząc do zakładki „Użytkownicy i uprawnienia” i
                otwierając kartę danego użytkownika. Na karcie użytkownika znajduje się sekcja „Raport e-mail z listą
                klientów” – zawiera ona nazwy raportów, w których odbiorcą jest dany użytkownik.{' '}
              </p>
              <div className={styles.tutorialImageWrapper}>
                <img
                  src={require('../../../assets/images/tutorial/9-6.png')}
                  alt="Jak zmodyfikować istniejące cykliczne raporty e-mail?"
                  className={styles.horizontallImg}
                />
              </div>
            </div>
          );
        }
      }
    ]
  },
  {
    name: 'Zakładka "Historia zmian na klientach"',
    subsections: [
      {
        name: 'Jakie dane zawiera zakładka „Historia zmian na klientach”?',
        content: function (): JSX.Element {
          return (
            <div className={styles.tutorialSubSectionWrapper}>
              <p>
                W zakładce „Historia zmian na klientach” prezentowane są dane dotyczące klientów z aktualnie włączonym
                monitorowaniem, dla których w ostatnich 9 tygodniach (tj. tydzień bieżący i 8 tygodni wstecz) wystąpiły
                zmiany salda zadłużenia w KRD w PLN i/lub kategorii wiarygodności płatniczej. Wartości w poszczególnych
                polach pokazują stan (wartość) po wystąpieniu zmiany. Puste pole oznacza, że danego dnia nie wystąpiła
                zmiana. Domyślnie prezentowany jest „Widok sumaryczny” zawierający podsumowanie wszystkich tygodni.{' '}
              </p>
              <p>
                Jeśli w ciągu 9 tygodni nie było zmian dla zadłużenia lub wiarygodności, to rekord z zadłużeniem /
                wiarygodnością i tak jest widoczny na liście, ponieważ nastąpiła w tym czasie zmiana dla drugiej danej –
                odpowiednio: zadłużenia lub wiarygodności.
              </p>
              <p>
                Kolumna „Dziś” jest zawsze widoczna i prezentuje dane na dzień bieżący. Wartości są w niej widoczne,
                jeżeli wystąpiło zadłużenie lub/i istnieje wyznaczona wiarygodność.
              </p>
              <p>
                Jeżeli monitorowanie zostanie wyłączone, historia zmian zadłużenia i wiarygodności zostanie usunięta z
                serwisu RiskRadar.{' '}
              </p>
              <p>Poglądowa wizualizacja ekranu z widokiem sumarycznym:</p>
              <div className={styles.tutorialImageWrapper}>
                <img
                  src={require('../../../assets/images/tutorial/10-1.png')}
                  alt="Jakie dane zawiera zakładka „Historia zmian”?"
                  className={styles.horizontallImg}
                />
              </div>
            </div>
          );
        }
      },
      {
        name: 'Jak działa eksport danych do CSV w zakładce „Historia zmian na klientach”?',
        content: function (): JSX.Element {
          return (
            <div className={styles.tutorialSubSectionWrapper}>
              <p>
                Dane prezentowane w zakładce „Historia zmian na klientach” można wyeksportować do pliku CSV i następnie
                analizować poza serwisem RiskRadar. Po naciśnięciu przycisku „Eksport do CSV” rozpoczyna się tworzenie
                pliku CSV z danymi widocznymi na ekranie „Historia zmian”. Plik zawiera dane z tego ekranu z
                uwzględnieniem filtra „Rodzaj informacji”, wyszukiwania, sortowania, ze wszystkich stron, z okresu 9
                tygodni (tydzień bieżący i 8 tygodni wstecz). W eksporcie nie jest uwzględniany filtr „Zakres czasowy”.
                Dane w pliku są rozdzielone średnikami.
              </p>
              <p>
                Dane w pliku są w następującej kolejności:
                <ul>
                  <li>Nazwa firmy,</li>
                  <li>NIP,</li>
                  <li>Rodzaj informacji – „Zadłużenie w KRD” lub „Wiarygodność”,</li>
                  <li>Dzień tygodnia i data,</li>
                  <li>Dziś i data bieżąca.</li>
                </ul>
              </p>
              <p>
                Użytkownik, który zainicjował eksport otrzyma powiadomienie email, gdy plik będzie gotowy do pobrania.
              </p>
              <p>
                Plik CSV z wyeksportowanymi danymi jest dostępny do pobrania dla wszystkich użytkowników danej firmy w
                zakładce menu "Historia eksportu danych" przez 7 dni.
              </p>
            </div>
          );
        }
      }
    ]
  },
  {
    name: 'Zakładka „Historia pobranych raportów”',
    subsections: [
      {
        name: 'Co zawiera zakładka „Historia pobranych raportów”?',
        content: function (): JSX.Element {
          return (
            <div className={styles.tutorialSubSectionWrapper}>
              <p>
                Zakładka ta zawiera informacje o wszystkich raportach o klientach pobranych przez wszystkich
                użytkowników z Twojej organizacji w ciągu ostatnich 7 dni. Lista zawiera zarówno raporty pobrane z
                poziomu serwisu RiskRadar (z listy klientów i z kart klientów) jak i z poziomu cyklicznych raportów
                email. Dodatkowo na liście uwzględniane są raporty, które są w trakcie generowania (raporty generują się
                zwykle około 30 sekund). Po wygenerowaniu się raportu jego status zmieni się samoczynnie, bez
                konieczności odświeżania strony. Raport, który został wygenerowany można pobrać klikając na dowolne
                miejsce w wierszu dotyczącym danego klienta. Pobranie uprzednio wygenerowanego raportu można wykonywać
                dowolną ilość razy i nie skutkuje to naliczeniem usługi.
              </p>
              <p>Wizualizacja poglądowa:</p>
              <div className={styles.tutorialImageWrapper}>
                <img
                  src={require('../../../assets/images/tutorial/11-1.png')}
                  alt="Co zawiera zakładka „Historia raportów”?"
                  className={styles.horizontallImg}
                />
              </div>
            </div>
          );
        }
      }
    ]
  },
  {
    name: 'Przycisk „Nowy raport o dowolnym NIP”',
    subsections: [
      {
        name: 'Do czego służy i jak działa przycisk „Nowy raport o dowolnym NIP”?',
        content: function (): JSX.Element {
          return (
            <div className={styles.tutorialSubSectionWrapper}>
              <p>
                Przycisk ten służy do szybkiego pobierania raportu o dowolnym kliencie, także takim, którego nie ma w
                RiskRadar w danej chwili. Kliknięcie tego przycisku na dowolnym ekranie serwisu powoduje otwarcie
                zakładki „Historia pobranych raportów” oraz wyświetlenie okna dialogowego do wprowadzania numeru NIP.
              </p>
              <p>Wizualizacja poglądowa:</p>
              <div className={styles.tutorialImageWrapper}>
                <img
                  src={require('../../../assets/images/tutorial/12-1.png')}
                  alt="Do czego służy i jak działa przycisk „Nowy raport o kliencie”?"
                  className={styles.horizontallImg}
                />
              </div>
              <p>
                Jeśli wprowadzony zostanie poprawny numer NIP a klient istnieje już w RiskRadar to nastąpi wygenerowanie
                aktualnego raportu o kliencie oraz naliczenie usługi.
              </p>
              <p>
                Jeśli wprowadzony zostanie poprawny numer NIP ale klient nie istnieje w RiskRadar to konieczne będzie
                dodatkowo wpisanie nazwy klienta. Po tym nastąpi dodanie klienta do RiskRadar oraz od razu wygenerowanie
                aktualnego raportu o tym kliencie i naliczenie usługi.
              </p>
              <p>
                Wygenerowany w ten sposób raport pojawi się na liście „Historia raportów” i stamtąd można go pobrać.
                Więcej informacji o zakładce „Historia pobranych raportów” znajduje się w Samouczku, w punkcie „11.1. Co
                zawiera zakładka „Historia pobranych raportów”?”.
              </p>
            </div>
          );
        }
      }
    ]
  },
  {
    name: 'Pomoc',
    subsections: [
      {
        name: 'Z kim i jak się kontaktować w przypadku pytań?',
        content: function (): JSX.Element {
          return (
            <div className={styles.tutorialSubSectionWrapper}>
              <p>
                W pierwszej kolejności sprawdź, czy odpowiedź na Twoje pytanie znajduje się w Samouczku. Samouczek
                zawiera szereg wartościowych wskazówek i objaśnień, które ułatwiają korzystanie z RiskRadar.
              </p>
              <p>Jeśli Twoje pytanie dotyczy:</p>
              <ul>
                <li>zarządzania kontami użytkowników, np. dodawania, usuwania kont,</li>
                <li>zarządzania uprawnieniami użytkowników do funkcjonalności i usług,</li>
                <li>cyklicznych raportów e-mail z monitorowania klientów,</li>
                <li>klientów dodanych do RiskRadar,</li>
              </ul>
              <p>skontaktuj się z administratorem RiskRadar w Twojej firmie.</p>
              <p>Jeśli Twoje pytanie dotyczy:</p>
              <ul>
                <li>funkcjonowania RiskRadar,</li>
                <li>działania usług,</li>
                <li>automatycznego importu danych o klientach,</li>
                <li>limitów usług i opłaty miesięcznej, </li>
                <li>fakturowania i płatności,</li>
              </ul>
              <p>skontaktuj się z infolinią KG:</p>
              <ul>
                <li>telefonicznie – pod numerem 71-74-74-744,</li>
                <li>
                  pocztą elektroniczną – pod adresem{' '}
                  <a className={styles.blueLink} href="mailto:pomoc@riskradar.pl">
                    pomoc@riskradar.pl
                  </a>
                  .
                </li>
              </ul>
              <p>
                Jeśli Twoja sprawa dotyczyć będzie usług świadczonych przez KRD BIG S.A. zawartych w RiskRadar (tj.
                sprawdzania zdarzeń, sprawdzania zadłużenia, sprawdzania kategorii wiarygodności płatniczej), to Twoja
                rozmowa zostanie przekierowana do oddzielnej infolinii obsługiwanej przez KRD BIG S.A.
              </p>
            </div>
          );
        }
      }
    ]
  }
].map((s, index) => ({
  ...s,
  index: `${index + 1}.`,
  subsections: s.subsections.map((ss, index2) => ({
    ...ss,
    index: `${index + 1}.${index2 + 1}.`,
    id: `punkt-${index + 1}-${index2 + 1}`
  }))
}));
