export enum FIELDS {
  REPORT_ID = 'reportId',
  NAME = 'name',
  RISK_GROUPS = 'riskGroups',
  REPORT_TYPE = 'reportType',
  FREQUENCY = 'frequency',
  ACTIONS = 'actions'
};

export type ReportRow = {
  id: number;
  name: string;
  reportType: string;
  frequency: number;
};