import { ApiError } from 'api/models';

export type ErrorsType = {
  ErrorId: string;
  Errors: ApiError[];
};

export enum ERRORS {
  ERROR_UNEXPECTED = 'ERROR_UNEXPECTED_ERROR_OCCURRED'
}
