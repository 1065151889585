import { FC, useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import Box from '@mui/material/Box';
import { selectErrors } from 'store/errors';
import { selectSuccess } from 'store/success';
import Nav from '../Nav';
import Errors from '../Errors';
import SuccessToast from '../SuccessToast';
import ReportToast from '../ReportToast';
import ProtectedRoute from 'router/ProtectedRoute';
import { ROLE } from 'config/oidc';
import { PushNotification, NotificationType } from '../../App';
import { connectionSelector } from 'store/connection';

const Layout: FC = ({ children }) => {
  const { Errors: errors, ErrorId } = useSelector(selectErrors);
  const { message } = useSelector(selectSuccess);
  const [toastData, setToastData] = useState<PushNotification | null>(null);

  const connection = useSelector(connectionSelector);

  useEffect(() => {
    connection?.on('SendNotification', (data: PushNotification) => {
      if (data?.type === NotificationType.UserReportGenerated) {
        setToastData(data);
      }
    });
  }, [connection]);

  const clearToastDataOnClose = () => setToastData(null);

  return (
    <>
      <ProtectedRoute
        component={Nav}
        hideSpinner={true}
        roles={[
          ROLE.ADMIN_KG,
          ROLE.INTEGRATOR,
          ROLE.CLIENT_ADMIN,
          ROLE.CLIENT_USER,
          ROLE.CURATOR,
          ROLE.INTEGRATOR,
          ROLE.SUPER_ADMIN,
          ROLE.TECH_ACCOUNT,
          ROLE.USER_WITHOUT_WEB_ACCESS
        ]}></ProtectedRoute>
      <Box sx={{ display: 'flex' }}>
        <Box sx={{ minWidth: 0, width: '100%' }}>{children}</Box>
      </Box>
      <Errors errors={errors} errorId={ErrorId} />
      {toastData ? <ReportToast closeCallback={clearToastDataOnClose} message={toastData?.message} /> : null}
      {message && <SuccessToast message={message} />}
    </>
  );
};
export default Layout;
