import { FC, useState, KeyboardEvent, ChangeEvent, useEffect, memo } from 'react';
import MaterialPagination from '@mui/material/Pagination';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import { Props } from './Pagination.types';
import { StyledTextField } from 'components';
import classes from './styles.module.scss';
import { SelectChangeEvent } from '@mui/material';
import { useLocation, useNavigate, createSearchParams } from 'react-router-dom';

const Pagination: FC<Props> = memo(
  ({ options = [10, 20, 50, 100], total, pageSize, extraData, onRowsPerPageChange }) => {
    const navigate = useNavigate();
    const location = useLocation();
    const params = new URLSearchParams(location.search);
    const pageParam = params.get('page');
    const page = pageParam ? parseInt(pageParam) : undefined;
    const [pageInputValue, setPageInputValue] = useState<number | string>(1);
    const pageQuantity = Math.ceil(total / pageSize);

    const navigateToPage = (value: number, replace?: boolean) => {
      const mergedParams = createSearchParams(params);
      mergedParams.set('page', value?.toString());
      navigate({ pathname: location.pathname, search: `${mergedParams}` }, { replace: replace ?? false });
    };

    useEffect(() => {
      if (!page) {
        setPageInputValue(1);
        navigateToPage(1, true);
      } else {
        navigateToPage(page);
        setPageInputValue(page);
      }
    }, []);

    const handleSetInputValue = (event: ChangeEvent<HTMLInputElement>) => {
      const regex = /^[0-9\b]+$/;
      if (event.target.value === '' || regex.test(event.target.value)) {
        setPageInputValue(event.target.value);
      }
    };

    const handleOnPageType = (event: KeyboardEvent<HTMLDivElement>) => {
      if (['Enter', 'NumpadEnter'].includes(event.key)) {
        const value = parseInt((event.target as HTMLInputElement).value);
        if (value > pageQuantity) {
          setPageInputValue(pageQuantity);
          navigateToPage(pageQuantity);
        } else if ((value === 0 || isNaN(value)) && page === 1) {
          setPageInputValue(1);
        } else if (value === 0 || isNaN(value)) {
          navigateToPage(1);
        } else {
          navigateToPage(value);
        }
      }
    };

    const handleOnPageChange = (event: any, value: any) => {
      navigateToPage(value);
      setPageInputValue(value);
    };

    const handleOnRowsPerPageChange = (event: SelectChangeEvent<number>) => {
      const value = event.target.value;
      localStorage.setItem('rowsPerPage', value?.toString());
      onRowsPerPageChange(event);
    };

    return (
      <div className={classes.container}>
        <div className={classes.extraData}>{extraData}</div>
        <div className={classes.innerContainer}>
          <div className={classes.selectContainer}>
            <span className={classes.selectDescription}>Liczba wierszy:</span>
            <Select
              id="pagination-select"
              size="small"
              value={pageSize}
              onChange={(event) => handleOnRowsPerPageChange(event)}
              classes={{
                select: classes.select
              }}>
              {options.map((option) => (
                <MenuItem key={option} value={option} id={`pagination-select-menu-${option}`}>
                  {option}
                </MenuItem>
              ))}
            </Select>
          </div>
          <div className={classes.inputContainer}>
            <span className={classes.inputDescription}>Strona:</span>
            <StyledTextField
              classes={{
                root: classes.input
              }}
              size="small"
              onKeyDown={(event) => handleOnPageType(event)}
              onChange={handleSetInputValue}
              value={pageInputValue}
              id="pagination-input"
            />
          </div>
          <MaterialPagination
            count={pageQuantity}
            onChange={(event, value) => handleOnPageChange(event, value)}
            page={page}
          />
        </div>
      </div>
    );
  }
);

export default Pagination;
